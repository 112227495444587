import { useBreakpointIndex } from '@theme-ui/match-media';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Button, Container, Flex, Paragraph, Text } from 'voom-gatsby';

import { ReactComponent as CalendarIcon } from '~svg/icons/icon-calendar.svg';

import { SanityDoctor } from '~types/sanity';

interface DoctorStickyBarProps {
  doctor: SanityDoctor;
  openFormModal: () => void;
  active: boolean;
}
export const DoctorStickyBar = ({
  doctor,
  openFormModal,
  active,
}: DoctorStickyBarProps) => {
  const mainOffice = doctor.doctorOffices[0];
  const breakpointIndex = useBreakpointIndex();
  const buttonText = breakpointIndex >= 2 ? 'Email This Doctor' : 'Email';

  return (
    <motion.div
      sx={{
        py: 2,
        bg: 'white',
        position: 'fixed',
        top: 0,
        width: 'full',
        display: active ? 'block' : 'none',
        zIndex: 'nav',
        boxShadow: 'navShadow',
      }}
      initial="hide"
      animate={active ? 'show' : 'hide'}
      variants={{
        hide: {
          transform: 'translate3d(0, -100%, 0)',
          opacity: 0,
        },
        show: {
          transform: 'translate3d(0, 0%, 0)',
          opacity: 1,
        },
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Flex sx={{ alignItems: 'center', gap: 3 }}>
          <GatsbyImage
            image={doctor.gallery[0].asset.gatsbyImageData}
            alt={doctor.gallery[0].alt}
            sx={{
              aspectRatio: '1 / 1',
              width: [45, null, 65],
              maxWidth: [45, null, 65],
              borderRadius: 'circle',
              img: {
                borderRadius: 'circle',
              },
            }}
          />
          <Flex sx={{ flexDirection: 'column' }}>
            <Paragraph variant="faq">
              <Text sx={{ fontWeight: 'medium', mr: 2 }}>{doctor.name}</Text>
              <Text sx={{ display: ['none', 'inline'] }}>
                {doctor.credentials}
              </Text>
            </Paragraph>
            <Paragraph variant="faq">
              {`${mainOffice.city.name}, ${mainOffice.state.abbreviation}`}
            </Paragraph>
          </Flex>
        </Flex>
        <Button
          onClick={openFormModal}
          sx={{ flex: '0 0 auto', px: [3, null, 5], height: [36, null, 50] }}
        >
          <Text sx={{ fontWeight: 'semibold', mr: 2 }}>{buttonText}</Text>
          <CalendarIcon sx={{ color: 'white', width: 17, height: 17 }} />
        </Button>
      </Container>
    </motion.div>
  );
};
