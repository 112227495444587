import { Container, Paragraph, Section, Spacer } from 'voom-gatsby';

import { ReactComponent as PatentedMethodIcon } from '~svg/brand/patented-method.svg';
import { ReactComponent as QuoteIcon } from '~svg/brand/quote-icon.svg';

import { SanityDoctor } from '~types/sanity';

export const DoctorQuote = ({
  quote,
  hideQuoteAttribution,
  name,
}: {
  quote: SanityDoctor['quote'];
  hideQuoteAttribution: SanityDoctor['hideQuoteAttribution'];
  name: SanityDoctor['name'];
}) => {
  const showQuoteAttribution = !hideQuoteAttribution;
  return (
    <Section
      sx={{
        py: [5, null, 9],
        bg: 'white',
        overflow: 'hidden',
      }}
    >
      <Container
        variant="doctorContainer"
        sx={{
          maxWidth: 1000,
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {showQuoteAttribution ? (
          <QuoteIcon sx={{ width: 75 }} />
        ) : (
          <PatentedMethodIcon sx={{ width: 75 }} />
        )}
        <Spacer />
        <Paragraph
          as="q"
          variant="contentTitle"
          sx={{ textAlign: 'center', lineHeight: 'headingWide' }}
        >
          {quote}
        </Paragraph>
        <Spacer />
        {showQuoteAttribution && (
          <Paragraph variant="eyeBrow">{name}</Paragraph>
        )}
      </Container>
    </Section>
  );
};
