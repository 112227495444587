import { motion } from 'framer-motion';
import {
  ForwardedRef,
  RefObject,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Badge } from 'theme-ui';

import { Flex, Paragraph, Spacer, ThemeUIStyleObject } from 'voom-gatsby';

import { ReactComponent as SuccessSvg } from '~svg/bunionplasty-quiz/success.svg';

const cardContainerStyles: ThemeUIStyleObject = {
  width: ['105px', '150px', '250px', '350px'],
  height: ['150px', '180px', '250px', '300px'],
  border: '2px solid rgba(217, 194, 180, 1)',
  borderRadius: 'corner',
  backgroundColor: 'rgba(255, 244, 237, 1)',
  cursor: 'pointer',
  px: [2, null, null, 4],
  py: 1,
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    boxShadow: 'cardShadow',
  },
};

const selectedCardStyles: ThemeUIStyleObject = {
  ...cardContainerStyles,
  backgroundColor: 'white',
  border: '4px solid rgba(218, 27, 133, 1)',
  boxShadow: '0px 0px 40px 0px rgba(0, 0, 0, 0.2)',
};

const innerCardStyles: ThemeUIStyleObject = {
  justifyContent: 'center',
  alignItems: 'center',
  height: 'full',
  flexDirection: 'column',
};

const SuccessIndicator = () => (
  <SuccessSvg
    sx={{
      position: 'absolute',
      left: [-2, null, null, 25],
      top: [1, null, null, 25],
      height: ['25px', null, null, '50px'],
    }}
  />
);

type PanelSelectQuestionProps = {
  ref?: RefObject<HTMLDivElement>;
  headline: string | JSX.Element;
  subheadline?: string;
  pillText: string;
  cardContent: { key: string; content: JSX.Element }[];
  disabled?: boolean;
  register: { name: string };
  setValue: UseFormSetValue<FieldValues>;
  onSelectChange?: () => void;
};

export const PanelSelectQuestion = forwardRef(
  (props: PanelSelectQuestionProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      headline,
      subheadline,
      pillText,
      cardContent,
      disabled,
      register,
      setValue,
      onSelectChange,
    } = props;

    const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
      null,
    );
    const [firstCardContent, secondCardContent, thirdCardContent] = cardContent;

    useEffect(() => {
      if (selectedCardIndex === null) return;

      setValue(register.name, cardContent[selectedCardIndex].key, {
        shouldValidate: true,
        shouldDirty: true,
      });
      if (onSelectChange) onSelectChange();
    }, [selectedCardIndex, register.name, setValue]);

    const PanelCard = ({
      index,
      cardContent,
    }: {
      index: number;
      cardContent: JSX.Element;
    }) => (
      <motion.div
        sx={
          selectedCardIndex === index ? selectedCardStyles : cardContainerStyles
        }
        whileHover={{ y: -5, boxShadow: '0px 0px 40px 0px rgba(0, 0, 0, 0.2)' }}
        onClick={() => setSelectedCardIndex(index)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            setSelectedCardIndex(index);
          }
        }}
        tabIndex={0}
      >
        {selectedCardIndex === index && <SuccessIndicator />}
        <Flex sx={innerCardStyles}>{cardContent}</Flex>
      </motion.div>
    );

    return (
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          opacity: disabled ? 0.3 : 1,
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        {...register}
      >
        <Badge
          sx={{
            backgroundColor: 'rgba(247, 247, 249, 1)',
            fontSize: 'xs',
            color: 'rgba(218, 27, 133, 1)',
            maxWidth: '86px',
            padding: '8px 22px 8px 22px',
            borderRadius: 'rounded',
          }}
        >
          {pillText}
        </Badge>
        <Spacer />
        <Paragraph
          variant="contentTitleLg"
          sx={{
            textAlign: 'center',
            fontSize: ['2xl', null, '5xl', null, '6xl'],
          }}
        >
          {headline}
        </Paragraph>
        <Spacer space={3} />
        <Paragraph sx={{ fontSize: ['xs', null, null, 'md'] }}>
          {subheadline}
        </Paragraph>
        <Spacer space={6} />
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            width: ['full', null, null, '80%'],
            fontFamily: 'TT Norms',
            fontSize: ['xs', null, null, '2xl'],
            fontWeight: 'medium',
            gap: 4,
          }}
          ref={ref}
        >
          <input type="hidden" {...register} />
          <PanelCard index={0} cardContent={firstCardContent.content} />
          <PanelCard index={1} cardContent={secondCardContent.content} />
          <PanelCard index={2} cardContent={thirdCardContent.content} />
        </Flex>
      </Flex>
    );
  },
);

PanelSelectQuestion.displayName = 'PanelSelectQuestion';
