import { PortableTextProps } from '@portabletext/react';
import { graphql } from 'gatsby';
import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import Sticky from 'react-stickynode';

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Paragraph,
  SEO,
  Spacer,
  Spinner,
} from 'voom-gatsby';

import { DoctorResultsCarousel } from '~sections/DoctorResultsCarousel';
import { DoctorResultsForm } from '~sections/DoctorResultsForm';
import { FaqSection } from '~sections/FaqSection';

import {
  ContentBlock,
  Disclaimer,
  DoctorMap,
  DoctorResultCard,
} from '~components';

import { mobileResultsViewAtom } from '~hooks/use-doctor-results';
import { useDoctorResults } from '~hooks/use-doctor-results';

import { scrollToDataId } from '~utils/helpers';
import { useGoogleMapInstance } from '~utils/map';

import {
  ContentBlockProps,
  SanityDoctor,
  SanityFaqSection,
} from '~types/sanity';

interface DoctorResultsTemplateProps {
  data: {
    allSanityDoctor: {
      nodes: SanityDoctor[];
    };
    sanityDoctorSettings: {
      doctorNullStateContentContent: ContentBlockProps;
      _rawDoctorListingDisclaimer?: PortableTextProps['value'];
    };
    sanityDoctorState: {
      stateFaq?: SanityFaqSection;
    };
    sanityDoctorCity: {
      cityFaq?: SanityFaqSection;
    };
    sanityFaq?: SanityFaqSection;
  };
  pageContext: {
    boundary?: string;
  };
}

const DoctorResultsTemplate = ({
  data,
  pageContext,
}: DoctorResultsTemplateProps) => {
  const {
    isLoaded,
    onLoad,
    onUnmount,
    addressDisplay,
    selectedIndex,
    googleMapInstance,
    userLocation,
    setSelectedIndex,
    hasUserLocation,
    doctorResults,
    nullStateDoctors,
  } = useDoctorResults(data.allSanityDoctor.nodes, pageContext);

  const miniMap = useGoogleMapInstance(); // mini map for mobile list view. needs to be separate from main map

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'Doctor Result Page View', {});
    }
  }, []);

  const [mobileView, setMobileView] = useAtom(mobileResultsViewAtom);
  const mobileNavHeight = `${81 * 2}px`;

  const faqData = useMemo(() => {
    if (pageContext.boundary) {
      return (
        data.sanityDoctorCity?.cityFaq ??
        data.sanityDoctorState?.stateFaq ??
        data.sanityFaq
      );
    }
  }, [
    pageContext.boundary,
    data.sanityDoctorCity?.cityFaq,
    data.sanityDoctorState?.stateFaq,
    data.sanityFaq,
  ]);

  useEffect(() => {
    if (mobileView === 'map' && typeof window !== 'undefined') {
      scrollToDataId('top-of-page');
    }
  }, [mobileView]);

  const hasDoctorResults = doctorResults && doctorResults.length > 0;

  return (
    <>
      <Box>
        <DoctorResultsForm
          showDistanceFilter={pageContext.boundary === undefined}
          hasDoctorResults={hasDoctorResults}
        />
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, null, '2fr 1fr', '3fr 2fr'],
          }}
        >
          {mobileView === 'list' && (
            <Box
              sx={{
                display: ['block', null, null, 'none'],
                height: [150, null, 250],
                position: 'relative',
              }}
            >
              <DoctorMap
                {...miniMap}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                userLocation={userLocation}
                doctorResults={doctorResults}
                defaultZoom={13}
              />
              {hasDoctorResults && (
                <Button
                  onClick={() => {
                    if (window.gtag) {
                      window.gtag(
                        'event',
                        'Mobile View Map Button Clicked',
                        {},
                      );
                    }
                    setMobileView('map');
                  }}
                  sx={{
                    position: 'absolute',
                    bottom: 3,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 3,
                    ':hover': {
                      transform: 'translateX(-50%)',
                    },
                  }}
                >
                  View Map
                </Button>
              )}
            </Box>
          )}
          <Container
            sx={{
              display: [
                mobileView === 'list' ? 'block' : 'none',
                null,
                null,
                'block',
              ],
            }}
          >
            {doctorResults ? (
              <>
                <Spacer space={6} />
                <Heading
                  variant="mediumP"
                  as="h1"
                  sx={{ fontWeight: 'semibold' }}
                >
                  {addressDisplay}
                </Heading>
                <Flex
                  sx={{
                    flexDirection: 'column',
                  }}
                >
                  {hasDoctorResults ? (
                    doctorResults
                      .filter((v, i, a) => {
                        return hasUserLocation
                          ? a.findIndex(
                              (v2) =>
                                v.name === v2.name && v.state === v2.state,
                            ) === i
                          : true;
                      })
                      .map((doctor, index) => {
                        return (
                          <DoctorResultCard
                            index={index}
                            key={doctor.streetAddress + index}
                            doctor={doctor}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            additionalLocationCount={
                              hasUserLocation
                                ? doctorResults.filter(
                                    (s) =>
                                      s.name === doctor.name &&
                                      s.state === doctor.state,
                                  ).length - 1
                                : 0
                            }
                          />
                        );
                      })
                  ) : (
                    <>
                      <Spacer space={3} />
                      <ContentBlock
                        content={
                          data.sanityDoctorSettings
                            .doctorNullStateContentContent
                        }
                      />
                      <Spacer space={[3, null, 7]} />
                      <Paragraph
                        variant="mediumP"
                        sx={{ fontWeight: 'semibold' }}
                      >
                        3 Bunion Surgery Doctors In Your Region
                      </Paragraph>
                      {nullStateDoctors?.map((doctor, index) => {
                        return (
                          <DoctorResultCard
                            index={index}
                            key={doctor.streetAddress + index}
                            doctor={doctor}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            additionalLocationCount={
                              hasUserLocation
                                ? doctorResults.filter(
                                    (s) =>
                                      s.name === doctor.name &&
                                      s.state === doctor.state,
                                  ).length - 1
                                : 0
                            }
                          />
                        );
                      })}
                    </>
                  )}
                  {data.sanityDoctorSettings._rawDoctorListingDisclaimer && (
                    <Disclaimer
                      value={
                        data.sanityDoctorSettings._rawDoctorListingDisclaimer
                      }
                    />
                  )}
                </Flex>
              </>
            ) : (
              <Grid
                css={{
                  // use fallback for mobile height
                  minHeight: [
                    `calc(100vh - ${mobileNavHeight})`,
                    `calc(100dvh - ${mobileNavHeight})`,
                  ],
                }}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spinner />
              </Grid>
            )}
          </Container>
          <Box
            className="container"
            sx={{
              height: 'full',
              width: 'full',
              overflow: 'hidden',
            }}
          >
            <Sticky top={0} bottomBoundary={'.container'}>
              <Box
                css={{
                  // use fallback for mobile height
                  height: [
                    `calc(100vh - ${mobileNavHeight})`,
                    `calc(100dvh - ${mobileNavHeight})`,
                  ],
                }}
                sx={{
                  position: 'relative',
                  width: 'full',
                  // desktop height
                  height: [null, null, '100vh'],

                  '.gm-style-iw.gm-style-iw-c': {
                    transform: 'translate3d(-50%, 15px, 0)',
                    boxShadow: 'none',
                    bg: 'transparent',
                  },
                  '.gm-style-iw, .gm-style-iw-c, .gm-style-iw-d': {
                    p: '0 !important',
                    overflow: 'hidden !important',
                  },
                  '.gm-style-iw-tc, .gm-ui-hover-effect': {
                    visibility: 'hidden',
                    display: 'none',
                  },
                  '.gm-style-iw-t': {
                    bottom: 0,
                  },
                  display: [
                    mobileView === 'map' ? 'block' : 'none',
                    null,
                    null,
                    'flex',
                  ],
                }}
              >
                <DoctorMap
                  isLoaded={isLoaded}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  userLocation={userLocation}
                  doctorResults={doctorResults}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                />

                {mobileView === 'map' && doctorResults && (
                  <DoctorResultsCarousel
                    doctors={doctorResults}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    googleMapInstance={googleMapInstance}
                  />
                )}
              </Box>
            </Sticky>
          </Box>
        </Grid>
      </Box>
      {faqData && <FaqSection data={{ faqReference: faqData }} />}
    </>
  );
};

export const Head = ({ pageContext }: DoctorResultsTemplateProps) => {
  const description = pageContext.boundary
    ? `Find a doctor in ${pageContext.boundary}, and prepare to step into a bunion-free future, thanks to the revolutionary Bunionplasty® 360 Bunion Repair™ surgery!`
    : 'Use our “find a doctor” feature today, and prepare to step into a bunion-free future, thanks to the revolutionary Bunionplasty® 360 Bunion Repair™ surgery!';

  return <SEO title={'Find a Doctor'} description={description} />;
};

export const ALL_SANITY_DOCTOR_QUERY = graphql`
  query DoctorSearch($cityId: String, $stateId: String) {
    allSanityDoctor {
      nodes {
        ...sanityDoctorFields
      }
    }
    sanityDoctorSettings(id: { eq: "-8b30ce4e-87a0-5afb-bf8c-c8982d88641d" }) {
      doctorNullStateContentContent {
        ...sanityContentBlockFields
      }
      _rawDoctorListingDisclaimer
    }
    sanityDoctorCity: sanitySurgeonCity(id: { eq: $cityId }) {
      cityFaq {
        ...sanityFaqFields
      }
    }
    sanityDoctorState: sanitySurgeonState(id: { eq: $stateId }) {
      stateFaq {
        ...sanityFaqFields
      }
    }
    sanityFaq(id: { eq: "-0e369bd1-401b-5df7-ac70-6192b1eb2167" }) {
      ...sanityFaqFields
    }
  }
`;

export default DoctorResultsTemplate;
