import { Global } from '@emotion/react';

export const ResetCss = (): JSX.Element => {
  return (
    <Global
      styles={() => {
        return {
          /* http://meyerweb.com/eric/tools/css/reset/ 
            v2.0 | 20110126
            License: none (public domain)
          */
          'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video':
            {
              margin: 0,
              padding: 0,
              border: 0,
              fontSize: '100%',
              fontWeight: 'inherit',
              font: 'inherit',
              verticalAlign: 'baseline',
            },
          /* HTML5 display-role reset for older browsers */
          'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section':
            {
              display: 'block',
            },
          body: {
            lineHeight: 1,
            overflowX: 'hidden',
          },
          'ol, ul': {
            listStyle: 'none',
          },
          'blockquote, q': {
            quotes: 'none',
          },
          'blockquote:before, blockquote:after, q:before, q:after': {
            content: 'none',
          },
          table: {
            borderCollapse: 'collapse',
            borderSpacing: 0,
          },

          'html, body, #___gatsby, #gatsby-focus-wrapper': {
            width: '100%',
            height: '100%',
          },

          '*:focus:not(:focus-visible)': {
            outline: 'none',
          },
          /* Slider */
          '.slick-slider': {
            position: 'relative',
            display: 'block',
          },
          '.slick-list': {
            position: 'relative',
            display: 'block',
            overflow: 'hidden',
            zIndex: 1,
            margin: '0',
            padding: '0',
          },
          '.slick-list:focus': {
            outline: 'none',
          },
          '.slick-list.dragging': {
            cursor: 'pointer',
          },
          '.slick-slider .slick-track, .slick-slider .slick-list': {
            transform: 'translate3d(0, 0, 0)',
          },
          '.slick-track': {
            position: 'relative',
            top: '0',
            left: '0',
            display: 'flex',
          },
          '.slick-track:before, .slick-track:after': {
            display: 'table',
            content: '""',
          },
          '.slick-track:after': {
            clear: 'both',
          },
          '.slick-loading .slick-track': {
            visibility: 'hidden',
          },
          '.slick-slide': {
            display: 'none',
            float: 'left',
            height: 'inherit',
            minHeight: '1px',
            '> div': {
              height: '100%',
            },
          },
          '[dir="rtl"] .slick-slide': {
            float: 'right',
          },
          '.slick-slide img': {
            display: 'block',
          },
          '.slick-slide.slick-loading img': {
            display: 'none',
          },
          '.slick-slide.dragging img': {
            pointerEvents: 'none',
          },
          '.slick-initialized .slick-slide': {
            display: 'block',
          },
          '.slick-loading .slick-slide': {
            visibility: 'hidden',
          },
          '.slick-vertical .slick-slide': {
            display: 'block',
            height: 'auto',
            border: '1px solid transparent',
          },
          '.slick-arrow.slick-hidden': {
            display: 'none',
          },
          /* remove fade in on gatsby-images with loading="eager" */
          '.gatsby-image-wrapper [data-main-image][loading="eager"]': {
            transition: 'opacity 0s linear',
          },
          // Make google map marker info window line up with marker
          '.gm-style-iw-chr': {
            display: 'none',
          },
          '.pac-container': {
            borderRadius: `30px`,
            padding: '16px 24px 4px',
            transform: 'translateY(10px)',
            '&::after': {
              content: '""',
              padding: '1px 1px 1px 0',
              marginRight: '15px',
              height: '15px',
              boxSizing: 'border-box',
              textAlign: 'right',
              display: 'block',
              backgroundImage:
                'url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png)',
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '120px 14px',
            },
            '.pac-item:first-of-type': {
              borderTop: 'none',
            },
            '.pac-item': {
              padding: '5px 0px',
              cursor: 'pointer',
              fontSize: '12px',
              '.pac-item-query': {
                fontSize: '15px',
              },
              '@media (max-width: 768px)': {
                padding: '0 16px',
              },
            },
          },
          '@media (max-width: 768px)': {
            // hide google maps size button on mobile
            '.gmnoprint': {
              display: 'none',
            },
          },
        };
      }}
    />
  );
};
