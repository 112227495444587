import {
  GatsbyImage,
  GatsbyImageProps,
  IGatsbyImageData,
} from 'gatsby-plugin-image';
import { FC } from 'react';

import { ThemeUIStyleObject, useImageSources } from 'voom-gatsby';

export interface GatsbyBackgroundImageProps
  extends Omit<GatsbyImageProps, 'image' | 'alt'> {
  alt?: string;
  images: (IGatsbyImageData | null | undefined)[];
  sx?: ThemeUIStyleObject;
  className?: string;
}

/**
 * GatsbyBackgroundImage
 *
 * Helps validate and create a fluid background image with gatsby fluid image sources
 *
 *
 * you can also pass all gatsby image props to this component
 * style, imgStyle, loading, fadeIn etc
 *
 * And lastly you can pass in theme ui sx props
 */
export const GatsbyBackgroundImage: FC<GatsbyBackgroundImageProps> = ({
  images,
  alt,
  style,
  sx,
  className,
  ...props
}) => {
  const backgroundImageSources = useImageSources(images);

  return (
    <GatsbyImage
      image={backgroundImageSources}
      className={className}
      alt={alt || ''}
      sx={{
        ...sx,
      }}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        ...style,
      }}
      {...props}
    />
  );
};
