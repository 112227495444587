import { graphql } from 'gatsby';

import { Box, Container, Heading, Section } from 'voom-gatsby';

import { FAQItem } from '~components';

import { SanityFaqItem } from '~types/sanity';

export const FaqSection = ({
  data,
}: {
  data: {
    faqReference: {
      title: string;
      faqItems: SanityFaqItem[];
    };
  };
  index?: number;
}) => {
  const { title, faqItems } = data.faqReference;
  return (
    <Section
      narrowPaddingBottom
      narrowPaddingTop
      sx={{
        bg: 'white',
        overflow: 'hidden',
      }}
    >
      <Container variant="doctorContainer" sx={{ maxWidth: 'wideContainer' }}>
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            bg: 'grey200',
            borderRadius: 'rounded',
            p: [5, null, 9],
          }}
        >
          <Heading
            variant="contentTitle"
            sx={{ textAlign: 'center', mb: [3, null, 5] }}
          >
            {title}
          </Heading>
          {faqItems.map((item) => {
            return <FAQItem key={item.question} {...item} />;
          })}
        </Box>
      </Container>
    </Section>
  );
};

export const FAQ_SECTION_Query = graphql`
  fragment faqSectionFields on SanityFaqSection {
    _type
    backgroundColor
    enabled
    faqReference {
      title
      faqItems {
        question
        _rawAnswer
      }
    }
    sectionTitle
  }
`;
