import { Box, Container, Flex, Link, Text } from 'voom-gatsby';

import { getHref } from '~utils/helpers';

import { CtaProps } from '~types/sanity';

interface FooterLegalProps {
  links: CtaProps[];
}

export const FooterLegal = ({ links }: FooterLegalProps): JSX.Element => {
  return (
    <Box sx={{ bg: 'grey200', py: 5, zIndex: 2, position: 'relative' }}>
      <Container>
        <Flex
          sx={{
            justifyContent: 'flex-start',
            alignItems: ['start', 'center'],
            fontSize: 'xs',
            flexDirection: ['column', null, 'row'],
          }}
        >
          <Text
            sx={{
              fontWeight: 'medium',
              color: 'grey400',
              sup: { color: 'grey400' },
              mb: [2, 0],
              mr: [0, 5],
            }}
          >
            {`© ${new Date().getFullYear()} Voom™ Medical Devices. All Rights Reserved.`}
          </Text>
          <Flex sx={{ flexDirection: ['column', 'row'] }}>
            {links.map((cta) => {
              return (
                <Link
                  key={cta.label}
                  variant={'legal'}
                  href={getHref(cta)}
                  sx={{ mb: [2, 0], mr: [0, 5] }}
                >
                  {cta.label}
                </Link>
              );
            })}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
