import { Flex, Heading, Link, Paragraph, Spacer, Text } from 'voom-gatsby';

import { PhoneLink } from '~components';

import { SanityDoctorOffice } from '~types/sanity';

export const DoctorOfficeCard = ({
  office,
  index,
  officeCount,
}: {
  office: SanityDoctorOffice;
  index: number;
  officeCount: number;
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        p: [4, null, 6],
        mb: officeCount > 1 ? 4 : undefined, // dont cover up arrows
        fontSize: 'md',
        width: '100%',
      }}
    >
      {officeCount > 1 && (
        <>
          <Paragraph variant="copyright" sx={{ fontWeight: 'semibold' }}>{`${
            index < 10 ? '0' : ''
          }${index + 1} of ${
            officeCount < 10 ? '0' : ''
          }${officeCount}`}</Paragraph>
          <Spacer space={2} />
        </>
      )}
      <Heading sx={{ fontWeight: 'semibold' }}>{office.practiceName}</Heading>
      <Spacer space={3} />
      <Paragraph>{office.streetAddress}</Paragraph>
      {office.extendedAddress && (
        <Paragraph>{office.extendedAddress}</Paragraph>
      )}
      <Paragraph>
        <Text>{`${office.city.name}, `}</Text>
        <Text>{`${office.state.abbreviation}, `}</Text>
        <Text>{office.zip}</Text>
      </Paragraph>
      <Link
        variant="inline"
        sx={{ fontSize: 'md', m: 0 }}
        href={`https://www.google.com/maps/dir//${office.streetAddress.replaceAll(
          ' ',
          '+',
        )}+${office.city.name}+${office.state.abbreviation}+${office.zip}`}
      >
        Get Directions
      </Link>
      <Spacer space={3} />
      <PhoneLink office={office} fontSize="sm" />
    </Flex>
  );
};
