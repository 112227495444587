import { useLocation } from '@reach/router';
import { Autocomplete } from '@react-google-maps/api';
import { navigate } from 'gatsby';
import { FC, useEffect, useRef, useState } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  Box,
  IconButton,
  Input,
  Paragraph,
  Spinner,
  ThemeUIStyleObject,
} from 'voom-gatsby';

import { useGoogleMapInstance } from '~utils/map';

import { ReactComponent as GeolocationIcon } from '~svg/icons/icon-geolocation.svg';

interface Location {
  address: string;
  lat?: number;
  lng?: number;
}
interface GeoLocationFormProps {
  placeholder?: string;
  sx?: ThemeUIStyleObject;
  className?: string;
}

export const GeolocationForm: FC<GeoLocationFormProps> = ({
  placeholder,
  sx,
  className,
}) => {
  const [location, setLocation] = useState<Location>({
    address: '',
    lat: undefined,
    lng: undefined,
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (location.lat || location.lng || location.address !== '') {
      const urlParams = new URLSearchParams(
        location as unknown as Record<string, string>,
      );
      const resultUrl = `/find-a-doctor/doctors?${urlParams.toString()}`;
      window.localStorage.setItem('doctorSearchResultUrl', resultUrl);
      navigate(resultUrl);
    }
  }, [location]);

  const { search } = useLocation();
  useEffect(() => {
    if (search === '') {
      window.localStorage.removeItem('doctorSearchResultUrl');
    }
  }, [search]);
  const [query] = useQueryParams({
    address: StringParam,
    lat: NumberParam,
    lng: NumberParam,
  });

  const [isFetchingGeo, setIsFetchingGeo] = useState(false);
  const [isUsingGeo, setIsUsingGeo] = useState(false);

  useEffect(() => {
    if (query.lat && !query.address) {
      setIsUsingGeo(true);
    } else {
      setIsUsingGeo(false);
    }
  }, [query]);

  const { isLoaded } = useGoogleMapInstance();

  const searchRef = useRef<google.maps.places.Autocomplete>();

  const onLoadSearch = (ref: google.maps.places.Autocomplete) =>
    (searchRef.current = ref);

  const onPlacesChanged = () => {
    const place = searchRef.current?.getPlace();
    const location = {
      address: place?.formatted_address ?? '',
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
    };
    setLocation(location);
  };

  const getLocation = () => {
    setIsFetchingGeo(true);
    if (!navigator.geolocation) {
      setError('Geolocation is not supported by your browser');
      setIsFetchingGeo(false);
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            address: '',
          });
          setIsFetchingGeo(false);
        },
        () => {
          setError('Unable to retrieve your location');
          setIsFetchingGeo(false);
        },
      );
    }
  };

  useEffect(() => {
    if (location.lat) {
      setError(null);
    }
  }, [location.lat]);

  return (
    <Box
      className={className}
      sx={{
        position: 'relative',
        width: ['full', 375],
        height: 65,
        ...sx,
      }}
    >
      {isLoaded && (
        <Autocomplete
          onLoad={onLoadSearch}
          onPlaceChanged={onPlacesChanged}
          options={{
            types: ['geocode'],
            componentRestrictions: { country: 'us' },
          }}
          sx={{ height: 'full' }}
        >
          <Input
            aria-label="Doctor Search input"
            variant="autocompleteInput"
            sx={{ bg: 'white' }}
            placeholder={
              isUsingGeo
                ? 'Using Geolocation'
                : query.address ??
                  placeholder ??
                  'Search by City, State, or ZIP'
            }
          />
        </Autocomplete>
      )}
      <IconButton
        onClick={getLocation}
        variant="iconGhost"
        type="button"
        aria-label="Get GeoLocation"
        sx={{
          position: 'absolute',
          right: 2,
          top: '50%',
          transform: 'translateY(-50%)',
          width: [40, null, 45],
          height: [40, null, 45],

          svg: {
            width: [15, null, 20],
            height: [15, null, 20],
            color: isUsingGeo || !isFetchingGeo ? 'primary' : 'grey400',
          },
          '@media (hover: hover)': {
            ':hover': {
              svg: {
                color: isUsingGeo || !isFetchingGeo ? 'primary' : 'grey400',
              },
            },
          },
        }}
      >
        {isFetchingGeo ? <Spinner /> : <GeolocationIcon />}
      </IconButton>
      {error && (
        <Paragraph
          variant="copyright"
          sx={{
            color: 'white',
            position: 'absolute',
            top: 'calc(100% + 2px)',
            left: 3,
          }}
        >
          {error}
        </Paragraph>
      )}
    </Box>
  );
};
