import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { memo } from 'react';

import { Container, Grid, Section, useImageSources } from 'voom-gatsby';

import { BEFORE_AFTER_CARD_COUNT_QUERY } from '~sections/BeforeAfterCallout';

import { BunionplastyBackground, ContentBlock } from '~components';

import { ContentBlockProps, ImageWithAltProps } from '~types/sanity';

export const Hero = memo(
  ({
    data,
    index,
  }: {
    data: {
      backgroundColor?: string;
      narrowPaddingTop?: boolean;
      narrowPaddingBottom?: boolean;
      withSvg?: boolean;
      content: ContentBlockProps;
      imageSet: {
        desktopImage: ImageWithAltProps;
        mobileImage: ImageWithAltProps;
      };
      desktopRight: boolean;
    };
    index: number;
  }) => {
    const imageSources = useImageSources([
      data.imageSet.mobileImage.asset.gatsbyImageData,
      null,
      null,
      data.imageSet.desktopImage.asset.gatsbyImageData,
    ]);

    const imageBorderStyles = {
      borderTopRightRadius: ['square', null, null, 'square'],
      borderTopLeftRadius: ['square', null, null, 'rounded'],
      borderBottomRightRadius: ['rounded', null, null, 'square'],
      borderBottomLeftRadius: ['rounded', null, null, 'rounded'],
    };

    const { allSanityBeforeAfterCard } = useStaticQuery(
      BEFORE_AFTER_CARD_COUNT_QUERY,
    );

    return (
      <Section
        narrowPaddingTop={data.narrowPaddingTop}
        narrowPaddingBottom={data.narrowPaddingBottom}
        sx={{
          bg: data.backgroundColor,
          py: [0, 0, 0],
        }}
      >
        {data.withSvg && (
          <BunionplastyBackground
            sx={{
              top: [375, '50%', null, -480],
              left: [-186, null, null, -330],
              width: [505, null, null, 850],
              svg: {
                color: data.backgroundColor === 'beige' ? 'pink' : 'white',
                opacity: data.backgroundColor === 'beige' ? 0.07 : 1,
              },
            }}
          />
        )}
        <Grid
          columns={[1, null, null, 2]}
          sx={{
            gridAutoFlow: 'dense',
          }}
        >
          <GatsbyImage
            image={imageSources}
            alt={data.imageSet.mobileImage.alt}
            loading="eager"
            sx={{
              gridColumnStart: [1, null, null, data.desktopRight ? 2 : 1],
              aspectRatio: ['1 / 1', null, '7 / 5', '720 / 800'],
              ...imageBorderStyles,
              img: {
                ...imageBorderStyles,
              },
            }}
          />
          <Container sx={{ display: 'flex', alignItems: 'center' }}>
            <ContentBlock
              content={data.content}
              titleSize="Lg"
              captionSize="Md"
              sx={{
                maxWidth: [510, null, null, null, 750],
                mx: 'auto',
                py: 4,
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
              linkStyles={{
                flexDirection: ['column', null, 'column'],
                'a:not(:last-of-type)': {
                  mr: [0, null, 0],
                  mb: [3, null, 3],
                },
              }}
              headingAs={index === 0 ? 'h1' : undefined}
              replaceHashWithCount={allSanityBeforeAfterCard.totalCount}
            />
          </Container>
        </Grid>
      </Section>
    );
  },
);

Hero.displayName = 'Hero';

export const HERO_QUERY = graphql`
  fragment heroFields on SanityHero {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    withSvg
    content {
      ...sanityContentBlockFields
    }
    desktopRight
    imageSet {
      desktopImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
        alt
      }
      mobileImage {
        alt
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  }
`;
