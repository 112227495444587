import { useMemo } from 'react';

import { Grid, Link, Paragraph, Spacer } from 'voom-gatsby';

interface FooterStatesColumnProps {
  states: {
    name: string;
    slug: {
      current: string;
    };
  }[];
  allSanityDoctor: {
    nodes: {
      doctorOffices: {
        state: {
          name: string;
        };
      }[];
    }[];
  };
}

export const FooterStatesColumn = ({
  states,
  allSanityDoctor,
}: FooterStatesColumnProps): JSX.Element => {
  const statesWithDoctors = useMemo(() => {
    return allSanityDoctor.nodes.reduce((ary, node) => {
      node.doctorOffices.forEach((office) => {
        if (!ary.includes(office.state.name)) {
          ary.push(office.state.name);
        }
      });
      return ary;
    }, [] as string[]);
  }, [allSanityDoctor]);

  return (
    <>
      <Paragraph variant="footerHeading">Find a Doctor</Paragraph>
      <Spacer space={3} />
      {/* TODO: Add additional Columns when more states are added */}
      <Grid gap={0}>
        {states
          .filter((state) => statesWithDoctors.includes(state.name))
          .map((state) => {
            return (
              <Link
                key={state.name}
                sx={{ mb: 4 }}
                variant="footer"
                href={`/find-a-doctor/states/${state.slug.current}`}
              >
                {state.name}
              </Link>
            );
          })}
      </Grid>
    </>
  );
};
