import { CtaProps } from '~types/sanity';

export const getHref = (cta: CtaProps) => {
  if (cta.landingPageRoute?.slug?.current) {
    return `/${cta.landingPageRoute?.slug?.current.replace('home-page', '')}`;
  }
  if (cta.route) return cta.route;
  if (cta.link) return cta.link;
  return '#';
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const isElementInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  const halfHeight = rect.height / 2;
  return (
    rect.bottom > halfHeight &&
    rect.right > 0 &&
    rect.left <
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */ &&
    rect.top + halfHeight <
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */
  );
};
/**
 * Scroll To Id
 *
 * For accessibility its best to scroll and focus on element
 *
 * this function allows us to scroll to the element and focus after scroll is completed
 * by adding an event listener and on completion adding a tabindex to the element,
 * focus on the element
 * and remove the tabindex as cleanup
 *
 * @param dataId - elements id to scroll to
 */
export const scrollToDataId = (dataId: string | number) => {
  const element: HTMLElement | null = document.querySelector(
    `[data-id="${dataId}"]`,
  );
  if (!element || isElementInViewport(element)) return;

  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
};
