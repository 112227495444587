import { atom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { getDistance, getMapBounds, useGoogleMapInstance } from '~utils/map';

import { DoctorResult, SanityDoctor, SanityDoctorOffice } from '~types/sanity';

export const resultFiltersAtom = atom<{
  distance: number | null;
  consultation: string | null;
  gender: string | null;
  language: string | null;
}>({
  distance: null,
  consultation: null,
  gender: null,
  language: null,
});

export const mobileResultsViewAtom = atom<'list' | 'map'>('list');

export const useDoctorResults = (
  doctors: SanityDoctor[],
  pageContext: { boundary?: string; abbreviation?: string },
) => {
  const [query] = useQueryParams({
    address: StringParam,
    lat: NumberParam,
    lng: NumberParam,
  });

  const { boundary, abbreviation } = pageContext;

  const hasUserLocation = typeof query.lat === 'number';

  const { distance } = useAtomValue(resultFiltersAtom);

  const distanceRadius = distance ? distance : 100000;

  const [doctorResults, setDoctorResults] = useState<DoctorResult[] | null>(
    null,
  );
  const [nullStateDoctors, setNullStateDoctors] = useState<
    DoctorResult[] | null
  >(null);

  const [addressDisplay, setAddressDisplay] = useState<string>('');

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const allOffices = useMemo(() => {
    return doctors.reduce(
      (array, doctor) => {
        doctor.doctorOffices.map((o) => {
          array.push({
            ...o,
            name: doctor.name,
            credentials: doctor.credentials,
            consultations: doctor.consultations,
            badges: doctor.badges,
            image: doctor.gallery[0],
            slug: doctor.slug,
          });
        });
        return array;
      },
      [] as Omit<DoctorResult, 'distance'>[],
    );
  }, [doctors]);

  const [userLocation, setUserLocation] = useState<
    SanityDoctorOffice['geolocation'] | null
  >(null);

  useEffect(() => {
    if (query.lat && query.lng) {
      setUserLocation({
        lat: query.lat,
        lng: query.lng,
      });
    } else {
      const getIpgeo = async () => {
        try {
          const response = await fetch(`/geolocation`);
          const data = await response.json();
          setUserLocation({
            lat: data.geo.latitude,
            lng: data.geo.longitude,
          });
        } catch {
          // Set to New York if netlify edge function fails
          setUserLocation({
            lat: 40.7127753,
            lng: -74.0059728,
          });
        }
      };
      getIpgeo();
    }
  }, [query]);

  const { isLoaded, googleMapInstance, onLoad, onUnmount } =
    useGoogleMapInstance();

  const count = doctorResults?.length ?? 0;
  useEffect(() => {
    const getAddressToDisplay = async () => {
      const text = `${count} Bunion Surgery Doctor${count > 1 || count === 0 ? 's' : ''}`;

      if (boundary) setAddressDisplay(`${text} in ${boundary}`);
      if (!hasUserLocation && !boundary)
        setAddressDisplay(`${text} in The United States`);
      if (query.address) setAddressDisplay(`${text} Near ${query.address}`);

      if (hasUserLocation && !query.address && googleMapInstance) {
        const geocoder = new google.maps.Geocoder();
        const latLng = new google.maps.LatLng(
          userLocation?.lat ?? 0,
          userLocation?.lng ?? 0,
        );

        const { results } = await geocoder.geocode({
          location: latLng,
        });
        const city = results[0].address_components.find((c) =>
          c.types.includes('locality'),
        )?.long_name;
        const state = results[0].address_components.find((c) =>
          c.types.includes('administrative_area_level_1'),
        )?.short_name;
        setAddressDisplay(`${text} Near ${city}, ${state}`);
      }
    };
    getAddressToDisplay();
  }, [
    query,
    googleMapInstance,
    userLocation,
    count,
    hasUserLocation,
    boundary,
  ]);

  useEffect(() => {
    if (googleMapInstance && boundary) {
      // get boundary location and find offices inside that boundary
      const officesInBoundary = allOffices.reduce((array, o) => {
        if (o.state.name === boundary || o.city.name === boundary) {
          array.push({ ...o, distance: null });
        }
        return array;
      }, [] as DoctorResult[]);

      setDoctorResults(officesInBoundary);

      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: boundary }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK && results) {
          // get viewport of boundary. need abbreviation to get viewport of state due to Washington DC
          const bounds =
            results.find((r) => {
              return r.address_components.find(
                (c) => c.short_name === abbreviation,
              );
            })?.geometry.viewport ?? results[0].geometry.viewport;

          // extend bounds to make sure all offices are in viewport
          officesInBoundary.map(({ geolocation }) => {
            bounds.extend(
              new window.google.maps.LatLng(geolocation.lat, geolocation.lng),
            );
          });
          googleMapInstance.setCenter(results[0].geometry.location);

          googleMapInstance.fitBounds(bounds);
        }
      });
    } else if (googleMapInstance && userLocation) {
      const officesInArea = allOffices.reduce((array, o) => {
        const distance = getDistance(userLocation, o.geolocation);
        if (distance < distanceRadius) {
          array.push({ ...o, distance });
        }
        return array.sort((a, b) => (a?.distance ?? 0) - (b?.distance ?? 0));
      }, [] as DoctorResult[]);

      setDoctorResults(officesInArea);

      const closestThreeOffices = allOffices
        .reduce((array, o) => {
          const distance = getDistance(userLocation, o.geolocation);
          array.push({ ...o, distance });
          return array.sort((a, b) => (a?.distance ?? 0) - (b?.distance ?? 0));
        }, [] as DoctorResult[])
        .splice(0, 3);
      setNullStateDoctors(closestThreeOffices);

      const geoLocations = officesInArea.map((o) => {
        return o.geolocation;
      });
      if (geoLocations.length > 0) {
        geoLocations.push(userLocation); // add userLocation
        googleMapInstance && getMapBounds(googleMapInstance, geoLocations);
      }
    }
  }, [
    doctors,
    googleMapInstance,
    userLocation,
    allOffices,
    distanceRadius,
    boundary,
    abbreviation,
  ]);

  return {
    isLoaded,
    onLoad,
    onUnmount,
    googleMapInstance,
    addressDisplay,
    selectedIndex,
    setSelectedIndex,
    hasUserLocation,
    userLocation,
    doctorResults,
    nullStateDoctors,
  };
};
