import { FC } from 'react';

import { Flex, Spacer, Text, ThemeUIStyleObject } from 'voom-gatsby';

import { ReactComponent as IconSmile } from '~svg/icons/icon-smile.svg';
import { ReactComponent as IconTelehealth } from '~svg/icons/icon-telehealth.svg';

import { SanityDoctorConsultationTag } from '~types/sanity';

interface ConsultationTagProps {
  consultation: SanityDoctorConsultationTag;
  withText?: boolean;
  sx?: ThemeUIStyleObject;
  className?: string;
}

export const ConsultationTag: FC<ConsultationTagProps> = ({
  consultation,
  withText = true,
  sx,
  className,
}) => {
  const Icon = consultation === 'In-Person' ? IconSmile : IconTelehealth;
  return (
    <Flex
      sx={{
        bg: 'white',
        borderRadius: 'rounded',
        color: 'red',
        px: withText ? 2 : 1,
        py: 1,
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      className={className}
    >
      {withText && (
        <>
          <Text
            variant="copyright"
            sx={{ color: 'red', fontWeight: 'medium', whiteSpace: 'nowrap' }}
          >
            {consultation}
          </Text>
          <Spacer direction="horizontal" space={2} />
        </>
      )}
      <Icon sx={{ width: 15, height: 15 }} />
    </Flex>
  );
};
