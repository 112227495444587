import {
  Container,
  Divider,
  Flex,
  Paragraph,
  Section,
  StyledSup,
} from 'voom-gatsby';

import { ReactComponent as BunionplastyIcon } from '~svg/brand/bunionplasty-icon.svg';

export const DoctorCallout = ({ doctorCallout }: { doctorCallout: string }) => {
  return (
    <Section
      sx={{
        py: [3, null, 5],
        bg: 'white',
        overflow: 'hidden',
      }}
    >
      <Container
        variant="doctorContainer"
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Divider sx={{ borderColor: 'grey300', my: [3, null, 5] }} />
        <Flex
          sx={{
            flexDirection: ['column', null, 'row'],
            alignItems: ['flex-start', null, 'center'],
          }}
        >
          <BunionplastyIcon
            sx={{
              width: [50, null, 100],
              flex: ['0 0 50px', null, '0 0 100px'],
              mr: [0, null, 4],
              mb: [2, null, 0],
            }}
          />
          <Paragraph variant="doctorCallout">
            <StyledSup>{doctorCallout}</StyledSup>
          </Paragraph>
        </Flex>
        <Divider sx={{ borderColor: 'grey300', my: [3, null, 5] }} />
      </Container>
    </Section>
  );
};
