import { useWindowWidth } from '@react-hook/window-size';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';

import {
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  Heading,
  Paragraph,
  Section,
  Spacer,
  StyledSup,
  useImageSources,
} from 'voom-gatsby';

import {
  AmbientVideo,
  BunionplastyBackground,
  ContentBlock,
} from '~components';

import { ReactComponent as CircleSvg } from '~svg/icons/circle.svg';

import { ContentBlockProps, ImageWithAltProps } from '~types/sanity';

const Dots = ({ maxCount = 20 }) => {
  const windowWidth = useWindowWidth();
  // only render dots client side
  const [isClient, setIsClient] = useState(false);
  const [count, setCount] = useState<number | null>(null);
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isClient && typeof window !== 'undefined') {
      const count = windowWidth < 768 ? maxCount / 2 : maxCount;
      setCount(count);
    }
  }, [maxCount, windowWidth, isClient]);

  return (
    <Flex
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: -6,
        flexDirection: 'column',
        justifyContent: 'space-between',
        svg: { color: 'grey300', width: 9, height: 9 },
      }}
    >
      {count &&
        [...Array(count)].map((_, index) => {
          return <CircleSvg key={index} />;
        })}
    </Flex>
  );
};
export const Timeline = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    withSvg?: boolean;
    content: ContentBlockProps;
    imageSet: {
      desktopImage: ImageWithAltProps;
      mobileImage: ImageWithAltProps;
    };
    video?: {
      file: {
        asset: {
          url: string;
        };
      };
      type: string;
    };
    timelineList: {
      title: string;
      caption: string;
    }[];
    disclaimer?: string;
  };
  index: number;
}) => {
  const imageSources = useImageSources([
    data.imageSet.mobileImage.asset.gatsbyImageData,
    null,
    data.imageSet.desktopImage.asset.gatsbyImageData,
  ]);

  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{
        bg: data.backgroundColor ?? 'white',
      }}
    >
      {data.withSvg && (
        <BunionplastyBackground
          sx={{
            top: [-140, null, -480],
            left: [-139, null, -330],
            width: [450, null, 1150],
            svg: {
              color: 'grey200',
            },
          }}
        />
      )}
      <Container
        variant="narrowContainer"
        sx={{ zIndex: 2, position: 'relative' }}
      >
        <ContentBlock
          content={data.content}
          titleSize="Lg"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: [900, null, null, null, 1100],
            mx: 'auto',
            textAlign: 'center',
            mb: 5,
            position: 'relative',
            zIndex: 1,
          }}
          linkStyles={{
            flexDirection: ['column', null, 'column'],
            'a:not(:last-of-type)': {
              mr: [0, null, 0],
              mb: [4, null, 4],
            },
          }}
        />
        <Grid
          columns={[1, null, 2]}
          sx={{
            rowGap: 6,
          }}
        >
          {data.video ? (
            <AmbientVideo
              url={data.video.file.asset.url}
              type={data.video.type}
            />
          ) : (
            <GatsbyImage
              image={imageSources}
              alt={data.imageSet.mobileImage.alt}
              loading="eager"
              sx={{
                borderRadius: 'rounded',
                img: {
                  borderRadius: 'rounded',
                },
              }}
            />
          )}
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              position: 'relative',
              ml: [7, null, 9],
            }}
          >
            <Dots />
            {data.timelineList.map((item, index) => {
              const isLastChild = index === data.timelineList.length - 1;
              return (
                <Box key={item.title} sx={{ position: 'relative' }}>
                  <Heading
                    sx={{
                      fontSize: ['lg', null, '3xl'],
                      maxWidth: 360,
                      position: 'relative',
                    }}
                  >
                    <Flex
                      sx={{
                        position: 'absolute',
                        right: 'calc(100% + 23px)',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 40,
                        height: 40,
                        borderRadius: 'circle',
                        bg: 'pink',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: 'md',
                        boxShadow: 'timelineIcon',
                      }}
                    >
                      {index + 1}
                    </Flex>
                    {item.title}
                  </Heading>
                  <Spacer space={2} />
                  <Paragraph
                    sx={{ fontSize: ['sm', null, 'md'], maxWidth: 360 }}
                  >
                    {item.caption}
                  </Paragraph>
                  <Spacer />
                  {!isLastChild && (
                    <Divider
                      sx={{ color: 'grey200', opacity: 1, my: [4, 6] }}
                    />
                  )}
                </Box>
              );
            })}
          </Flex>
        </Grid>
        {data.disclaimer && (
          <Paragraph
            variant="disclaimer"
            sx={{
              mx: 'auto',
              maxWidth: 400,
              textAlign: 'center',
              mt: 6,
              mb: -7,
            }}
          >
            <StyledSup>{data.disclaimer}</StyledSup>
          </Paragraph>
        )}
      </Container>
    </Section>
  );
};

export const TIMELINE_QUERY = graphql`
  fragment timelineFields on SanityTimeline {
    _type # needed to select this component to render in template
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    withSvg
    content {
      ...sanityContentBlockFields
    }
    timelineList {
      title
      caption
    }
    imageSet {
      desktopImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
        alt
      }
      mobileImage {
        alt
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
    video {
      file {
        asset {
          url
        }
      }
      type
    }
    disclaimer
  }
`;
