import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useRef } from 'react';

import { Box } from 'voom-gatsby';

import { GridCalloutProps } from '~types/sanity';

import { ContentBlock } from './ContentBlock';
import { GatsbyBackgroundImage } from './GatsbyBackgroundImage';

/**
 * Get Callout Index
 *
 * This function calculates the index of the callout to display based on the frequency and the current index.
 * since we are using a 0 based index, we need to add 1 to the index before dividing by the frequency.
 *
 * and since we are not rendering the Grid Callout on the first index, we need to subtract 1 from the calculated index.
 *
 * @param index
 * @param frequency
 * @param aryLength
 * @returns
 */
const getCalloutIndex = (
  index: number,
  frequency: number,
  aryLength: number,
) => {
  const calculatedIndex = ((index + 1) / frequency) % aryLength;
  return calculatedIndex !== 0 ? calculatedIndex - 1 : aryLength - 1;
};

export const GridCallout = ({
  frequency,
  index,
  gridCallouts,
}: GridCalloutProps & { index: number }) => {
  const calloutIndex = getCalloutIndex(index, frequency, gridCallouts.length);

  const currentCallout = gridCallouts[calloutIndex];
  if (currentCallout._type === 'backgroundImageCard') {
    const image = getImage(
      currentCallout.backgroundImage.asset.gatsbyImageData,
    );
    return (
      <Box
        sx={{
          position: 'relative',
          borderRadius: 'rounded',
          overflow: 'hidden',
          mb: 5,
          aspectRatio: '1/1',
        }}
      >
        <GatsbyBackgroundImage
          images={[image]}
          alt={currentCallout.backgroundImage.alt}
          sx={{
            filter: 'brightness(60%)',
          }}
        />
        <ContentBlock
          content={currentCallout.content}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            p: 5,
            height: 'full',
          }}
          titleStyles={{
            '&, & span': {
              fontSize: ['2xl', 'xl', '3xl', null, '5xl'],

              color: 'white',
            },
          }}
          linkStyles={{
            mt: ['auto', 'auto', 'auto'],
          }}
          ctaOnClick={() => {
            if (window.gtag) {
              window.gtag('event', 'Grid Callout Link Clicked', {
                pathname: window.location.pathname,
              });
            }
          }}
        />
      </Box>
    );
  }
};

export const SANITY_CONTENT_QUERY_FRAGMENT = graphql`
  fragment sanityGridCalloutFields on SanityGridCallout {
    frequency
    gridCallouts {
      _type
      content {
        _rawTitle
        ctas {
          type
          label
          route
          link
          landingPageRoute {
            slug {
              current
            }
          }
        }
      }
      backgroundImage {
        asset {
          gatsbyImageData(layout: FIXED, width: 400, placeholder: NONE)
        }
        alt
      }
    }
  }
`;
