import { useForm } from 'react-hook-form';
import {
  Honeypot,
  NetlifyFormComponent,
  NetlifyFormProvider,
  useNetlifyForm,
} from 'react-netlify-forms';

import {
  Box,
  Flex,
  FormInput,
  IconButton,
  Link,
  Paragraph,
  Spacer,
  StyledSup,
  useDefaultFormFields,
} from 'voom-gatsby';

import { ReactComponent as ArrowIcon } from '~svg/icons/icon-arrow.svg';

interface FooterFormColumnProps {
  phoneNumber?: {
    display: string;
    link: string;
  };
  email?: {
    display: string;
    link: string;
  };
  socialLinks: {
    icon: {
      svg: string;
    };
    title: string;
    URL: string;
  }[];
}

export const FooterFormColumn = ({
  phoneNumber,
  email,
  socialLinks,
}: FooterFormColumnProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const netlify = useNetlifyForm({
    name: 'Footer form',
    action: '/thanks',
    honeypotName: 'bot-field',
    onSuccess: () => {
      if (window.gtag) {
        window.gtag('event', 'Form Submission - Footer Email', {});
      }
      reset();
    },
  });

  const DefaultFormFields = useDefaultFormFields(register, reset);

  const onSubmit = (data: unknown) => netlify.handleSubmit(null, data);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <Paragraph variant="footerHeading">Contact Us Today</Paragraph>
      <Spacer space={4} />
      {phoneNumber && (
        <Link
          href={`tel:${phoneNumber.link}`}
          variant="footer"
          sx={{ fontSize: 'xs' }}
        >
          {phoneNumber.display}
        </Link>
      )}
      <Spacer space={2} />

      {email && (
        <Link
          href={`mailto:${email.link}?subject=You're one step closer! Get back on your feet fast.`}
          variant="footer"
          sx={{ fontSize: 'xs' }}
        >
          {email.display}
        </Link>
      )}
      <Spacer space={1} />
      <Flex sx={{ ml: -3 }}>
        {socialLinks.map((social) => {
          return (
            <Link
              variant="iconGhost"
              key={social.title}
              href={social.URL}
              aria-label={social.title}
            >
              <Box
                dangerouslySetInnerHTML={{ __html: social.icon.svg }}
                sx={{
                  '&, svg': {
                    mx: 'auto',
                    width: 22,
                    height: 22,
                  },
                }}
              />
            </Link>
          );
        })}
      </Flex>
      <Spacer space={6} />

      <Paragraph variant="footerHeading">Stay in Touch</Paragraph>
      <Spacer space={3} />
      <Paragraph sx={{ fontSize: 'xs' }}>
        <StyledSup>
          Discover new locations, meet new doctors, get foot health tips, and
          more.
        </StyledSup>
      </Paragraph>
      <Spacer space={3} />
      <NetlifyFormProvider {...netlify}>
        <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
          <Honeypot />
          <Box sx={{ position: 'relative' }}>
            <FormInput
              label="Email"
              name="email"
              type="email"
              register={register('email', { required: true })}
              errors={errors.email}
              control={control}
            />
            <DefaultFormFields />
            <IconButton
              type="submit"
              aria-label="Submit"
              variant="iconPrimary"
              sx={{
                position: 'absolute',
                right: 2,
                top: '50%',
                transform: 'translateY(-50%)',
                width: [30, null, 30],
                height: [30, null, 30],
                svg: {
                  width: [15, null, 15],
                  height: [15, null, 15],
                },
              }}
            >
              <ArrowIcon
                sx={{
                  color: 'primary',
                }}
              />
            </IconButton>
          </Box>
          {netlify.error && (
            <Paragraph sx={{ py: 4, textAlign: 'center' }}>
              Sorry, we could not reach servers. Please try again later. If this
              problem continues, please contact us. Thanks!
            </Paragraph>
          )}
        </NetlifyFormComponent>
      </NetlifyFormProvider>
    </Flex>
  );
};
