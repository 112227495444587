import { Dispatch, SetStateAction } from 'react';

import { Box, Button, DropdownButton, Flex, Text } from 'voom-gatsby';

interface FilterRowProps<T> {
  filters: { label: string; value: T }[];
  label: string;
  activeFilter: T;
  setActiveFilter: Dispatch<SetStateAction<T>>;
}

export const FilterRow = <T,>({
  filters,
  label,
  activeFilter,
  setActiveFilter,
}: FilterRowProps<T>) => {
  return (
    <>
      <Box sx={{ display: ['block', null, 'none'] }}>
        <DropdownButton
          label={label}
          selectedValue={activeFilter}
          items={filters}
          onClick={setActiveFilter}
        />
      </Box>
      <Flex sx={{ display: ['none', null, 'flex'], gap: 4 }}>
        {filters.map((filter) => {
          return (
            <Button
              className={filter.value === activeFilter ? 'active' : ''}
              variant="navButton"
              onClick={() => setActiveFilter(filter.value as T)}
              key={filter.label}
            >
              <Text>{filter.label}</Text>
            </Button>
          );
        })}
      </Flex>
    </>
  );
};
