import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Box, Button, Flex } from 'voom-gatsby';

import { ReactComponent as PauseIcon } from '~svg/icons/icon-pause.svg';
import { ReactComponent as PlayIcon } from '~svg/icons/icon-play.svg';

import { RevealVideoCardProps } from '~types/sanity';

export interface VideoPlayerProps {
  src: string;
}

interface RevealVideoState {
  videoPlaying: boolean;
  setVideoPlayingIndex: Dispatch<SetStateAction<number | null>>;
  index: number;
}

export const RevealVideoCard: FC<RevealVideoCardProps & RevealVideoState> = ({
  video,
  image,
  videoPlaying,
  setVideoPlayingIndex,
  index,
}) => {
  // we only want to download video if user interacts
  const [videoLoaded, setVideoLoaded] = useState(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoPlaying) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [videoPlaying]);

  return (
    <motion.div
      whileHover={{ y: -5, boxShadow: '0px 6px 20px 0 rgba(0, 0, 0, 0.36)' }}
      style={{ boxShadow: '0px 6px 20px 0 rgba(0, 0, 0, 0.25)' }}
      sx={{
        borderRadius: 'rounded',
        my: 4,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          borderRadius: 'rounded',
          overflow: 'hidden',
          aspectRatio: '443 / 786',
        }}
      >
        {videoLoaded ? (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video
            ref={videoRef}
            playsInline
            onEnded={() => setVideoPlayingIndex(null)}
            sx={{ width: 'full', height: 'full' }}
          >
            <source src={video.file.asset.url} type={video.type} />
            Your browser does not support the video tag.
          </video>
        ) : (
          <GatsbyImage
            image={image.asset.gatsbyImageData}
            alt=""
            sx={{
              width: 'full',
              height: 'full',
              borderRadius: 'rounded',
              img: {
                borderRadius: 'rounded',
              },
            }}
          />
        )}
        <Button
          onClick={() => {
            setVideoLoaded(true);
            setVideoPlayingIndex(videoPlaying ? null : index);
          }}
          aria-label="Play Video"
          variant="ghost"
          sx={{
            position: 'absolute',
            inset: 0,
            m: 'auto',
            zIndex: 1,
          }}
        >
          <Flex
            sx={{
              boxShadow: 'iconShadow',
              borderRadius: 'circle',
              bg: 'white',
              opacity: videoPlaying ? 0.4 : 1,
              width: 60,
              height: 60,
              alignSelf: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {videoPlaying ? (
              <PauseIcon sx={{ width: 22, height: 22 }} />
            ) : (
              <PlayIcon sx={{ width: 22, height: 22 }} />
            )}
          </Flex>
        </Button>
      </Box>
    </motion.div>
  );
};
