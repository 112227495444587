import { GatsbyImage } from 'gatsby-plugin-image';
import { forwardRef } from 'react';
import type { Ref } from 'react';

import { Box, Flex, Heading, Paragraph, Spacer, StyledSup } from 'voom-gatsby';

import { ReactComponent as CircleSvg } from '~svg/icons/circle.svg';

import { IconCardProps } from '~types/sanity';

interface ImageCarouselCardProps {
  card: IconCardProps;
  dotSeparators?: boolean;
  circularImages?: boolean;
  variableWidth: boolean;
}

export const ImageCarouselCard = forwardRef(function ImageCarouselCard(
  {
    card,
    dotSeparators,
    variableWidth,
    circularImages,
  }: ImageCarouselCardProps,
  forwardedRef: Ref<HTMLDivElement>,
) {
  return (
    <Box
      ref={forwardedRef}
      sx={{
        px: [0, 5],
        minWidth: variableWidth ? [400] : 'unset',
        maxWidth: variableWidth ? [400] : 'unset',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: 265,
          maxHeight: 265,
          mx: ['auto', variableWidth ? 0 : 'auto'],
          aspectRatio: '1 / 1',
          mb: [7, 0],
        }}
      >
        {card.icon && (
          <Box
            dangerouslySetInnerHTML={{ __html: card.icon.svg }}
            sx={{
              width: [70, 75],
              height: [70, 75],
              position: 'absolute',
              left: ['50%', 0],
              top: ['100%', '50%'],
              transform: ['translate(-50%, -50%)', 'translate(-20%, -50%)'],
              zIndex: 1,
            }}
          />
        )}
        <GatsbyImage
          image={card.image.asset.gatsbyImageData}
          alt={card.image.alt}
          sx={{
            borderRadius: circularImages ? 'circle' : 'none',
            zIndex: -1,
            img: { borderRadius: circularImages ? 'circle' : 'none' },
          }}
        />
        {dotSeparators && (
          <Flex
            sx={{
              position: 'absolute',
              left: '100%',
              top: '50%',
              transform: 'translateY(-50%)',
              width: [133, 120],
              justifyContent: 'space-evenly',
              svg: {
                color: 'grey300',
                width: 12,
                height: 12,
              },
            }}
          >
            <CircleSvg />
            <CircleSvg />
            <CircleSvg />
            <CircleSvg sx={{ display: ['block', 'none'] }} />
          </Flex>
        )}
      </Box>
      <Spacer space={[3, 5]} />
      <Box
        sx={{
          textAlign: ['center', 'left'],
          mx: 'auto',
          maxWidth: [290, 350],
        }}
      >
        <Heading sx={{ fontSize: ['lg', '3xl'] }}>
          <StyledSup>{card.title}</StyledSup>
        </Heading>
        <Spacer space={2} />
        <Paragraph sx={{ fontSize: 'md' }}>
          <StyledSup>{card.caption}</StyledSup>
        </Paragraph>
      </Box>
    </Box>
  );
});
