import { graphql, useStaticQuery } from 'gatsby';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { DropdownButton } from 'voom-gatsby';

import { resultFiltersAtom } from '~hooks/use-doctor-results';

import { ReactComponent as IconFilter } from '~svg/icons/icon-filter.svg';

export const DistanceFilter = () => {
  const { allSanityDistanceFilter } = useStaticQuery(DISTANCE_FILTER_QUERY);

  const [{ distance }, setResultFilters] = useAtom(resultFiltersAtom);

  const defaultDistance =
    allSanityDistanceFilter.nodes.find(
      (n: { defaultValue: boolean; value: number }) => n.defaultValue,
    )?.value ?? 100;

  useEffect(() => {
    if (distance === null) {
      setResultFilters((prev) => ({
        ...prev,
        distance: defaultDistance, // set default Distance on initial render
      }));
    }
  }, [distance, defaultDistance, setResultFilters]);

  const handleDropdownClick = (selectedDistance: number) => {
    if (window.gtag) {
      window.gtag('event', 'Map Distance Change', {
        distance: selectedDistance,
      });
    }
    setResultFilters((prev) => ({
      ...prev,
      distance: selectedDistance,
    }));
  };

  if (distance === null) return null; // only render Distance filter if distance value is not null

  return (
    <DropdownButton
      label="Distance"
      onClick={handleDropdownClick}
      items={allSanityDistanceFilter.nodes.map((node: { value: number }) => {
        const label = `${node.value} mi.`;
        return { label, value: node.value };
      })}
      selectedValue={distance}
      MobileIcon={IconFilter}
      mobileModal={true}
      sx={{
        'button[data-id="mobile-icon-button"]': {
          variant: 'buttons.iconOutline',
          display: ['flex', null, 'none'],
        },
        'button[data-id="dropdown-button"]': {
          variant: 'buttons.outline',
          display: ['none', null, 'flex'],
          boxShadow: 'none',

          ':hover': {
            boxShadow: 'none',
          },
        },
      }}
    />
  );
};

const DISTANCE_FILTER_QUERY = graphql`
  query DistanceFilter {
    allSanityDistanceFilter(sort: { value: ASC }) {
      nodes {
        value
        defaultValue
      }
    }
  }
`;
