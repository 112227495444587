import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Parallax } from 'react-scroll-parallax';

import {
  Box,
  CertifiedBadge,
  Container,
  Flex,
  Grid,
  Paragraph,
  Section,
  StyledSup,
  useImageSources,
} from 'voom-gatsby';

import { ContentBlock } from '~components';

import { ContentBlockProps, ImageWithAltProps } from '~types/sanity';

export const SideBySide = ({
  data,
  index,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    content: ContentBlockProps;
    imageSet: {
      desktopImage: ImageWithAltProps;
      mobileImage: ImageWithAltProps;
      withCertifiedBadge: boolean;
    };
    disclaimer?: string;
    desktopRight: boolean;
    collageImageSet?: {
      desktopImage: ImageWithAltProps;
      mobileImage: ImageWithAltProps;
    };
  };
  index: number;
}) => {
  const imageSources = useImageSources([
    data.imageSet.mobileImage.asset.gatsbyImageData,
    null,
    data.imageSet.desktopImage.asset.gatsbyImageData,
  ]);

  const collageImageSources = data.collageImageSet
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useImageSources([
        data.collageImageSet?.mobileImage.asset.gatsbyImageData,
        null,
        data.collageImageSet?.desktopImage.asset.gatsbyImageData,
      ])
    : undefined;

  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{ bg: data.backgroundColor ?? 'grey200' }}
    >
      <Container>
        <Grid
          columns={[1, null, 2]}
          sx={{
            gridAutoFlow: 'dense',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              gridColumnStart: [1, null, data.desktopRight ? 2 : 1],
              ...(collageImageSources
                ? {
                    aspectRatio: '315 / 370',
                    overflow: 'visible',
                    ml: [5, 6, 3],
                    mb: [6, 7, 0],
                  }
                : {}),
            }}
          >
            {data.imageSet.withCertifiedBadge && <CertifiedBadge />}
            {collageImageSources ? (
              <>
                <GatsbyImage
                  image={imageSources}
                  alt={data.imageSet.mobileImage.alt}
                  sx={{
                    aspectRatio: ['285 / 335', null, '655 / 675'],
                    position: 'absolute',
                    inset: 0,
                    borderRadius: 'rounded',
                    img: { borderRadius: 'rounded' },
                  }}
                />
                <Parallax
                  speed={8}
                  sx={{
                    position: 'absolute',
                    bottom: [-5, -6],
                    left: [-5, -6],
                    zIndex: 2,
                    width: '45%',
                    aspectRatio: ['125 / 150', null, '265 / 310'],
                    borderRadius: 'rounded',
                    img: { borderRadius: 'rounded' },
                  }}
                >
                  <GatsbyImage
                    image={collageImageSources}
                    alt={data.collageImageSet?.mobileImage.alt ?? ''}
                  />
                </Parallax>
              </>
            ) : (
              <GatsbyImage
                image={imageSources}
                alt={data.imageSet.mobileImage.alt}
                sx={{
                  aspectRatio: ['320 / 250', null, '655 / 675'],
                  borderRadius: 'rounded',
                  img: { borderRadius: 'rounded' },
                }}
              />
            )}
          </Box>
          <Flex
            sx={{
              flexDirection: 'column',
              maxWidth: [510, null, null, null, 700],
              height: 'full',
              px: [0, null, 3],
              mx: 'auto',
              ...(data.desktopRight ? { ml: ['auto', null, 0] } : {}),
            }}
          >
            <ContentBlock
              content={data.content}
              titleStyles={{ maxWidth: ['none', null, '90%'] }}
              headingAs={index === 0 ? 'h1' : undefined}
            />
            {data.disclaimer && (
              <Paragraph variant="disclaimer" sx={{ mt: 'auto' }}>
                <StyledSup>{data.disclaimer}</StyledSup>
              </Paragraph>
            )}
          </Flex>
        </Grid>
      </Container>
    </Section>
  );
};

export const SIDE_BY_SIDE_QUERY = graphql`
  fragment sideBySideFields on SanitySideBySide {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    content {
      ...sanityContentBlockFields
    }
    disclaimer
    desktopRight
    imageSet {
      desktopImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
        alt
      }
      mobileImage {
        alt
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      withCertifiedBadge
    }
    collageImageSet {
      desktopImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
        alt
      }
      mobileImage {
        alt
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  }
`;
