import { graphql } from 'gatsby';

import { Box, Container, Flex, Section, StyledSup, Text } from 'voom-gatsby';

import { ContentBlock } from '~components';

import { ContentBlockProps, IconBlockProps } from '~types/sanity';

export const IconCallout = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    content: ContentBlockProps;
    iconList: IconBlockProps[];
  };
  index: number;
}) => {
  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{ py: [6, null, 8], bg: data.backgroundColor }}
    >
      <Container
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', null, '4fr 3fr', '3fr 2fr'],
          columnGap: 7,
        }}
      >
        <ContentBlock content={data.content} titleSize="Lg" captionSize="Lg" />
        <Box>
          {data.iconList.map((iconBlock) => {
            return (
              <Flex
                key={iconBlock.title}
                sx={{
                  alignItems: 'center',
                  py: 4,
                  position: 'relative',
                  ':not(:last-of-type) ::after': {
                    content: '""',
                    width: 'full',
                    height: 2,
                    bg: 'grey300',
                    position: 'absolute',
                    bottom: -1,
                    left: 0,
                    right: 0,
                  },
                }}
              >
                <Box
                  dangerouslySetInnerHTML={{ __html: iconBlock.icon.svg }}
                  sx={{
                    '&, svg': {
                      flex: ['0 0 65px', null, '0 0 85px'],
                      width: [65, null, 85],
                      height: [65, null, 85],
                      mr: 3,
                    },
                  }}
                />
                <Box>
                  <Text variant="iconBlockText" sx={{ fontWeight: 'bold' }}>
                    {iconBlock.title}
                  </Text>
                  <Text> • </Text>
                  <Text variant="iconBlockText">
                    <StyledSup>{iconBlock.caption}</StyledSup>
                  </Text>
                </Box>
              </Flex>
            );
          })}
        </Box>
      </Container>
    </Section>
  );
};

export const ICON_CALLOUT_QUERY = graphql`
  fragment iconCalloutFields on SanityIconCallout {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    content {
      ...sanityContentBlockFields
    }
    iconList {
      icon {
        svg
      }
      caption
      title
    }
  }
`;
