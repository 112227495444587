import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Paragraph,
  Section,
  Spacer,
  Text,
} from 'voom-gatsby';

import { marks } from '~components';

import { ReactComponent as PlusIcon } from '~svg/icons/icon-plus.svg';

import { SanityDoctor } from '~types/sanity';

export const biographyComponents: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => (
      <Paragraph variant="mediumP" sx={{ mb: 3 }}>
        {children}
      </Paragraph>
    ),
  },
  marks: marks,
  list: ({ children }) => (
    <Text as="ul" variant="contentCaptionUl">
      {children}
    </Text>
  ),
  listItem: ({ children }) => (
    <Text as="li" variant="contentCaptionLi">
      {children}
    </Text>
  ),
};

export const DoctorDetails = ({ doctor }: { doctor: SanityDoctor }) => {
  const { doctorDetails } = doctor;
  const { yearStarted } = doctorDetails;

  const yearsInPractice = useMemo(() => {
    if (!yearStarted || yearStarted === 0) return null;
    return new Date().getFullYear() - yearStarted;
  }, [yearStarted]);

  const [isBioOpen, setIsBioOpen] = useState(false);

  return (
    <Section
      sx={{
        pt: [5, null, 7],
        pb: [6, null, 0],
        bg: 'white',
        overflow: 'hidden',
      }}
    >
      <Container
        variant="doctorContainer"
        sx={{ position: 'relative', zIndex: 1 }}
      >
        {doctorDetails._rawBiography && (
          <>
            <Heading variant="doctorSectionHeading">{`About ${doctor.name}`}</Heading>
            <Spacer />
            <motion.div
              initial="collapsed"
              variants={{
                collapsed: { height: 115 },
                expanded: { height: 'auto' },
              }}
              transition={{ type: 'tween' }}
              animate={isBioOpen ? 'expanded' : 'collapsed'}
              sx={{
                overflow: 'hidden',
                position: 'relative',

                ':after': {
                  content: !isBioOpen && '""',
                  position: 'absolute',
                  inset: 0,
                  background: (theme) => theme.colors?.fadeGradient,
                },
              }}
            >
              <PortableText
                value={doctorDetails._rawBiography}
                components={biographyComponents}
              />
            </motion.div>
            <Button
              onClick={() => setIsBioOpen(!isBioOpen)}
              variant="underline"
              sx={{ display: 'flex', alignItems: 'center', my: 3 }}
            >
              {`Read ${isBioOpen ? 'Less' : 'More'}`}
              <Spacer direction="horizontal" space={1} />
              <PlusIcon
                sx={{
                  width: isBioOpen ? 14 : 12,
                  transform: isBioOpen ? 'rotate(45deg)' : 'none',
                  transition: 'transform 0.4s ease-in-out',
                }}
              />
            </Button>
          </>
        )}

        <Divider
          sx={{
            borderColor: 'grey300',
            mt: doctorDetails._rawBiography ? 7 : 0,
            mb: 7,
          }}
        />
        <Spacer />
        <Heading variant="doctorDetailsHeading" sx={{ fontWeight: 'semibold' }}>
          Details
        </Heading>
        <Spacer />
        <Box as="ul" sx={{ '> li': { mb: 5 } }}>
          {yearsInPractice && (
            <li>
              <Heading variant="doctorDetailsHeading">
                Years in Practice
              </Heading>
              <Paragraph variant="doctorDetails">
                {yearsInPractice < 1
                  ? 'Less than 1'
                  : `${yearsInPractice} Years`}
              </Paragraph>
            </li>
          )}
          {doctorDetails.boardStatus?.length > 0 && (
            <li>
              <Heading variant="doctorDetailsHeading">Board Status</Heading>
              <ul>
                {doctorDetails.boardStatus.map((text) => (
                  <Text as="li" key={text} variant="doctorDetails">
                    {text}
                  </Text>
                ))}
              </ul>
            </li>
          )}
          {doctorDetails.fellowshipTrained.length > 0 && (
            <li>
              <Heading variant="doctorDetailsHeading">
                Fellowship Trained
              </Heading>
              <ul>
                {doctorDetails.fellowshipTrained.map((text) => (
                  <Text as="li" key={text} variant="doctorDetails">
                    {text}
                  </Text>
                ))}
              </ul>
            </li>
          )}
          {doctorDetails.languages.length > 0 && (
            <li>
              <Heading variant="doctorDetailsHeading">Languages</Heading>
              <ul>
                {doctorDetails.languages.map(({ value }) => (
                  <Text as="li" key={value} variant="doctorDetails">
                    {value}
                  </Text>
                ))}
              </ul>
            </li>
          )}
          {doctorDetails.gender?.pronoun && (
            <li>
              <Heading variant="doctorDetailsHeading">Pronouns</Heading>
              <Paragraph variant="doctorDetails">
                {doctorDetails.gender.pronoun}
              </Paragraph>
            </li>
          )}
          {doctorDetails.otherCredentials?.map(({ title, list }) => (
            <li key={title}>
              <Heading variant="doctorDetailsHeading">{title}</Heading>
              <ul>
                {list.map((text) => (
                  <Text as="li" key={text} variant="doctorDetails">
                    {text}
                  </Text>
                ))}
              </ul>
            </li>
          ))}
        </Box>
        <Divider sx={{ borderColor: 'grey300', mt: 7, mb: [4, 7] }} />
      </Container>
    </Section>
  );
};
