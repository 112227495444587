import { graphql } from 'gatsby';
import { useRef } from 'react';
import ReactSlickSlider, { Settings } from 'react-slick';

import {
  Box,
  Button,
  Container,
  Flex,
  Paragraph,
  Section,
  Spacer,
  Text,
  useThemeUI,
} from 'voom-gatsby';

import { ContentBlock, ScriptsBackground } from '~components';

import { ReactComponent as StarsIcon } from '~svg/icons/icon-five-stars.svg';

import { ContentBlockProps } from '~types/sanity';

interface TestimonialCard {
  icon: {
    svg: string;
  };
  _rawQuote: ContentBlockProps['_rawTitle'];
  author: string;
  source: string;
  starCount: 1 | 2 | 3 | 4 | 5;
}

interface TestimonialCarouselProps {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    scriptsBackground?: boolean;
    content?: ContentBlockProps;
    testimonials: TestimonialCard[];
  };
  index: number;
}

const TestimonialCard = (testimonial: TestimonialCard) => {
  return (
    <Flex
      sx={{
        display: 'flex',
        px: 20,
        mx: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: [718, null, null, null, 800],
      }}
    >
      <Box
        dangerouslySetInnerHTML={{ __html: testimonial.icon.svg }}
        sx={{
          '&, svg': {
            width: [65, null, 75],
            height: [65, null, 75],
            mx: 'auto',
            mb: 5,
          },
        }}
      />
      <ContentBlock content={{ _rawTitle: testimonial._rawQuote }} />
      <Paragraph
        sx={{
          color: 'pink',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: 'xs',
        }}
      >
        {testimonial.author}
        <Text sx={{ mx: 2, color: 'pink' }}>•</Text>
        {testimonial.source}
      </Paragraph>
      <Spacer space={2} />
      {testimonial.starCount && <StarsIcon sx={{ width: 95 }} />}
    </Flex>
  );
};

export const TestimonialCarousel = ({ data }: TestimonialCarouselProps) => {
  const { theme } = useThemeUI();
  const ref = useRef<ReactSlickSlider>(null);
  const featuredCarouselSettings: Settings = {
    dots: false,
    arrows: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '20%',
    responsive: [
      {
        breakpoint: parseInt(theme.breakpoints?.[2] ?? '1024px'),
        settings: { centerPadding: '10%' },
      },
      {
        breakpoint: parseInt(theme.breakpoints?.[1] ?? '768px'),
        settings: { centerPadding: '0px' },
      },
      {
        breakpoint: parseInt(theme.breakpoints?.[0] ?? '480px'),
        settings: { centerPadding: '0px', autoplay: true },
      },
    ],
  };

  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{ bg: data.backgroundColor }}
    >
      {data.scriptsBackground && <ScriptsBackground />}
      {data.content && (
        <Container>
          <ContentBlock
            content={data.content}
            titleSize="Lg"
            sx={{
              maxWidth: [630, null, null, null, 750],
              mx: 'auto',
              py: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          />
        </Container>
      )}
      {data.testimonials.length === 1 ? (
        <TestimonialCard {...data.testimonials[0]} />
      ) : (
        <ReactSlickSlider
          // We need ref to manage slider but the package's ts definition does not include ref
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}
          {...featuredCarouselSettings}
          sx={{
            '.slick-list': {
              '.slick-slide': {
                textAlign: 'center',
                opacity: 0.2,
                transition: 'opacity 0.2s ease-in-out',

                '&.slick-active': {
                  opacity: 1,
                },
              },
            },
          }}
        >
          {data.testimonials.map((testimonial, index) => {
            return (
              <Button
                variant="ghost"
                onClick={() => ref.current?.slickGoTo(index)}
                key={index}
              >
                <TestimonialCard {...testimonial} />
              </Button>
            );
          })}
        </ReactSlickSlider>
      )}
    </Section>
  );
};

export const TESTIMONIAL_CAROUSEL_QUERY = graphql`
  fragment testimonialCarouselFields on SanityTestimonialCarousel {
    _type # needed to select this component to render in template
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    scriptsBackground
    content {
      ...sanityContentBlockFields
    }
    testimonials {
      icon {
        svg
      }
      _rawQuote
      author
      source
      starCount
    }
  }
`;
