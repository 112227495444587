import { graphql, useStaticQuery } from 'gatsby';

import { GatsbyBackgroundImage } from '~components';

export const ScriptsBackground = () => {
  const { backgroundImg } = useStaticQuery(BACKGROUND_SCRIPTS_QUERY);

  return (
    <GatsbyBackgroundImage
      images={[backgroundImg.childImageSharp.gatsbyImageData]}
    />
  );
};

export const BACKGROUND_SCRIPTS_QUERY = graphql`
  query ScriptsBackgroundImage {
    backgroundImg: file(
      relativePath: { eq: "bunionplasty-scripts-pattern-v2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`;
