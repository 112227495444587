import {
  PortableText,
  PortableTextProps,
  PortableTextReactComponents,
} from '@portabletext/react';
import { graphql } from 'gatsby';
import { ReactNode } from 'react';

import { Container, Heading, Paragraph, Section, Text } from 'voom-gatsby';

const marks = {
  em: ({ children }: { children: ReactNode }) => (
    <Text variant="legalEm" as="em">
      {children}
    </Text>
  ),
  sup: ({ children }: { children: ReactNode }) => (
    <Text as="sup" variant="sup">
      {children}
    </Text>
  ),
  strong: ({ children }: { children: ReactNode }) => (
    <Text as="strong" sx={{ fontWeight: 'semibold' }}>
      {children}
    </Text>
  ),
};

const legalComponents: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => (
      <Paragraph variant="contentCaptionMd" sx={{ my: 2 }}>
        {children}
      </Paragraph>
    ),
    // can add more headings if required
    h2: ({ children }) => (
      <Heading variant="legalH2" sx={{ mt: 4 }}>
        {children}
      </Heading>
    ),
    h3: ({ children }) => (
      <Heading variant="legalH3" sx={{ mt: 4 }}>
        {children}
      </Heading>
    ),
    h4: ({ children }) => (
      <Heading variant="legalH4" sx={{ mt: 4 }}>
        {children}
      </Heading>
    ),
  },
  marks: marks,
  list: ({ children }) => (
    <Text as="ul" sx={{ listStyle: 'outside', pl: 4, my: 2 }}>
      {children}
    </Text>
  ),
  listItem: ({ children }) => <Text as="li">{children}</Text>,
};

export const LegalSection = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    _rawContent: PortableTextProps['value'];
  };
  index: number;
}) => {
  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{ bg: data.backgroundColor ?? 'white' }}
    >
      <Container variant="formContainer">
        <PortableText value={data._rawContent} components={legalComponents} />
      </Container>
    </Section>
  );
};

export const LEGAL_SECTION = graphql`
  fragment legalSectionFields on SanityLegalSection {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    _rawContent
  }
`;
