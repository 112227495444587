import { graphql } from 'gatsby';

import { Box, Container, Section, Spacer } from 'voom-gatsby';

import {
  BunionplastyBackground,
  ContentBlock,
  GatsbyBackgroundImage,
} from '~components';

import { ContentBlockProps, ImageWithAltProps } from '~types/sanity';

export const PreFooter = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    isLargeIcon?: boolean;
    withSvg?: boolean;
    content: ContentBlockProps;
    backgroundImages: {
      desktopImage: ImageWithAltProps;
      mobileImage: ImageWithAltProps;
    };
    icon: {
      svg: string;
    };
  };
  index: number;
}) => {
  const { isLargeIcon } = data;
  return (
    <Section
      sx={{
        py: [0, 0, 0],
        bg: data.backgroundColor ?? isLargeIcon ? 'beige' : 'grey200',
        overflow: 'visible',
        mt: isLargeIcon ? 6 : null,
      }}
    >
      <Box
        sx={{
          position: ['relative', null, 'absolute'],
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {data.withSvg && (
          <BunionplastyBackground
            sx={{
              left: ['-45%', null, -337],
              top: ['-25%', null, -100],
              width: ['100%', null, 890],
              svg: {
                color: 'white',
              },
            }}
          />
        )}
        <GatsbyBackgroundImage
          images={[
            data.backgroundImages.mobileImage.asset.gatsbyImageData,
            null,
            data.backgroundImages.desktopImage.asset.gatsbyImageData,
          ]}
          sx={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            position: ['relative !important', null, 'absolute !important'],
            '::after': {
              content: [null, null, "''"],
              position: 'absolute',
              inset: 0,
              opacity: 0.8,
              background: isLargeIcon
                ? 'beige'
                : 'linear-gradient(359.6deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.01) 100%, rgba(0,0,0,0) 100%)',
            },
          }}
        />
      </Box>
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: [null, null, isLargeIcon ? 590 : 725],
          py: isLargeIcon ? 0 : 6,
          transform: (theme) =>
            isLargeIcon ? `translateY(-${theme.space?.[6]}px)` : 'none',
        }}
      >
        <Box
          dangerouslySetInnerHTML={{ __html: data.icon.svg }}
          sx={{
            position: 'relative',
            zIndex: 1,
            '&, svg': {
              width: isLargeIcon ? [130, null, 200] : [60, null, 75],
              height: isLargeIcon ? [130, null, 200] : [60, null, 75],
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              inset: 2,
              opacity: 0.9,
              transform: 'translateY(13%)',
              zIndex: -1,
              background: 'wine',
              filter: 'blur(22px)',
              borderRadius: 'circle',
            },
          }}
        />
        <Spacer />
        <ContentBlock
          content={data.content}
          titleSize="Lg"
          captionSize="Md"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: [isLargeIcon ? 630 : 500, null, null, null, 650],
            mx: 'auto',
            textAlign: 'center',
            mb: 5,
            'h2, span:not(a span), p': {
              color: ['black', null, isLargeIcon ? null : 'white'],
            },
          }}
          linkStyles={{
            flexDirection: ['column', null, 'column'],
            'a:not(:last-of-type)': {
              mr: [0, null, 0],
              mb: [4, null, 4],
            },
          }}
        />
      </Container>
    </Section>
  );
};

export const PRE_FOOTER = graphql`
  fragment preFooterFields on SanityPrefooter {
    _type
    backgroundColor
    isLargeIcon
    withSvg
    content {
      ...sanityContentBlockFields
    }
    backgroundImages {
      desktopImage {
        alt
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      mobileImage {
        alt
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
    icon {
      svg
    }
  }
`;
