import { graphql, useStaticQuery } from 'gatsby';

import {
  Box,
  Container,
  Grid,
  Paragraph,
  Section,
  Spacer,
  StyledSup,
} from 'voom-gatsby';

import { FooterFormColumn } from '~sections/Footer/FooterFormColumn';
import { FooterLegal } from '~sections/Footer/FooterLegal';
import { FooterLinksColumn } from '~sections/Footer/FooterLinksColumn';
import { FooterStatesColumn } from '~sections/Footer/FooterStatesColumn';

import { BunionplastyBackground } from '~components';

import { ReactComponent as Logo } from '~svg/logo/bunionplasty-with-caption.svg';

import { CtaProps } from '~types/sanity';

interface FooterProps {
  footerMenu: {
    items: CtaProps[];
  };
  siteSettings: {
    title: string;
    email: {
      display: string;
      link: string;
    };
    phoneNumber?: {
      display: string;
      link: string;
    };
    socials: {
      icon: {
        svg: string;
      };
      title: string;
      URL: string;
    }[];
    footerDisclaimer?: string;
  };
  legalMenu: {
    items: CtaProps[];
  };
  doctorStatesMenu: {
    nodes: {
      name: string;
      slug: {
        current: string;
      };
    }[];
  };
  allSanityDoctor: {
    nodes: {
      doctorOffices: {
        state: {
          name: string;
        };
      }[];
    }[];
  };
}

export const Footer = (): JSX.Element => {
  const data: FooterProps = useStaticQuery(FOOTER_QUERY);

  return (
    <Section
      as="footer"
      theme="base"
      sx={{
        bg: 'white',
        py: [0, 0, 0],
      }}
    >
      <BunionplastyBackground
        sx={{
          top: [-80, null, -375],
          right: [-50, null, -100],
          left: ['unset'],
          width: [400, null, 970],
          svg: {
            color: 'grey100',
            opacity: 0.3,
          },
        }}
      />
      <Spacer space={6} />
      <Container>
        <Logo sx={{ width: 265, height: 77, color: 'pink' }} />
        <Grid
          sx={{
            py: [4, null, 6],
            width: ['full', 400, null, 'full'],
            mx: [0, 'auto', null, 0],
            gridTemplateColumns: ['1fr', '1fr', null, '350px 1fr 350px'],
          }}
        >
          <Box
            sx={{
              gridColumn: '1',
            }}
          >
            <FooterLinksColumn links={data.footerMenu.items} />
          </Box>
          <Box
            sx={{
              gridColumn: ['1', '1 / -1', null, '2'],
              textAlign: 'left',
              mb: [5, null, 0],
            }}
          >
            <FooterStatesColumn
              states={data.doctorStatesMenu.nodes}
              allSanityDoctor={data.allSanityDoctor}
            />
          </Box>
          <Box>
            <FooterFormColumn
              phoneNumber={data.siteSettings.phoneNumber}
              email={data.siteSettings.email}
              socialLinks={data.siteSettings.socials}
            />
          </Box>
        </Grid>
        {data.siteSettings.footerDisclaimer && (
          <Paragraph
            variant="copyright"
            sx={{ fontStyle: 'italic', color: 'grey500', pb: [4, null, 6] }}
          >
            <StyledSup>{data.siteSettings.footerDisclaimer}</StyledSup>
          </Paragraph>
        )}
      </Container>
      <FooterLegal links={data.legalMenu.items} />
    </Section>
  );
};

export const FOOTER_QUERY = graphql`
  query Footer {
    # title = footer
    footerMenu: sanityNavigationMenu(
      _id: { eq: "59a00b13-b8b1-473e-bf6a-a151df1256d9" }
    ) {
      items {
        ... on SanityCta {
          type
          label
          route
          link
          landingPageRoute {
            slug {
              current
            }
          }
        }
      }
    }
    siteSettings: sanitySiteSettings(
      _id: { eq: "e983f7e8-8b28-486d-a3d0-8e3d066bd206" }
    ) {
      title
      email {
        display
        link
      }
      phoneNumber {
        display
        link
      }
      socials {
        icon {
          svg
        }
        title
        URL
      }
      footerDisclaimer
    }
    # Title = Legal
    legalMenu: sanityNavigationMenu(
      _id: { eq: "fc8cc4ea-2881-4e95-96b0-a04c90a1322d" }
    ) {
      items {
        ... on SanityCta {
          type
          label
          route
          link
          landingPageRoute {
            slug {
              current
            }
          }
        }
      }
    }
    doctorStatesMenu: allSanitySurgeonState(sort: { name: ASC }) {
      nodes {
        slug {
          current
        }
        name
      }
    }
    allSanityDoctor {
      nodes {
        doctorOffices {
          state {
            name
          }
        }
      }
    }
  }
`;
