import { graphql, useStaticQuery } from 'gatsby';
import { useState } from 'react';
import ReactSlickSlider, { Settings } from 'react-slick';

import {
  Container,
  Paragraph,
  Section,
  SliderNextArrow,
  SliderPrevArrow,
  Spacer,
  StyledSup,
} from 'voom-gatsby';

import { ContentBlock, RevealVideoCard } from '~components';

import { ContentBlockProps, RevealVideoCardProps } from '~types/sanity';

export const RevealVideoCallout = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    withSvg?: boolean;
    content: ContentBlockProps;
    revealVideoList: RevealVideoCardProps[];
    disclaimer?: string;
  };
  index: number;
}) => {
  const revealVideoCalloutCarouselSettings: Settings = {
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 3,
    prevArrow: <SliderPrevArrow />,
    nextArrow: <SliderNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [videoPlayingIndex, setVideoPlayingIndex] = useState<number | null>(
    null,
  );

  const { allSanityRevealVideoCard } = useStaticQuery(
    REVEAL_VIDEO_CARD_COUNT_QUERY,
  );

  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{
        bg: data.backgroundColor ?? 'white',
      }}
    >
      <Container>
        <ContentBlock
          content={data.content}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: [600, null, null, null, 800],
            mx: 'auto',
            textAlign: 'center',
            mb: 5,
          }}
          captionStyles={{ maxWidth: [450, null, null, null, 550] }}
          replaceHashWithCount={allSanityRevealVideoCard.totalCount}
        />
      </Container>
      <Container variant="noGutterContainer">
        <ReactSlickSlider
          {...revealVideoCalloutCarouselSettings}
          sx={{ '.slick-slide': { mx: 4 } }}
          afterChange={() => {
            // on slide change, pause all videos
            setVideoPlayingIndex(null);
          }}
        >
          {data.revealVideoList.map((card, index) => {
            return (
              <RevealVideoCard
                {...card}
                key={card.video.file.asset.url}
                videoPlaying={videoPlayingIndex === index}
                setVideoPlayingIndex={setVideoPlayingIndex}
                index={index}
              />
            );
          })}
        </ReactSlickSlider>
        {data.disclaimer && (
          <>
            <Spacer />
            <Paragraph
              variant="disclaimer"
              sx={{ mx: 'auto', maxWidth: 400, textAlign: 'center' }}
            >
              <StyledSup>{data.disclaimer}</StyledSup>
            </Paragraph>
          </>
        )}
      </Container>
    </Section>
  );
};

export const REVEAL_VIDEO_CARD_COUNT_QUERY = graphql`
  query RevealVideoCardCount {
    allSanityRevealVideoCard {
      totalCount
    }
  }
`;

export const REVEAL_VIDEO_CALLOUT_QUERY = graphql`
  fragment revealVideoCalloutFields on SanityRevealVideoCallout {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    content {
      ...sanityContentBlockFields
    }
    revealVideoList {
      video {
        file {
          asset {
            url
          }
        }
        type
      }
      image {
        alt
        asset {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
      duration {
        value
      }
    }
    disclaimer
  }
`;
