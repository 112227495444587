import { useBreakpointIndex } from '@theme-ui/match-media';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';
import ReactSlickSlider, { Settings } from 'react-slick';

import {
  Box,
  BreadCrumbs,
  Button,
  CertifiedBadge,
  Container,
  Flex,
  Grid,
  Heading,
  Link,
  Modal,
  Paragraph,
  Section,
  SliderNextArrow,
  SliderPrevArrow,
  Spacer,
  Text,
  useElementOutOfView,
} from 'voom-gatsby';

import {
  BunionplastyBackground,
  ConsultationTag,
  DoctorStickyBar,
  PhoneLink,
} from '~components';

import { ReactComponent as CalendarIcon } from '~svg/icons/icon-calendar.svg';
import { ReactComponent as GalleryIcon } from '~svg/icons/icon-gallery.svg';
import { ReactComponent as GeomarkerIcon } from '~svg/icons/icon-geomarker.svg';
import { ReactComponent as PhoneIcon } from '~svg/icons/icon-phone.svg';

import { SanityDoctor } from '~types/sanity';

import { DoctorModalForm } from './DoctorModalForm';

const doctorHeroCarouselSettings: Settings = {
  dots: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  prevArrow: (
    <SliderPrevArrow
      sx={{
        position: ['fixed', null, null, 'absolute'],
        left: [0, null, null, -8],
      }}
    />
  ),
  nextArrow: (
    <SliderNextArrow
      sx={{
        position: ['fixed', null, null, 'absolute'],
        right: [0, null, null, -8],
      }}
    />
  ),
  responsive: undefined,
};
interface DoctorHeroProps {
  doctor: SanityDoctor;
  showFormOnLoad: boolean;
}

export const DoctorHero = ({ doctor, showFormOnLoad }: DoctorHeroProps) => {
  const mainOffice = doctor.doctorOffices[0];
  const { ref, isOutOfView } = useElementOutOfView();
  const breakpointIndex = useBreakpointIndex();

  const buttonText =
    breakpointIndex >= 2 ? 'Email This Doctor' : 'Email Doctor';
  const additionalOfficeCount = doctor.doctorOffices.length - 1;

  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const openGalleryModal = () => setShowGalleryModal(true);
  const closeGalleryModal = () => setShowGalleryModal(false);

  const [showFormModal, setShowFormModal] = useState(false);
  const openFormModal = () => setShowFormModal(true);
  const closeFormModal = () => setShowFormModal(false);

  useEffect(() => {
    if (showFormOnLoad === true) {
      openFormModal();
    }
  }, [showFormOnLoad]);

  const [breadcrumbUrl, setBreadcrumbUrl] = useState<string>(
    '/find-a-doctor/doctors',
  );
  const isBrowser = typeof window !== 'undefined';
  useEffect(() => {
    if (isBrowser) {
      const url = window.localStorage.getItem('doctorSearchResultUrl');
      if (url) {
        setBreadcrumbUrl(url);
      }
    }
  }, [isBrowser]);
  return (
    <>
      <Section
        sx={{
          py: [5, null, 8, null, 9],
          bg: 'beige',
          overflow: 'hidden',
        }}
        ref={ref}
      >
        <Container sx={{ position: 'relative', zIndex: 1 }}>
          <BunionplastyBackground
            sx={{
              left: [-250, null, -425],
              top: [-350, null, -400],
              width: [593, null, 1050],
              height: [560, null, 987],
              zIndex: -1,
            }}
          />
          <Flex sx={{ flexDirection: ['column', null, 'row'] }}>
            <Box
              sx={{
                flex: ['0 0 100%', null, '0 0 50%'],
                position: 'relative',
              }}
            >
              <GatsbyImage
                image={doctor.gallery[0].asset.gatsbyImageData}
                alt={doctor.gallery[0].alt}
                sx={{
                  borderRadius: 'corner',
                  height: 'full',
                  maxHeight: 700,
                  // aspectRatio: ['315 / 225', null, '800 / 595'],
                  img: {
                    borderRadius: 'corner',
                    // aspectRatio: ['315 / 225', null, '800 / 595'],
                    objectPosition: 'top',
                  },
                }}
                loading="eager"
              />
              <CertifiedBadge sx={{ top: 'unset', bottom: 0 }} />
              {doctor.gallery.length > 1 && (
                <>
                  <Button
                    variant="iconGhost"
                    aria-label="View Gallery"
                    onClick={openGalleryModal}
                    sx={{
                      position: 'absolute',
                      top: [3, 4],
                      left: [3, 4],
                      width: 50,
                      height: 50,
                      p: 0,
                      svg: {
                        width: 50,
                        height: 50,
                      },
                    }}
                  >
                    <GalleryIcon sx={{ color: 'primary' }} />
                  </Button>
                  <Modal
                    close={closeGalleryModal}
                    open={openGalleryModal}
                    isOpen={showGalleryModal}
                    sx={{
                      '&[data-reach-dialog-content]': {
                        bg: 'transparent',
                        overflow: 'visible',
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        position: 'absolute',
                        inset: 0,
                        placeContent: 'center',
                      }}
                    >
                      <ReactSlickSlider
                        {...doctorHeroCarouselSettings}
                        sx={{ width: '90vw', maxHeight: '80vh' }}
                      >
                        {doctor.gallery.map((image, index) => {
                          return (
                            <Box
                              key={image.alt + index}
                              sx={{
                                height: '80vh',
                                width: '90vw',
                              }}
                            >
                              <GatsbyImage
                                key={image.alt + index}
                                image={image.asset.gatsbyImageData}
                                alt={image.alt}
                                imgStyle={{ objectFit: 'contain' }}
                                sx={{
                                  height: 'full',
                                  width: 'full',
                                  borderRadius: 'corner',
                                  img: {
                                    borderRadius: 'corner',
                                  },
                                }}
                              />
                            </Box>
                          );
                        })}
                      </ReactSlickSlider>
                    </Grid>
                  </Modal>
                </>
              )}
            </Box>
            <Flex
              sx={{
                flex: ['0 0 100%', null, '0 0 44%'],
                px: [0, null, 6],
                py: [3, null, 6],
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <BreadCrumbs
                links={[
                  { url: breadcrumbUrl, label: 'All Doctors' },
                  {
                    url: `/find-a-doctor/doctors/${doctor.slug.current}`,
                    label: doctor.name,
                  },
                ]}
                sx={{ mb: 0 }}
              />
              <Heading variant="contentTitle" as="h1">
                <Text sx={{ fontWeight: 'medium', mr: 2 }}>{doctor.name}</Text>
                <Text>{doctor.credentials}</Text>
              </Heading>
              <Spacer space={1} />
              <Paragraph variant="mediumP" sx={{ mb: 0 }}>
                <GeomarkerIcon
                  sx={{
                    mr: 2,
                    width: [16, null, null, 20],
                    height: [16, null, null, 20],
                  }}
                />
                <Text>{`${mainOffice.city.name}, ${mainOffice.state.abbreviation}`}</Text>
                {additionalOfficeCount > 0 && (
                  <>
                    <Text
                      sx={{
                        mx: 2,
                        borderRight: (theme) =>
                          `1px solid ${theme.colors?.grey300}`,
                      }}
                    />
                    <Text>{`${additionalOfficeCount} Additional Location${
                      additionalOfficeCount > 1 ? 's' : ''
                    }`}</Text>
                  </>
                )}
              </Paragraph>
              <Spacer space={1} />
              <PhoneLink office={mainOffice} />
              <Spacer space={2} />
              <Flex>
                {doctor.consultations.map((consultation) => (
                  <ConsultationTag
                    key={consultation}
                    consultation={consultation}
                    sx={{ mr: 2 }}
                  />
                ))}
              </Flex>
              <Spacer />
              <Flex sx={{ mt: 'auto' }}>
                <Button
                  onClick={openFormModal}
                  sx={{
                    display: 'flex',
                    py: '10px',
                    px: 4,
                    alignItems: 'center',
                    mr: 2,
                  }}
                >
                  <Text
                    sx={{
                      fontWeight: 'semibold',
                      mr: 2,
                      fontSize: ['xs', null, null, 'sm'],
                    }}
                  >
                    {buttonText}
                  </Text>
                  <CalendarIcon
                    sx={{
                      color: 'white',
                      width: [14, null, null, 17],
                      height: [14, null, null, 17],
                    }}
                  />
                </Button>
                <PhoneLink office={mainOffice} variant="button" />
              </Flex>
              <DoctorModalForm
                close={closeFormModal}
                open={openFormModal}
                isOpen={showFormModal}
                doctorName={doctor.name}
              />
            </Flex>
          </Flex>
        </Container>
      </Section>
      <DoctorStickyBar
        doctor={doctor}
        active={isOutOfView}
        openFormModal={openFormModal}
      />
    </>
  );
};
