import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import { ReactNode } from 'react';

import {
  Box,
  Flex,
  Heading,
  Link,
  Paragraph,
  StyledSup,
  Text,
  ThemeUIStyleObject,
} from 'voom-gatsby';

import { getHref } from '~utils/helpers';

import { ContentBlockProps as ContentBlockInterface } from '~types/sanity';

export interface ContentBlockProps {
  content: ContentBlockInterface;
  sx?: ThemeUIStyleObject;
  className?: string;
  titleSize?: 'Md' | 'Lg' | 'Xl';
  captionSize?: 'Sm' | 'Md' | 'Lg';
  eyebrowStyles?: ThemeUIStyleObject;
  titleStyles?: ThemeUIStyleObject;
  captionStyles?: ThemeUIStyleObject;
  linkStyles?: ThemeUIStyleObject;
  replaceHashWithCount?: number;
  headingAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  ctaOnClick?: () => void;
}

export const marks = {
  em: ({ children }: { children: ReactNode }) => (
    <Text variant="contentTitleEm" as="em">
      {children}
    </Text>
  ),
  sup: ({ children }: { children: ReactNode }) => (
    <Text as="sup" variant="sup">
      {children}
    </Text>
  ),
  strong: ({ children }: { children: ReactNode }) => (
    <Text as="strong" sx={{ fontWeight: 'semibold' }}>
      {children}
    </Text>
  ),
};

export const titleComponentsMd: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => <Text variant="contentTitle">{children}</Text>,
  },
  marks: marks,
  listItem: ({ children }) => (
    <Text as="li" variant="contentCaptionLi">
      {children}
    </Text>
  ),
  list: ({ children }) => (
    <Text as="ul" variant="contentCaptionUl">
      {children}
    </Text>
  ),
};

const titleComponentsLg: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => <Text variant="contentTitleLg">{children}</Text>,
  },
  marks: marks,
  listItem: ({ children }) => (
    <Text as="li" variant="contentCaptionLiLg">
      {children}
    </Text>
  ),
  list: ({ children }) => (
    <Text as="ul" variant="contentCaptionUl">
      {children}
    </Text>
  ),
};

const titleComponentsXl: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => <Text variant="contentTitleXl">{children}</Text>,
  },
  marks: marks,
  listItem: ({ children }) => (
    <Text as="li" variant="contentCaptionLiLg">
      {children}
    </Text>
  ),
  list: ({ children }) => (
    <Text as="ul" variant="contentCaptionUl">
      {children}
    </Text>
  ),
};

export const captionComponentsSm: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => (
      <Paragraph variant="contentCaptionSm">{children}</Paragraph>
    ),
  },
  marks: marks,
  list: ({ children }) => (
    <Text as="ul" variant="contentCaptionUl">
      {children}
    </Text>
  ),
  listItem: ({ children }) => (
    <Text as="li" variant="contentCaptionLi">
      {children}
    </Text>
  ),
};

const captionComponentsMd: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => (
      <Paragraph variant="contentCaptionMd">{children}</Paragraph>
    ),
  },
  marks: marks,
  list: ({ children }) => (
    <Text as="ul" variant="contentCaptionUl">
      {children}
    </Text>
  ),
  listItem: ({ children }) => (
    <Text as="li" variant="contentCaptionLi">
      {children}
    </Text>
  ),
};

const captionComponentsLg: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => (
      <Paragraph variant="contentCaptionLg">{children}</Paragraph>
    ),
  },
  marks: marks,
  list: ({ children }) => (
    <Text as="ul" variant="contentCaptionUl">
      {children}
    </Text>
  ),
  listItem: ({ children }) => (
    <Text as="li" variant="contentCaptionLi">
      {children}
    </Text>
  ),
};

export const ContentBlock = ({
  content,
  sx,
  className,
  eyebrowStyles,
  titleStyles,
  titleSize,
  captionStyles,
  captionSize,
  linkStyles,
  replaceHashWithCount,
  headingAs = 'h2',
  ctaOnClick,
}: ContentBlockProps) => {
  const getTitleComponents = (size?: ContentBlockProps['titleSize']) => {
    if (size === 'Xl') {
      return titleComponentsXl;
    }
    if (size === 'Lg') {
      return titleComponentsLg;
    }
    if (size === 'Md') {
      return titleComponentsMd;
    }
    return titleComponentsMd;
  };

  const getCaptionComponents = (size?: ContentBlockProps['captionSize']) => {
    if (size === 'Lg') {
      return captionComponentsLg;
    }
    if (size === 'Md') {
      return captionComponentsMd;
    }
    if (size === 'Sm') {
      return captionComponentsSm;
    }
    return captionComponentsSm;
  };

  return (
    <Box sx={{ ...sx }} className={className}>
      {content.eyebrow && (
        <Paragraph
          variant="eyeBrow"
          sx={{
            ...eyebrowStyles,
          }}
        >
          <StyledSup>{content.eyebrow}</StyledSup>
        </Paragraph>
      )}
      {content._rawTitle && (
        <Heading as={headingAs} sx={{ mb: 3, ...titleStyles }}>
          <PortableText
            value={content._rawTitle}
            components={getTitleComponents(titleSize)}
          />
        </Heading>
      )}
      {content._rawCaption && (
        <Text
          as="div"
          sx={{
            ...(!content.ctas || content.ctas.length === 0 ? { mb: 4 } : {}),
            ...captionStyles,
          }}
        >
          <PortableText
            value={content._rawCaption}
            components={getCaptionComponents(captionSize)}
          />
        </Text>
      )}
      {content.ctas && content.ctas.length > 0 && (
        <Flex
          sx={{
            alignItems: 'center',
            flexDirection: ['column', null, 'row'],
            my: 4,
            ...linkStyles,
          }}
        >
          {content.ctas.map((cta) => {
            const label = replaceHashWithCount
              ? cta.label.replace('#', String(replaceHashWithCount))
              : cta.label;
            return (
              <Link
                key={cta.label}
                sx={{
                  ':not(:last-of-type)': {
                    mr: [0, null, 4],
                    mb: [3, null, 0],
                  },
                  width:
                    cta.type === 'button' ? ['full', null, 'auto'] : 'auto',
                }}
                variant={cta.type}
                href={getHref(cta)}
                onClick={ctaOnClick}
              >
                {label}
              </Link>
            );
          })}
        </Flex>
      )}
    </Box>
  );
};
