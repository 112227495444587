import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Box, Flex, Spacer, Text } from 'voom-gatsby';

import { BeforeAfterCardProps } from '~types/sanity';

export const BeforeAfterCard = ({ image, severity }: BeforeAfterCardProps) => {
  const severityCount: Record<string, number> = {
    Mild: 1,
    Large: 2,
    Severe: 3,
  };

  const count = severityCount[severity.value];

  return (
    <Box>
      <motion.div
        whileHover={{ y: -5, boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.15)' }}
        sx={{
          borderRadius: 'rounded',
          boxShadow: 'cardShadow',
        }}
      >
        <GatsbyImage
          image={image.asset.gatsbyImageData}
          alt={image.alt}
          sx={{
            img: { borderRadius: 'rounded' },
          }}
        />
      </motion.div>
      <Flex sx={{ alignItems: 'center', mt: 3 }}>
        {[...new Array(count)].map((_, index) => {
          return (
            <Box
              key={index}
              sx={{
                bg: 'pink',
                borderRadius: 'circle',
                width: 10,
                height: 10,
                mr: 1,
              }}
            />
          );
        })}
        <Spacer space={2} direction="horizontal" />
        <Text variant="severityText">{severity.value}</Text>
      </Flex>
    </Box>
  );
};
