import { PortableTextProps } from '@portabletext/react';
import { PageProps, graphql } from 'gatsby';
import { useMemo } from 'react';

import { Container, SEO } from 'voom-gatsby';

import { DoctorBeforeAfter } from '~sections/DoctorBeforeAfter';
import { DoctorCallout } from '~sections/DoctorCallout';
import { DoctorDetails } from '~sections/DoctorDetails';
import { DoctorHero } from '~sections/DoctorHero';
import { DoctorOffices } from '~sections/DoctorOffices';
import { DoctorQuote } from '~sections/DoctorQuote';
import { FaqSection } from '~sections/FaqSection';

import { Disclaimer } from '~components';

import {
  ImageWithAltProps,
  SanityDoctor,
  SanityFaqSection,
} from '~types/sanity';

interface DoctorTemplateProps {
  data: {
    sanityDoctor: SanityDoctor;
    sanityDoctorSettings: {
      doctorPageCallout?: string;
      _rawDoctorDetailDisclaimer?: PortableTextProps['value'];
    };
    allSanityBeforeAfterCard: {
      nodes: {
        image: ImageWithAltProps;
      }[];
    };
    sanityFaq: SanityFaqSection;
  };
  location: PageProps['location'];
}

const DoctorTemplate = ({ data, location }: DoctorTemplateProps) => {
  const doctor = data.sanityDoctor;
  const { doctorPageCallout } = data.sanityDoctorSettings;

  const faqData = useMemo(() => {
    const primaryOffice = doctor.doctorOffices[0];
    if (doctor.doctorFaq) {
      return doctor.doctorFaq;
    } else if (primaryOffice.city.cityFaq) {
      return primaryOffice.city.cityFaq;
    } else if (primaryOffice.state.stateFaq) {
      return primaryOffice.state.stateFaq;
    } else {
      return data.sanityFaq;
    }
  }, [doctor.doctorFaq, doctor.doctorOffices, data.sanityFaq]);

  return (
    <>
      <DoctorHero
        doctor={doctor}
        showFormOnLoad={location.search === '?contact=true'}
      />
      {doctor.quote && (
        <DoctorQuote
          quote={doctor.quote}
          name={doctor.name}
          hideQuoteAttribution={doctor.hideQuoteAttribution}
        />
      )}
      {doctorPageCallout && <DoctorCallout doctorCallout={doctorPageCallout} />}
      <DoctorOffices doctor={doctor} />
      {data.allSanityBeforeAfterCard.nodes.length >= 5 && (
        <DoctorBeforeAfter
          gallery={data.allSanityBeforeAfterCard.nodes}
          doctorSlug={doctor.slug.current}
          doctorName={doctor.name}
        />
      )}
      <DoctorDetails doctor={doctor} />
      {data.sanityDoctorSettings._rawDoctorDetailDisclaimer && (
        <Container
          variant="doctorContainer"
          sx={{ position: 'relative', zIndex: 1 }}
        >
          <Disclaimer
            value={data.sanityDoctorSettings._rawDoctorDetailDisclaimer}
            sx={{ mt: 0 }}
          />
        </Container>
      )}
      {faqData && <FaqSection data={{ faqReference: faqData }} />}
    </>
  );
};

export const Head = ({ data }: DoctorTemplateProps) => {
  return (
    <SEO
      title={data.sanityDoctor.SEO?.title ?? data.sanityDoctor.name}
      description={
        data.sanityDoctor.SEO?.description ??
        `Dr. ${data.sanityDoctor.name} is specially trained to help you get back on your feet fast with Bunionplasty® 360 Repair™ bunion surgery, so schedule your consult today!`
      }
      useSiteTitle={typeof data.sanityDoctor.SEO?.title === 'string'}
    />
  );
};

export const SANITY_DOCTOR_QUERY = graphql`
  query SanityDoctorTemplate($sanityDoctorId: String) {
    sanityDoctor(id: { eq: $sanityDoctorId }) {
      ...sanityDoctorFields
    }
    allSanityBeforeAfterCard(
      filter: { doctor: { id: { eq: $sanityDoctorId } } }
    ) {
      nodes {
        image {
          alt
          asset {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
    sanityDoctorSettings(id: { eq: "-8b30ce4e-87a0-5afb-bf8c-c8982d88641d" }) {
      doctorPageCallout
      _rawDoctorDetailDisclaimer
    }
    sanityFaq(id: { eq: "-0e369bd1-401b-5df7-ac70-6192b1eb2167" }) {
      ...sanityFaqFields
    }
  }
`;

export default DoctorTemplate;
