import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Box,
  Container,
  Grid,
  Heading,
  Link,
  Paragraph,
  Section,
  Spacer,
  Text,
} from 'voom-gatsby';

import { BunionplastyBackground } from '~components';

export const BunionplastyQuizThankYou = () => {
  const { illustration } = useStaticQuery(HERO_ILLUSTRATION_QUERY);
  const heroImage = getImage(illustration);

  return (
    <Section
      sx={{
        bg: 'beige',
        pt: [0, 0, 0, 0],
        pb: [0, 0, 0, 0],
      }}
    >
      <BunionplastyBackground
        sx={{
          left: [0, null, -400],
          bottom: [0, null, 500],
          width: [0, null, 1400],
          zIndex: [0, null, -1],
        }}
      />
      <Grid
        columns={[1, null, null, 2]}
        sx={{
          gridAutoFlow: 'dense',
        }}
      >
        {heroImage && (
          <GatsbyImage
            image={heroImage}
            alt="an illusration of a person walking"
            loading="eager"
            sx={{
              gridColumnStart: [1, null, null, 2],
              aspectRatio: ['1 / 1', null, '5 / 3', '720 / 800'],
              img: {
                objectPosition: 'left bottom',
              },
            }}
          />
        )}
        <Container sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              maxWidth: [510, null, null, null, 750],
              mx: 'auto',
              py: 4,
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: 'full',
            }}
          >
            <Paragraph variant="eyeBrow" sx={{ textAlign: 'center' }}>
              The Bunion Quiz
            </Paragraph>
            <Spacer />
            <Heading as="h1" sx={{ mb: 3 }}>
              <Text variant="contentTitleLg">
                <Text as="span" variant="contentTitleEm">
                  Great news!
                </Text>
              </Text>
            </Heading>
            <Text as="div">
              <Paragraph variant="contentCaptionMd">
                You could be a candidate for the Bunionplasty® 360 Bunion
                Repair™ procedure with same-day walking recovery. Of course,
                medical decisions are more complicated than quizzes, so use our
                Find a Doctor tool and get started today!
              </Paragraph>
            </Text>
            <Link
              variant="button"
              sx={{ whiteSpace: 'nowrap', mt: 4, mb: 2 }}
              href={'/doctors'}
            >
              Find a Doctor
            </Link>
            <Spacer />
          </Box>
        </Container>
      </Grid>
    </Section>
  );
};

export const HERO_ILLUSTRATION_QUERY = graphql`
  query BunionplastyQuizHeroIllustration {
    illustration: file(relativePath: { eq: "bp-quiz-hero-illustration.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`;

export const BUNIONPLASTY_QUIZ_THANK_YOU_QUERY = graphql`
  fragment bunionplastyQuizThankYouFields on SanityBunionplastyQuizThankYou {
    _type
  }
`;
