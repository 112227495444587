import { graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import {
  Honeypot,
  NetlifyFormComponent,
  NetlifyFormProvider,
  useNetlifyForm,
} from 'react-netlify-forms';

import {
  Box,
  Button,
  Container,
  Flex,
  FormInput,
  Grid,
  Paragraph,
  Section,
  useDefaultFormFields,
} from 'voom-gatsby';

import { BunionplastyBackground, ContentBlock } from '~components';

import { ContentBlockProps } from '~types/sanity';

export const Form = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    content: ContentBlockProps;
    type: 'BECOME_A_SURGEON' | 'SCHEDULE_A_CONSULTATION';
    successContent: ContentBlockProps;
  };
  index: number;
}) => {
  const isBecomeASurgeonForm = data.type === 'BECOME_A_SURGEON';

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const netlify = useNetlifyForm({
    name: isBecomeASurgeonForm ? 'Become a surgeon form' : 'Consult form',
    action: '/thanks',
    honeypotName: 'bot-field',
    onSuccess: () => {
      if (window.gtag) {
        window.gtag(
          'event',
          isBecomeASurgeonForm
            ? 'Form Submission - Become a Surgeon'
            : 'Form Submission - Consult form',
          {},
        );
      }
      reset();
    },
  });

  const DefaultFormFields = useDefaultFormFields(register, reset);

  const onSubmit = (data: unknown) => netlify.handleSubmit(null, data);

  const BecomeASurgeonInputs = () => {
    return (
      <Grid columns={[1, null, 2]} sx={{ rowGap: 0 }}>
        <FormInput
          label="First Name"
          name="firstName"
          register={register('firstName', { required: true })}
          errors={errors.firstName}
          control={control}
        />
        <FormInput
          label="Last Name"
          name="lastName"
          register={register('lastName', { required: true })}
          errors={errors.lastName}
          control={control}
        />
        <FormInput
          label="State"
          name="state"
          register={register('state', { required: true })}
          errors={errors.state}
          control={control}
        />
        <FormInput
          label="Practice Name"
          name="practiceName"
          register={register('practiceName', { required: true })}
          errors={errors.practiceName}
          control={control}
        />
        <FormInput
          label="Phone Number"
          name="phoneNumber"
          type="tel"
          register={register('phoneNumber', { required: true })}
          errors={errors.phoneNumber}
          control={control}
        />
        <FormInput
          label="Email"
          name="email"
          type="email"
          register={register('email', { required: true })}
          errors={errors.email}
          control={control}
        />
        <DefaultFormFields />
      </Grid>
    );
  };

  const ConsultationInputs = () => {
    return (
      <Flex
        sx={{
          flexDirection: ['column', null, 'row'],
          justifyContent: 'space-between',
          '> div': { flex: ['1 1 auto', null, '0 0 calc(50% - 10px)'] },
        }}
      >
        <Box>
          <Flex
            sx={{
              flexDirection: ['column', null, 'row'],
              justifyContent: 'space-between',
              '> div': { flex: ['1 1 auto', null, '0 0 calc(50% - 10px)'] },
            }}
          >
            <FormInput
              label="First Name"
              name="firstName"
              register={register('firstName', { required: true })}
              errors={errors.firstName}
              control={control}
            />
            <FormInput
              label="Last Name"
              name="lastName"
              register={register('lastName', { required: true })}
              errors={errors.lastName}
              control={control}
            />
          </Flex>
          <FormInput
            label="Email"
            name="email"
            type="email"
            register={register('email', { required: true })}
            errors={errors.email}
            control={control}
          />
          <FormInput
            label="Phone Number"
            name="phoneNumber"
            type="tel"
            register={register('phoneNumber', { required: true })}
            errors={errors.phoneNumber}
            control={control}
          />
          <FormInput
            label="Zip"
            name="zip"
            type="text"
            register={register('zip', { required: true })}
            errors={errors.zip}
            control={control}
          />
        </Box>
        <Box>
          <FormInput
            label="Message"
            name="message"
            type="string"
            register={register('message', { required: true })}
            errors={errors.message}
            control={control}
            Textarea={true}
            sx={{ '&, label': { height: 'full' } }}
          />
          <DefaultFormFields />
        </Box>
      </Flex>
    );
  };

  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      id="form-section"
      sx={{
        py: [5, null, 9],
        bg: isBecomeASurgeonForm ? 'grey300' : 'beige',
      }}
    >
      {isBecomeASurgeonForm && (
        <BunionplastyBackground
          sx={{
            top: 100,
            left: 'auto',
            right: ['-60vw', null, '-40vw', null, '-20vw'],
            width: [500, null, 1145, null, 1600],
            zIndex: -1,
            svg: {
              color: 'white',
              opacity: 0.3,
            },
          }}
        />
      )}
      <Container
        variant="formContainer"
        sx={{ zIndex: 1, position: 'relative' }}
      >
        {netlify.success ? (
          <ContentBlock
            content={data.successContent}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              maxWidth: 620,
              mx: 'auto',
            }}
            eyebrowStyles={{
              color: isBecomeASurgeonForm ? 'black' : 'pink',
            }}
          />
        ) : (
          <>
            <ContentBlock
              content={data.content}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                maxWidth: 620,
                mx: 'auto',
              }}
              eyebrowStyles={{
                color: isBecomeASurgeonForm ? 'black' : 'pink',
              }}
            />
            <NetlifyFormProvider {...netlify}>
              <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
                <Honeypot />
                {isBecomeASurgeonForm ? (
                  <BecomeASurgeonInputs />
                ) : (
                  <ConsultationInputs />
                )}
                {netlify.error && (
                  <Paragraph sx={{ py: 4, textAlign: 'center' }}>
                    Sorry, we could not reach servers. Please try again later.
                    If this problem continues, please contact us. Thanks!
                  </Paragraph>
                )}
                <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <Button
                    type="submit"
                    sx={{
                      variant: 'buttons.primary',
                      appearance: 'none',
                      mt: 4,
                      mb: 2,
                      width: 250,
                    }}
                  >
                    Submit
                  </Button>
                  <Paragraph variant="copyright">All fields required</Paragraph>
                </Flex>
              </NetlifyFormComponent>
            </NetlifyFormProvider>
          </>
        )}
      </Container>
    </Section>
  );
};

export const FORM_SECTION_QUERY = graphql`
  fragment formFields on SanityForm {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    content {
      ...sanityContentBlockFields
    }
    type
    successContent {
      ...sanityContentBlockFields
    }
  }
`;
