import { graphql } from 'gatsby';

import { Container, Flex, Section } from 'voom-gatsby';

import {
  ContentBlock,
  GatsbyBackgroundImage,
  GeolocationForm,
} from '~components';

import { ContentBlockProps, ImageWithAltProps } from '~types/sanity';

export const HomeHero = ({
  data,
  index,
}: {
  data: {
    backgroundColor?: string;
    content: ContentBlockProps;
    backgroundImage: {
      desktopImage: ImageWithAltProps;
      mobileImage: ImageWithAltProps;
    };
    showDoctorSearchInput?: boolean;
  };
  index: number;
}) => {
  return (
    <Section
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: ['end', null, 'center'],
        minHeight: 800,
        mx: [0, null, 4],
        py: [0, 0, 0],
        bg: data.backgroundColor,
      }}
    >
      <GatsbyBackgroundImage
        sx={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          position: ['relative !important', null, 'absolute !important'],
          borderRadius: [null, null, 'rounded'],
          img: { objectPosition: 'top', borderRadius: [null, null, 'rounded'] },
        }}
        loading="eager"
        images={[
          data.backgroundImage.mobileImage.asset.gatsbyImageData,
          null,
          data.backgroundImage.desktopImage.asset.gatsbyImageData,
        ]}
      />
      <Container
        variant="homeHeroContainer"
        sx={{
          display: 'flex',
          mt: [-150, null, 0],
          position: 'relative',
          '&::before': {
            display: ['block', null, 'none'],
            content: '""',
            position: 'absolute',
            top: -150,
            left: 0,
            zIndex: 'background',
            width: 'full',
            height: 300,
            backgroundImage: (theme) =>
              `${theme?.colors?.homeHeroMobileGradient}`,
          },
        }}
      >
        <Flex
          sx={{
            maxWidth: [639, null, null, null, 750],
            justifyContent: 'center',
            height: 'full',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <ContentBlock
            content={data.content}
            titleSize="Xl"
            captionSize="Lg"
            eyebrowStyles={{ fontSize: 'sm' }}
            headingAs={index === 0 ? 'h1' : undefined}
          />
          {data.showDoctorSearchInput && (
            <GeolocationForm
              placeholder="Find a Doctor by City, State, or Zip"
              sx={{ width: ['full', 400], mb: 2 }}
            />
          )}
        </Flex>
      </Container>
    </Section>
  );
};

export const HOME_HERO_QUERY = graphql`
  fragment homeHeroFields on SanityHomeHero {
    _type
    backgroundColor
    backgroundImage {
      desktopImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
        alt
      }
      mobileImage {
        alt
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
    content {
      ...sanityContentBlockFields
    }
    showDoctorSearchInput
  }
`;
