import { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Box, Button } from 'voom-gatsby';

import { ReactComponent as ReplayIcon } from '~svg/icons/icon-replay.svg';

export const AmbientVideo = ({
  url,
  type,
}: {
  url?: string;
  type?: string;
}) => {
  const [inViewRef, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

  // this is a bit confusing. we have a videoRef and then attach inViewRef to it,
  // and pass the mergedRef to add to the video element
  // dumb inViewRef causes a render loop if you dont do this
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const mergedRef = useCallback(
    (node: HTMLVideoElement) => {
      videoRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  useEffect(() => {
    if (inView) {
      videoRef.current?.play();
    }
  }, [inView]);

  const [showReplayButton, setShowReplayButton] = useState(false);

  return url ? (
    <Box sx={{ position: 'relative' }}>
      <video
        ref={mergedRef}
        muted
        playsInline
        onEnded={() => setShowReplayButton(true)}
        sx={{
          width: 'full',
          height: 'full',
        }}
      >
        {inView && <source src={url} type={type} />}
        Your browser does not support the video tag.
      </video>
      {showReplayButton && (
        <Button
          onClick={() => {
            videoRef.current?.play();
            setShowReplayButton(false);
          }}
          aria-label="Play Video"
          variant="ghost"
          sx={{
            position: 'absolute',

            zIndex: 1,
            boxShadow: 'iconShadow',
            borderRadius: 'circle',
            bg: 'white',
            width: 60,
            height: 60,
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            top: 4,
            right: 4,
          }}
        >
          <ReplayIcon sx={{ width: 22, height: 22 }} />
        </Button>
      )}
    </Box>
  ) : null;
};
