import { graphql } from 'gatsby';

import { Container, Link, Section, Spacer } from 'voom-gatsby';

import {
  BunionplastyBackground,
  ContentBlock,
  GatsbyBackgroundImage,
  GeolocationForm,
} from '~components';

import { ContentBlockProps, ImageWithAltProps } from '~types/sanity';

export const SurgeonSearchHero = ({
  data,
  index,
}: {
  data: {
    backgroundColor?: string;
    content: ContentBlockProps;
    backgroundImages: {
      desktopImage: ImageWithAltProps;
      mobileImage: ImageWithAltProps;
    };
    withSvg?: boolean;
  };
  index: number;
}) => {
  const mobileOffset = -150;

  return (
    <Section
      sx={{
        py: [0, 0, 0],
        bg: ['wine', null, 'white'],
        mb: [mobileOffset, null, 0],
        '::after': {
          content: '""',
          position: 'absolute',
          bottom: -mobileOffset,
          height: [600, 700, 0],
          width: 'full',
          background: (theme) => theme.colors?.doctorSearchHeroGradient,
        },
      }}
    >
      {data.withSvg && (
        <BunionplastyBackground
          sx={{
            top: [-200, null, -150],
            left: [-182, null, -380],
            width: [460, null, 800],
            mixBlendMode: 'soft-light',
            opacity: 0.6,
            svg: {
              color: 'white',
            },
          }}
        />
      )}
      <GatsbyBackgroundImage
        images={[
          data.backgroundImages.mobileImage.asset.gatsbyImageData,
          null,
          data.backgroundImages.desktopImage.asset.gatsbyImageData,
        ]}
        sx={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          position: ['relative !important', null, 'absolute !important'],
        }}
        loading="eager"
      />
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: [null, null, 640],
          pb: [7, null, 0],
          transform: [`translateY(${mobileOffset}px)`, null, 'none'],
          zIndex: 1,
        }}
      >
        <ContentBlock
          content={data.content}
          titleSize="Xl"
          captionSize="Md"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: [700, null, null, null, 850],
            mx: 'auto',
            textAlign: ['left', 'center'],
            mb: [2, 3, 5],
            'h2, span:not(a span), p': {
              color: 'white',
              fontSize: ['4xl', '5xl', '6xl', null, '7xl'],
            },
          }}
          linkStyles={{
            flexDirection: ['column', null, 'column'],
            'a:not(:last-of-type)': {
              mr: [0, null, 0],
              mb: [4, null, 4],
            },
          }}
          headingAs={index === 0 ? 'h1' : undefined}
        />
        <GeolocationForm sx={{ width: ['full', 375] }} />
        <Spacer space={5} />
        <Link
          href="/find-a-doctor/doctors"
          variant="underline"
          sx={{ color: 'white' }}
        >
          View All Locations & Doctors
        </Link>
      </Container>
    </Section>
  );
};

export const SURGEON_SEARCH_HERO = graphql`
  fragment surgeonSearchHeroFields on SanitySurgeonSearchHero {
    _type
    backgroundColor
    content {
      ...sanityContentBlockFields
    }
    backgroundImages {
      desktopImage {
        alt
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      mobileImage {
        alt
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
    withSvg
  }
`;
