import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Box,
  Container,
  Flex,
  Paragraph,
  Section,
  Spacer,
  StyledSup,
} from 'voom-gatsby';

import { ContentBlock, ScriptsBackground } from '~components';

import {
  ContentBlockProps,
  IconBlockProps,
  IconCardProps,
} from '~types/sanity';

export const BeforeAfterSideBySide = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    scriptsBackground?: boolean;
    content: ContentBlockProps;
    beforeAfterList: IconCardProps[];
    iconList: IconBlockProps[];
    disclaimer?: string;
  };
  index: number;
}) => {
  const { allSanityBeforeAfterCard } = useStaticQuery(
    BEFORE_AFTER_SIDE_BY_SIDE_COUNT_QUERY,
  );
  return (
    <Section
      id="before-after-side-by-side"
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{
        bg: data.backgroundColor ?? 'beige',
        zIndex: 1,
      }}
    >
      {data.scriptsBackground && <ScriptsBackground />}
      <Container variant="narrowContainer">
        <ContentBlock
          content={data.content}
          titleSize="Lg"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // maxWidth: [500, null, null, null, 800],
            mx: 'auto',
            textAlign: 'center',
            mb: 5,
            position: 'relative',
            zIndex: 1,
          }}
          captionStyles={{
            maxWidth: [700, null, null, null, 800],
            mx: 'auto',
          }}
          linkStyles={{
            flexDirection: ['column', null, 'column'],
            'a:not(:last-of-type)': {
              mr: [0, null, 0],
              mb: [4, null, 4],
            },
          }}
          replaceHashWithCount={allSanityBeforeAfterCard.totalCount}
        />
        <Flex
          sx={{
            flexDirection: ['column', null, 'row'],
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {data.beforeAfterList.map((card) => {
            const lineStyles = {
              content: "''",
              height: 3,
              width: '100%',
              borderRadius: 'rounded',
              bg: 'primary',
              position: 'absolute',
              bottom: '110%',
            } as const;

            return (
              <Flex
                key={card.title}
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  '&:first-of-type': {
                    transform: [
                      'none',
                      null,
                      'translateX(60px)',
                      'translateX(80px)',
                    ],
                  },
                  '&:last-of-type': {
                    flexDirection: ['column-reverse', null, 'column'],
                    mt: ['-33vw', null, 0],
                    transform: [
                      'none',
                      null,
                      'translateX(-60px)',
                      'translateX(-80px)',
                    ],
                    p: {
                      transform: ['translateY(-45px)', null, 'none'],
                      '&:before': {
                        ...lineStyles,
                        bottom: ['unset', null, '110%'],
                        top: ['110%', null, 'unset'],
                      },
                    },
                  },
                }}
              >
                <Paragraph
                  variant="mediumP"
                  sx={{
                    textTransform: 'uppercase',
                    position: 'relative',
                    fontWeight: 'bold',
                    mb: 0,
                    '&:before': {
                      ...lineStyles,
                    },
                  }}
                >
                  {card.title}
                </Paragraph>
                <GatsbyImage
                  image={card.image.asset.gatsbyImageData}
                  alt={card.image.alt}
                  sx={{ mb: [3, 4] }}
                />
              </Flex>
            );
          })}
        </Flex>
        <Flex
          sx={{
            flexDirection: ['column', null, 'row'],
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 1000,
            mx: 'auto',
          }}
        >
          {data.iconList.map((block) => (
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: ['none', 290],
                mx: 'auto',
                textAlign: 'center',
                px: 3,
              }}
              key={block.caption}
            >
              <Box
                dangerouslySetInnerHTML={{ __html: block.icon.svg }}
                sx={{
                  '&, svg': {
                    width: [80, null, 85],
                    height: [80, null, 85],
                    mb: 3,
                  },
                }}
              />
              <Paragraph variant="largeP">{block.caption}</Paragraph>
            </Flex>
          ))}
        </Flex>
        {data.disclaimer && (
          <>
            <Spacer space={[6, null, 8]} />
            <Paragraph
              variant="disclaimer"
              sx={{ mx: 'auto', maxWidth: 400, textAlign: 'center' }}
            >
              <StyledSup>{data.disclaimer}</StyledSup>
            </Paragraph>
          </>
        )}
      </Container>
    </Section>
  );
};

export const BEFORE_AFTER_SIDE_BY_SIDE_COUNT_QUERY = graphql`
  query BeforeAfterSideBySideCount {
    allSanityBeforeAfterCard(filter: { featured: { eq: true } }) {
      totalCount
    }
  }
`;

export const BEFORE_AFTER_SIDE_BY_SIDE_QUERY = graphql`
  fragment beforeAfterSideBySideFields on SanityBeforeAfterSideBySide {
    _type # needed to select this component to render in template
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    scriptsBackground
    content {
      ...sanityContentBlockFields
    }
    beforeAfterList {
      image {
        alt
        asset {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
      title
    }
    iconList {
      icon {
        svg
      }
      caption
    }
    disclaimer
  }
`;
