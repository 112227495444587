import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import ReactSlickSlider, { Settings } from 'react-slick';

import {
  Container,
  Section,
  SliderNextArrow,
  SliderPrevArrow,
} from 'voom-gatsby';

import { ContentBlock, ImageCarouselCard } from '~components';

import { ContentBlockProps, IconCardProps } from '~types/sanity';

export const ImageCarousel = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    content?: ContentBlockProps;
    dotSeparators?: boolean;
    circularImages?: boolean;
    imageCards: IconCardProps[];
  };
  index: number;
}) => {
  const [lastCardRef, lastCardInView] = useInView({ threshold: 1 });

  const variableWidth = data.imageCards.length > 3;
  const imageCarouselSettings: Settings = {
    dots: false,
    slidesToScroll: 1,
    variableWidth: variableWidth,
    slidesToShow: variableWidth ? undefined : 3,
    infinite: false,
    prevArrow: (
      <SliderPrevArrow
        sx={{
          top: [113, 220],
          left: [3, 4],
          transform: 'none',
          '&.slick-disabled': {
            display: 'none',
          },
        }}
      />
    ),
    nextArrow: (
      <SliderNextArrow
        sx={{
          top: [113, 220],
          right: [3, 4],
          transform: 'none',
          display: lastCardInView ? 'none' : 'block',
          '&.slick-disabled': {
            display: 'none',
          },
        }}
      />
    ),
    responsive: variableWidth
      ? [
          {
            breakpoint: 480,
            settings: {
              centerMode: true,
            },
          },
        ]
      : [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
  };

  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{ bg: data.backgroundColor }}
    >
      <Container variant="noGutterContainer" sx={{ pl: [0, 0, 3, 6] }}>
        {data.content && (
          <ContentBlock
            content={data.content}
            sx={{
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          />
        )}
        <ReactSlickSlider
          {...imageCarouselSettings}
          sx={{ '.slick-list': { overflow: 'visible' } }}
        >
          {data.imageCards.map((card, index) => {
            const isLastCard = index === data.imageCards.length - 1;
            return (
              <ImageCarouselCard
                ref={isLastCard ? lastCardRef : undefined}
                key={
                  card.image.asset.gatsbyImageData.images.fallback?.src ?? index
                }
                card={card}
                variableWidth={variableWidth}
                dotSeparators={isLastCard ? false : data.dotSeparators}
                circularImages={data.circularImages}
              />
            );
          })}
        </ReactSlickSlider>
      </Container>
    </Section>
  );
};

export const IMAGE_CAROUSEL_QUERY = graphql`
  fragment imageCarouselFields on SanityImageCarousel {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    content {
      ...sanityContentBlockFields
    }
    dotSeparators
    circularImages
    imageCards {
      icon {
        svg
      }
      image {
        alt
        asset {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
      title
      caption
    }
  }
`;
