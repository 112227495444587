import { Box, Flex, Link } from 'voom-gatsby';

import { getHref } from '~utils/helpers';

import { CtaProps } from '~types/sanity';

interface FooterLinksColumnProps {
  links: CtaProps[];
}

export const FooterLinksColumn = ({
  links,
}: FooterLinksColumnProps): JSX.Element => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        mb: [4, null, 0],
        mx: [0, 'auto', null, 0],
      }}
    >
      {links.map((cta) => {
        const variant =
          cta.type === 'boldLink'
            ? 'footerBold'
            : cta.type === 'link'
            ? 'footer'
            : cta.type;
        return (
          <Box key={cta.label} sx={{ mb: 4 }}>
            <Link variant={variant} href={getHref(cta)}>
              {cta.label}
            </Link>
          </Box>
        );
      })}
    </Flex>
  );
};
