import { FC, memo, useEffect, useState } from 'react';
import { Parallax } from 'react-scroll-parallax';

import { Box, ThemeUIStyleObject } from 'voom-gatsby';

import { ReactComponent as BPatternSvg } from '~svg/logo/BLogo.svg';

interface BunionplastyBackgroundProps {
  speed?: number;
  noParallax?: boolean;
  sx?: ThemeUIStyleObject;
  className?: string;
}

const Pattern = () => {
  return (
    <BPatternSvg
      sx={{
        color: 'tan',
        width: 'full',
        height: 'full',
      }}
    />
  );
};

export const BunionplastyBackground: FC<BunionplastyBackgroundProps> = memo(
  ({ speed, noParallax = false, sx, className }) => {
    const styles = {
      pointerEvents: 'none',
      position: 'absolute',
      left: -250,
      top: -525,
      width: 1100,
      ...sx,
    } as const;

    // only render client side
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
      setIsClient(true);
    }, []);

    if (!isClient || typeof window === 'undefined') {
      return null;
    }
    const userPrefersReducedMotion =
      window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    return noParallax || userPrefersReducedMotion ? (
      <Box sx={styles} className={className}>
        <Pattern />
      </Box>
    ) : (
      <Parallax
        speed={speed ?? 10}
        shouldAlwaysCompleteAnimation={true}
        sx={styles}
        className={className}
      >
        <Pattern />
      </Parallax>
    );
  },
);

BunionplastyBackground.displayName = 'BunionplastyBackground';
