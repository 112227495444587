import { FC } from 'react';

import { Link, Paragraph, Text } from 'voom-gatsby';

import { ReactComponent as PhoneIcon } from '~svg/icons/icon-phone.svg';

import { SanityDoctorOffice } from '~types/sanity';

interface PhoneLinkProps {
  variant?: 'link' | 'button';
  office: SanityDoctorOffice;
  fontSize?: (string | null)[] | string;
}

export const PhoneLink: FC<PhoneLinkProps> = ({
  variant = 'link',
  office,
  fontSize = 'md',
}) => {
  const { phoneNumber } = office;

  const handlePhoneLinkClick = () => {
    if (window.gtag) {
      window.gtag('event', 'Doctor Phone Number Button Clicked', {});
    }
  };

  if (!phoneNumber || !phoneNumber.display || !phoneNumber.tel) {
    return null;
  }

  if (variant === 'button') {
    return (
      <Link
        href={`tel:${phoneNumber.tel}${
          phoneNumber?.extension ? `,${phoneNumber.extension}` : ''
        }`}
        onClick={handlePhoneLinkClick}
        variant="buttonOutline"
        sx={{
          display: 'flex',
          flex: 1,
          py: '10px',
          px: ['12px', null, null, 4],
          alignItems: 'center',
          fontSize: ['xs', null, null, 'sm'],
          ':hover': {
            svg: {
              color: 'white',
            },
          },
        }}
      >
        <Text
          sx={{
            fontWeight: 'semibold',
            mr: 2,
            whiteSpace: ['nowrap', null, null, 'normal'],
          }}
        >
          {phoneNumber?.display}
        </Text>
        <PhoneIcon
          sx={{
            color: 'red',
            width: [14, null, null, 17],
            height: [14, null, null, 17],
          }}
        />
      </Link>
    );
  }

  return (
    <Paragraph variant="mediumP" sx={{ display: 'flex', alignItems: 'center' }}>
      <PhoneIcon
        sx={{
          mr: 2,
          width: [16, null, null, 20],
          height: [16, null, null, 20],
        }}
      />
      <Text>
        <Link
          href={`tel:${phoneNumber.tel}${phoneNumber?.extension ? `,${phoneNumber.extension}` : ''}`}
          onClick={handlePhoneLinkClick}
          variant="inline"
          sx={{ fontSize }}
        >
          {phoneNumber.display}{' '}
          {phoneNumber.extension ? `ext. ${phoneNumber.extension}` : ''}
        </Link>
      </Text>
    </Paragraph>
  );
};
