import { graphql } from 'gatsby';

import {
  BreadCrumbs,
  Container,
  Grid,
  Paragraph,
  Section,
  Spacer,
  StyledSup,
} from 'voom-gatsby';

import { BunionplastyBackground, ContentBlock, VideoCard } from '~components';

import { ContentBlockProps, VideoCardProps } from '~types/sanity';

export const VideoGrid = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    withSvg?: boolean;
    showBreadCrumb?: boolean;
    desktopRight?: boolean;
    content: ContentBlockProps;
    videoCards: VideoCardProps[];
    disclaimer?: string;
  };
  index: number;
}) => {
  // largeProtrait needs to include index + 1 to take into account only two videos
  const gridArea =
    data.videoCards.length === 2
      ? [
          `'largePortrait-1' 'largePortrait-2'`,
          null,
          null,
          `'largePortrait-1 largePortrait-2'`,
        ]
      : [
          `
        'largePortrait-1'
        'smallLandscapeTop'
        'smallLandscapeBottom'
      `,
          null,
          null,
          data.desktopRight
            ? `
        'smallLandscapeTop largePortrait-1'
        'smallLandscapeBottom largePortrait-1'
      `
            : `
      'largePortrait-1 smallLandscapeTop'
      'largePortrait-1 smallLandscapeBottom'
    `,
        ];
  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{
        bg: data.backgroundColor ?? 'beige',
      }}
    >
      <Container variant="videGridContainer" sx={{ position: 'relative' }}>
        {data.withSvg && (
          <BunionplastyBackground
            sx={{
              right: -300,
              bottom: -400,
              width: 1150,
            }}
          />
        )}
        {data.showBreadCrumb && <BreadCrumbs />}
        {data.content && (
          <>
            <ContentBlock
              content={data.content}
              titleSize="Lg"
              // left align if breadcrumbs are used          titleSize="Lg"
              sx={
                data.showBreadCrumb
                  ? {
                      maxWidth: [600, null, null, null, 900],
                      mb: data.disclaimer ? 5 : [5, null, 8],
                    }
                  : {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      maxWidth: [500, null, null, null, 750],
                      mx: 'auto',
                      textAlign: 'center',
                      mb: 5,
                      position: 'relative',
                      zIndex: 1,
                    }
              }
            />
            {data.showBreadCrumb && data.disclaimer && (
              <>
                <Paragraph
                  variant="disclaimer"
                  sx={{ maxWidth: [600, null, null, null, 900] }}
                >
                  <StyledSup>{data.disclaimer}</StyledSup>
                </Paragraph>
                <Spacer space={[5, null, 8]} />
              </>
            )}
          </>
        )}
        <Grid
          sx={{
            position: 'relative',
            zIndex: 1,
            gridTemplateColumns: ['1fr', null, null, 'repeat(2, 1fr)'],
            rowGap: '36px',
            columnGap: '31px',
            gridTemplateAreas: gridArea,
          }}
        >
          {data.videoCards.map((card, index) => {
            return (
              <VideoCard
                key={card.video.file.asset.url}
                {...card}
                index={index}
                backgroundColor={data.backgroundColor ?? 'beige'}
              />
            );
          })}
        </Grid>
        {data.disclaimer && !data.showBreadCrumb && (
          <>
            <Spacer space={5} />
            <Paragraph
              variant="disclaimer"
              sx={{ mx: 'auto', maxWidth: 400, textAlign: 'center' }}
            >
              <StyledSup>{data.disclaimer}</StyledSup>
            </Paragraph>
          </>
        )}
      </Container>
    </Section>
  );
};

export const VIDEO_GRID_QUERY = graphql`
  fragment videoGridFields on SanityVideoGrid {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    withSvg
    showBreadCrumb
    content {
      ...sanityContentBlockFields
    }
    desktopRight
    videoCards {
      cardType
      imageSet {
        mobileImage {
          alt
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        desktopImage {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
          alt
        }
      }
      quote
      video {
        file {
          asset {
            url
          }
        }
        type
      }
      _rawCaption
    }
    disclaimer
  }
`;
