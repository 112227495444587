import { graphql, useStaticQuery } from 'gatsby';
import type { ReactElement } from 'react';

import { Box, Flex, NavDataProps, Navigation } from 'voom-gatsby';

import { Footer } from '~sections/Footer/Footer';

import { getHref } from '~utils/helpers';

import { ReactComponent as MenuCloseIcon } from '~svg/icons/icon-close.svg';
import { ReactComponent as MenuIcon } from '~svg/icons/icon-menu.svg';
import { ReactComponent as SearchIcon } from '~svg/icons/icon-search.svg';
import { ReactComponent as Logo } from '~svg/logo/bunionplasty-with-caption.svg';

import { CtaProps, DropdownProps } from '~types/sanity';

export interface PageLayoutProps {
  children: ReactElement;
  pageContext: {
    secondaryNav?: boolean;
  };
}

export const NAVIGATION_QUERY_NEW = graphql`
  query NavigationNew {
    mainMenu: sanityNavigationMenu(
      # title: Main
      _id: { eq: "bb26a30a-49bc-445d-8ae0-20844f4cfc39" }
    ) {
      items {
        ... on SanityCta {
          _type
          type
          label
          route
          link
          landingPageRoute {
            slug {
              current
            }
          }
        }
        ... on SanityDropdownList {
          _type
          label
          items {
            type
            description
            label
            route
            link
            landingPageRoute {
              slug {
                current
              }
            }
          }
        }
      }
    }
    navSettings: sanitySiteSettings(
      _id: { eq: "e983f7e8-8b28-486d-a3d0-8e3d066bd206" }
    ) {
      email {
        display
        link
      }
      phoneNumber {
        display
        link
      }
      socials {
        icon {
          svg
        }
        title
        URL
      }
    }
  }
`;

export const PageLayout = ({
  children,
  pageContext,
}: PageLayoutProps): JSX.Element => {
  const { navSettings, mainMenu } = useStaticQuery(NAVIGATION_QUERY_NEW);

  const navData: NavDataProps = {
    Logo: Logo,
    CloseIcon: MenuCloseIcon,
    MenuIcon: MenuIcon,
    ButtonLinkIcon: SearchIcon,
    links: mainMenu.items.map((link: CtaProps | DropdownProps) => {
      if ('items' in link) {
        return {
          label: link.label,
          subLinks: link.items.map((subLink) => {
            const url = getHref(subLink);
            return {
              ...subLink,
              url: url,
            };
          }),
        };
      } else {
        const url = getHref(link);
        return {
          ...link,
          url: url,
        };
      }
    }),
    ...navSettings,
  };

  return (
    <Flex
      sx={{
        fontFamily: 'body',
        '*:focus, *:active': {
          outlineColor: (theme) => `${theme?.colors?.primary}`,
          outlineOffset: 0,
        },
        flexDirection: 'column',
        alignItems: 'stretch',
        minHeight: 'full',
        main: {
          flexGrow: 1,
          flexShrink: 0,
        },
        'header, footer': {
          flexShrink: 0,
        },
      }}
    >
      <Box sx={{ fontSize: 0 }} data-id="top-of-page" />
      <Navigation
        pageTheme="base"
        data={navData}
        hasSecondaryNav={pageContext.secondaryNav}
      />
      <Box as="main">{children}</Box>
      <Footer />
    </Flex>
  );
};
