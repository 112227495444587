import { InfoWindowF, MarkerF, MarkerProps } from '@react-google-maps/api';
import { AnimatePresence, motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';

import {
  Button,
  CloseSvg,
  Flex,
  Heading,
  IconButton,
  Link,
  Paragraph,
  Spacer,
  Text,
  useIsTouchDevice,
} from 'voom-gatsby';

import { ConsultationTag } from '~components';

import { DoctorResult } from '~types/sanity';

export const DoctorResultMarker = ({
  position,
  clusterer,
  active,
  onClick,
  doctor,
}: {
  doctor: DoctorResult;
  position: {
    lat: number;
    lng: number;
  };
  clusterer: MarkerProps['clusterer'];
  active: boolean;
  onClick: () => void;
}) => {
  const isTouchDevice = useIsTouchDevice();
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    if (!active) {
      setShowInfoWindow(false);
    }
  }, [active, setShowInfoWindow]);
  return (
    <MarkerF
      // onMouseOver={() => setShowInfoWindow(true)}
      // onMouseOut={() => setShowInfoWindow(false)}
      title={`${doctor.practiceName} map marker`}
      position={position}
      clusterer={clusterer}
      options={{
        icon: active
          ? '/icon-geomarker-selected.svg'
          : '/icon-geomarker-doctor.svg',
        zIndex: active ? 1 : 0,
      }}
      onClick={() => {
        onClick();
        setShowInfoWindow(true);
      }}
    >
      <AnimatePresence>
        {showInfoWindow && !isTouchDevice && (
          // infoWindow style overwrites in results.tsx
          <InfoWindowF position={position}>
            <motion.div
              variants={{
                default: { opacity: 0 },
                active: { opacity: 1 },
              }}
              initial="default"
              animate="active"
              exit="default"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 200,
                position: 'relative',
                bg: 'white',
              }}
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  right: 1,
                  top: 1,
                  zIndex: 1,
                  width: 30,
                  height: 30,
                  bg: 'white',
                  svg: {
                    width: '50%',
                    height: '50%',
                  },
                }}
                onClick={() => setShowInfoWindow(false)}
              >
                <CloseSvg />
              </IconButton>
              <GatsbyImage
                image={doctor.image.asset.gatsbyImageData}
                alt={doctor.image.alt}
                sx={{
                  aspectRatio: '175 / 120',
                  width: 'full',
                }}
              />
              <Flex sx={{ flexDirection: 'column', p: 3 }}>
                <Paragraph variant="copyright">
                  {`${doctor.city.name}, ${doctor.state.abbreviation}`}
                </Paragraph>
                <Heading variant="heading" sx={{ fontSize: 'xs' }}>
                  <Text sx={{ fontWeight: 'medium' }}>{doctor.name}</Text>
                </Heading>
                <Spacer space={2} />
                <Flex>
                  {doctor.consultations.map((consultation) => (
                    <ConsultationTag
                      key={consultation}
                      consultation={consultation}
                      withText={false}
                      sx={{
                        bg: 'tan',
                        mr: 2,
                      }}
                    />
                  ))}
                </Flex>
                <Spacer space={3} />
                <Link
                  variant="button"
                  to={`/find-a-doctor/doctors/${doctor.slug.current}`}
                  sx={{ px: 3, height: 35, transform: 'none !important' }}
                >
                  View Profile
                </Link>
              </Flex>
            </motion.div>
          </InfoWindowF>
        )}
      </AnimatePresence>
    </MarkerF>
  );
};
