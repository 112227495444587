import { useLocation } from '@reach/router';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { FC, SVGProps, useEffect } from 'react';

import { Box, Container, Flex, Link, ThemeUIProvider } from '../../components';
import { navStateAtom, useStickyHeader } from '../../hooks';
import { ThemeName, getThemeByName } from '../../themes';
import { Menu, MenuProps } from './Menu';

export interface NavDataProps extends MenuProps {
  Logo: FC<SVGProps<SVGElement>>;
}

interface NavigationProps {
  pageTheme?: ThemeName;
  data: NavDataProps;
  hasSecondaryNav?: boolean;
}

export const Navigation = ({
  pageTheme,
  data,
  hasSecondaryNav,
}: NavigationProps) => {
  const Logo = data.Logo;
  // Powers the navState
  useStickyHeader();

  const [
    { isSticky, isNavVisible, preventPeekaBoo, isDropdownOpen },
    setNavState,
  ] = useAtom(navStateAtom);

  const { pathname } = useLocation();

  useEffect(() => {
    setNavState((state) => ({ ...state, preventPeekaBoo: isDropdownOpen }));
  }, [isDropdownOpen, setNavState]);

  useEffect(() => {
    setNavState((state) => ({
      ...state,
      preventPeekaBoo: false,
      isDropdownOpen: false,
      isSticky: false,
      isNavVisible: true,
    }));
  }, [pathname, setNavState]);

  useEffect(() => {
    if (hasSecondaryNav === true) {
      setNavState((state) => ({ ...state, secondaryNav: true }));
    } else {
      setNavState((state) => ({ ...state, secondaryNav: false }));
    }
  }, [setNavState, hasSecondaryNav]);

  return (
    <ThemeUIProvider
      theme={getThemeByName(isDropdownOpen ? 'base' : pageTheme)}
    >
      <motion.div
        sx={{
          zIndex: 'nav',
          position: 'fixed',
          right: 0,
          left: 0,
          top: 0,
          height: [
            'stickyNavHeight',
            null,
            null,
            isSticky ? 'stickyNavHeight' : 'navHeight',
          ],
          color: 'text',
          boxShadow: isSticky || preventPeekaBoo ? 'navShadow' : 'none',
        }}
        initial="show"
        animate={preventPeekaBoo ? 'show' : isNavVisible ? 'show' : 'hide'}
        variants={{
          hide: {
            transform: 'translate3d(0, -100%, 0)',
            opacity: 0,
          },
          show: {
            transform: 'translate3d(0, 0%, 0)',
            opacity: 1,
            backgroundColor:
              isSticky || preventPeekaBoo ? 'white' : 'transparent',
          },
        }}
      >
        <Container variant="navContainer" sx={{ height: 'full' }}>
          <Flex
            as="nav"
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
              height: 'full',
            }}
          >
            <Link
              href="/"
              aria-label="Go to homepage"
              sx={{
                color: 'text',
                transform:
                  process.env.THEME === 'voom'
                    ? isSticky || preventPeekaBoo
                      ? 'scale(1)'
                      : 'scale(1.25)'
                    : 'scale(1.25)', // bunionplasty has no zoom
                transformOrigin: 'left center',
                // TODO: can we do this outside of voom-gatsby
                flex:
                  process.env.THEME === 'voom'
                    ? ['0 0 150px', '0 0 175px']
                    : ['0 0 150px', null, '0 0 250px'],
                fontSize: '0px',
                mr:
                  isSticky || preventPeekaBoo
                    ? [2, null, null, 2]
                    : [6, null, null, 75],
                transition: 'transform 0.2s ease-in',
              }}
            >
              <Logo
                sx={{
                  width: 'full',
                  height: 'full',
                  color: isSticky || preventPeekaBoo ? 'stickyLogo' : 'logo',
                }}
              />
            </Link>
            <Menu {...data} />
          </Flex>
        </Container>
      </motion.div>
      {/* voom homepage hero is background image, so we need to prevent taking up space */}
      {(process.env.THEME !== 'voom' || pathname !== '/') && (
        <Box
          sx={{
            width: 'full',
            height: ['stickyNavHeight', null, null, 'navHeight'],
            bg: 'background',
          }}
        />
      )}
    </ThemeUIProvider>
  );
};
