import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Box,
  Container,
  Section,
  ThemeUIStyleObject,
  useImageSources,
} from 'voom-gatsby';

import { ContentBlock } from '~components';

import { ContentBlockProps, ImageWithAltProps } from '~types/sanity';

export const BackgroundCallout = ({
  data,
  index,
}: {
  data: {
    backgroundColor?: string;
    content: ContentBlockProps;
    imageSet: {
      desktopImage: ImageWithAltProps;
      mobileImage: ImageWithAltProps;
    };
    desktopRight?: boolean;
    desktopFullWidth?: boolean;
  };
  index: number;
}) => {
  const imageSources = useImageSources([
    data.imageSet.mobileImage.asset.gatsbyImageData,
    null,
    null,
    data.imageSet.desktopImage.asset.gatsbyImageData,
  ]);

  const contentStyles: ThemeUIStyleObject = data.desktopFullWidth
    ? {
        textAlign: 'center',
        mx: 'auto',
        position: 'absolute',
        bottom: 3,
        left: '50%',
        transform: 'translateX(-50%)',
        'h2, p, span': { color: 'white' },
        maxWidth: 500,
        width: 'full',
        px: [6, null, 2],
      }
    : {
        width: [null, null, null, '50%'],
        textAlign: 'unset',
        mx: ['auto', null, null, 0],
        mt: [-7, null, null, 0],
        pt: [0, null, null, 8],
        pl: [4, null, null, 9],
        pr: 4,
        pb: 3,
      };
  return (
    <Section sx={{ bg: 'white', py: [0, 0, 0] }}>
      <Container sx={{ pt: data.desktopFullWidth ? 0 : [5, 7], pb: [5, 7] }}>
        <Box
          sx={{
            bg: data.backgroundColor ?? 'beige',
            borderRadius: 'rounded',
            height: ['auto', null, null, data.desktopFullWidth ? 650 : 800],
            position: 'relative',
            zIndex: 1,
            overflow: 'hidden',
          }}
        >
          <GatsbyImage
            image={imageSources}
            alt={data.imageSet.mobileImage.alt}
            sx={{
              position: ['relative', null, null, 'absolute'],
              right: [null, null, null, data.desktopRight ? 0 : 'unset'],
              left: [null, null, null, data.desktopRight ? 'unset' : 0],
              top: [null, null, null, 0],
              bottom: [null, null, null, 0],
              // about us page needed filter to see text
              filter: data.desktopFullWidth
                ? ['brightness(0.6)', null, 'unset']
                : null,
              width: [
                '100%',
                null,
                null,
                data.desktopFullWidth ? '100%' : '60%',
              ],
              zIndex: -1,
            }}
            imgStyle={{ objectPosition: 'top' }}
          />
          <ContentBlock
            content={data.content}
            sx={contentStyles}
            titleStyles={{
              maxWidth: data.desktopFullWidth
                ? 'none'
                : ['none', null, null, '90%'],
            }}
            linkStyles={{ a: { px: 3 } }}
            headingAs={index === 0 ? 'h1' : undefined}
          />
        </Box>
      </Container>
    </Section>
  );
};

export const BACKGROUND_CALLOUT_QUERY = graphql`
  fragment backgroundCalloutFields on SanityBackgroundCallout {
    _type
    backgroundColor
    content {
      ...sanityContentBlockFields
    }
    desktopRight
    desktopFullWidth
    imageSet {
      desktopImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
        alt
      }
      mobileImage {
        alt
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  }
`;
