import { graphql } from 'gatsby';
import { Fragment } from 'react';

import {
  Box,
  Container,
  Flex,
  Grid,
  Paragraph,
  Section,
  StyledSup,
} from 'voom-gatsby';

import { ContentBlock, ScriptsBackground } from '~components';

import { ReactComponent as IconCheckPink } from '~svg/icons/icon-check-pink.svg';
import { ReactComponent as IconCheck } from '~svg/icons/icon-check.svg';
import { ReactComponent as BLogo } from '~svg/logo/BLogo.svg';
import { ReactComponent as BunionplastyLogo } from '~svg/logo/bunionplasty-v2.svg';

import { ContentBlockProps } from '~types/sanity';

export const BunionplastyTable = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    content: ContentBlockProps;
    rows: {
      text: string;
      bunionplasty?: boolean;
      lapidus?: boolean;
    }[];
    disclaimer?: string;
  };
  index: number;
}) => {
  return (
    <Section
      id="bunionplasty-table"
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{
        bg: data.backgroundColor ?? 'beige',
        pb: [6, null, 7], // disclaimer spacing
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <ScriptsBackground />
      <Container variant="narrowContainer">
        <ContentBlock
          content={data.content}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 1000,
            mx: 'auto',
            textAlign: 'center',
          }}
          captionStyles={{
            maxWidth: 700,
            mx: 'auto',
          }}
        />
        <Grid
          gap={0}
          sx={{
            gridTemplateColumns: [
              'minmax(150px, 2fr) 1fr 1fr',
              null,
              null,
              'minmax(300px, 1fr) 1fr 1fr',
            ],
            boxShadow: 'bunionplastyTableShadow',
          }}
        >
          <Box />
          <Flex
            sx={{
              borderTopLeftRadius: 'radius',
              borderTopRightRadius: 'radius',
              bg: 'wine',
              p: 2,
              pb: 0,
              color: 'white',
              justifyContent: 'center',
            }}
          >
            <BLogo
              sx={{
                display: ['block', null, null, 'none'],
                width: 34,
                height: 'auto',
                color: 'white',
                mb: 2,
              }}
            />
            <BunionplastyLogo
              sx={{
                display: ['none', null, null, 'block'],
                width: 200,
                height: 'auto',
                color: 'white',
              }}
            />
          </Flex>
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Paragraph
              sx={{ fontSize: ['sm', null, null, 'lg'], lineHeight: 'heading' }}
            >
              Lapidus Surgery
            </Paragraph>
          </Flex>

          {data.rows.map((row, index) => {
            const lastRow = index === data.rows.length - 1;
            const firstRow = index === 0;
            return (
              <Fragment key={row.text}>
                <Flex
                  sx={{
                    alignItems: 'center',
                    minHeight: 57,
                    bg: 'white',
                    borderTopLeftRadius: firstRow ? 'radius' : 'square',
                    borderBottomLeftRadius: lastRow ? 'radius' : 'square',
                    ...(firstRow ? { pt: 3 } : {}),
                    ...(lastRow ? { pb: 3 } : {}),
                  }}
                >
                  <Flex
                    sx={{
                      alignItems: 'center',
                      py: 3,
                      pl: 0,
                      pr: 2,
                      ml: [3, 5],

                      width: 'full',
                      height: 'full',
                      borderBottom: (theme) =>
                        lastRow ? 'none' : `1px solid ${theme.colors?.grey300}`,
                    }}
                  >
                    <Paragraph variant="bunionplastyRow">
                      <StyledSup>{row.text}</StyledSup>
                    </Paragraph>
                  </Flex>
                </Flex>
                <Flex
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'full',
                    bg: 'wine',
                    position: 'relative',
                  }}
                >
                  <Flex
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 'full',
                      width: 'full',
                      mx: [2, 5],
                      borderBottomRightRadius: lastRow ? 'radius' : 'none',
                      borderBottom: (theme) =>
                        lastRow
                          ? 'none'
                          : `1px solid ${theme.colors?.pinkOpacity}`,
                    }}
                  >
                    {row.bunionplasty === true && (
                      <IconCheckPink sx={{ width: 28, height: 28 }} />
                    )}
                  </Flex>
                  {lastRow && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 'calc(100% - 1px)',
                        left: 0,
                        right: 0,
                        height: 16,
                        bg: 'wine',
                        borderBottomLeftRadius: 'radius',
                        borderBottomRightRadius: 'radius',
                      }}
                    />
                  )}
                </Flex>
                <Flex
                  sx={{
                    height: 'full',
                    bg: 'white',
                    borderTopRightRadius: firstRow ? 'radius' : 'square',
                    borderBottomRightRadius: lastRow ? 'radius' : 'square',
                  }}
                >
                  <Flex
                    sx={{
                      alignItems: 'center',
                      bg: 'white',
                      mr: [2, 5],
                      width: 'full',
                      justifyContent: 'center',
                      borderBottom: (theme) =>
                        lastRow ? 'none' : `1px solid ${theme.colors?.grey300}`,
                    }}
                  >
                    {row.lapidus === true && (
                      <IconCheck sx={{ width: 20, height: 20, mr: [-2, -5] }} />
                    )}
                  </Flex>
                </Flex>
              </Fragment>
            );
          })}
        </Grid>
        {data.disclaimer && (
          <Paragraph
            variant="disclaimer"
            sx={{
              mx: 'auto',
              maxWidth: 400,
              textAlign: 'center',
              mt: 6,
            }}
          >
            <StyledSup>{data.disclaimer}</StyledSup>
          </Paragraph>
        )}
      </Container>
    </Section>
  );
};

export const BUNIONPLASTY_TABLE_QUERY = graphql`
  fragment bunionplastyTableFields on SanityBunionplastyTable {
    _type # needed to select this component to render in template
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    content {
      ...sanityContentBlockFields
    }
    backgroundColor
    disclaimer
    rows {
      bunionplasty
      lapidus
      text
    }
  }
`;
