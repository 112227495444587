import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useMemo, useState } from 'react';

import {
  BreadCrumbs,
  Container,
  Flex,
  Grid,
  Paragraph,
  Section,
  Spacer,
  Spinner,
} from 'voom-gatsby';

import {
  BeforeAfterCard,
  ContentBlock,
  FilterRow,
  GridCallout,
} from '~components';

import {
  BeforeAfterCardProps,
  ContentBlockProps,
  GridCalloutProps,
} from '~types/sanity';

export const BeforeAfterGrid = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    content: ContentBlockProps;
    disclaimer?: string;
    gridCallout: GridCalloutProps;
  };
  index: number;
}) => {
  const { allSanityBeforeAfterCard, allSanitySeverityFilter } = useStaticQuery(
    ALL_BEFORE_AFTER_CARDS_QUERY,
  );

  const severityFilters = [
    { label: 'All', value: null },
    ...allSanitySeverityFilter.nodes.map(
      ({ value }: { value: string; icon: { svg: string } }) => ({
        label: `${value} Bunions`,
        value: value,
      }),
    ),
  ];

  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  // Request from client to sort cards randomly
  const randomSortedCards = useMemo(
    () =>
      allSanityBeforeAfterCard.nodes
        .map((card: BeforeAfterCardProps) => ({ ...card, sort: Math.random() }))
        .sort((a: { sort: number }, b: { sort: number }) => a.sort - b.sort),
    [allSanityBeforeAfterCard.nodes],
  );

  const beforeAfterCards: BeforeAfterCardProps[] = useMemo(
    () =>
      randomSortedCards.filter(
        (card: BeforeAfterCardProps) =>
          activeFilter === null || card.severity.value === activeFilter,
      ),
    [activeFilter, randomSortedCards],
  );

  const cardCount: number = beforeAfterCards.length;

  // only render cards client side
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{
        bg: data.backgroundColor ?? 'white',
        overflow: 'hidden',
      }}
    >
      <Container sx={{ position: 'relative' }}>
        <BreadCrumbs />
        <ContentBlock
          content={data.content}
          titleSize="Lg"
          sx={{
            maxWidth: [600, null, null, null, 900],
          }}
        />
        {data.disclaimer && (
          <Paragraph
            variant="disclaimer"
            sx={{ maxWidth: [600, null, null, null, 900], mb: 4 }}
          >
            {data.disclaimer}
          </Paragraph>
        )}
        <FilterRow
          filters={severityFilters}
          label="Bunion Severity"
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
        <Spacer space={5} />
        <Paragraph>{`${cardCount} Results`}</Paragraph>
        <Spacer />

        {isClient ? (
          <Grid
            columns={[1, 2, 3]}
            gap={[4, null, 5, 7]}
            sx={{ minHeight: 300 }}
          >
            {beforeAfterCards.map((card, index) => {
              if (
                data.gridCallout &&
                data.gridCallout.frequency > 0 &&
                (index + 1) % data.gridCallout.frequency === 0
              ) {
                return (
                  <GridCallout
                    key={`grid-callout-${index}`}
                    index={index}
                    {...data.gridCallout}
                  />
                );
              }
              return (
                <BeforeAfterCard
                  {...card}
                  key={
                    card.image.asset.gatsbyImageData.images.fallback?.src ??
                    index
                  }
                />
              );
            })}
          </Grid>
        ) : (
          <Flex
            sx={{
              minHeight: 500,
              width: 'full',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </Flex>
        )}
      </Container>
    </Section>
  );
};

const ALL_BEFORE_AFTER_CARDS_QUERY = graphql`
  query AllBeforeAfterCards {
    allSanityBeforeAfterCard(filter: { featured: { eq: true } }) {
      nodes {
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
          alt
        }
        severity {
          value
        }
      }
    }
    allSanitySeverityFilter {
      nodes {
        value
        icon {
          svg
        }
      }
    }
  }
`;

export const BEFORE_AFTER_GRID_QUERY = graphql`
  fragment beforeAfterGridFields on SanityBeforeAfterGrid {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    content {
      ...sanityContentBlockFields
    }
    disclaimer
    gridCallout {
      ...sanityGridCalloutFields
    }
  }
`;
