import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useState } from 'react';

import { Box, Button, Flex, Modal, Paragraph, useHover } from 'voom-gatsby';

import { ContentBlock } from '~components';

import { ReactComponent as PlayIcon } from '~svg/icons/icon-play.svg';

import { VideoCardProps } from '~types/sanity';

export const VideoCard = (card: VideoCardProps) => {
  const gridArea =
    card.cardType === 'Large Portrait'
      ? `largePortrait-${card.index + 1}`
      : card.cardType === 'Small Landscape Top'
        ? `smallLandscapeTop`
        : 'smallLandscapeBottom';

  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  const [isHovering, hoverProps] = useHover();

  const isActive = isHovering || showDialog;
  return (
    <>
      <Button
        variant="ghost"
        onClick={open}
        sx={{
          width: 'full',
          height: 'full',
          gridArea: gridArea,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 'rounded',
          transition: isActive
            ? 'box-shadow 0.25s ease-in-out'
            : 'box-shadow 0s ease-in-out',
          boxShadow: isActive ? 'videoCardShadow' : 'none',
        }}
        {...hoverProps}
      >
        <motion.div
          variants={{
            default: { borderWidth: '7px' },
            hover: { borderWidth: '0px' },
          }}
          animate={isActive ? 'hover' : 'default'}
          transition={{ duration: 0.2 }}
          sx={{
            borderColor: card.backgroundColor ?? 'white',
            borderStyle: 'solid',
            position: 'absolute',
            inset: 0,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            borderRadius: (theme) => theme.radii?.rounded - 1,
            zIndex: 1,
          }}
        />
        {/* TODO: Find out why useImageSources wont work here */}
        <GatsbyImage
          image={card.imageSet.mobileImage.asset.gatsbyImageData}
          alt={card.imageSet.mobileImage.alt}
          sx={{
            height: 'full',
            display: ['block', null, 'none'],
            img: { borderRadius: 'rounded' },
          }}
        />
        <GatsbyImage
          image={card.imageSet.desktopImage.asset.gatsbyImageData}
          alt={card.imageSet.desktopImage.alt}
          sx={{
            height: 'full',
            display: ['none', null, 'block'],
            img: { borderRadius: 'rounded' },
          }}
        />

        {card.quote && (
          <>
            <Paragraph
              as="q"
              sx={{
                maxWidth: 300,
                textAlign: 'left',
                zIndex: 1,
                lineHeight: 'none',
                fontFamily: 'cursive',
                fontSize: ['4xl', '5xl'],
                color: 'pink',
                position: 'absolute',
                top: [4, 5],
                left: [4, 5],
                // Make open quote line up outside of text block
                '&::before': {
                  content: 'open-quote',
                  position: 'absolute',
                  right: '100%',
                },
              }}
            >
              {card.quote}
            </Paragraph>
            <Box
              sx={{
                borderRadius: 'rounded',
                position: 'absolute',
                inset: 0,
                background:
                  'linear-gradient(123.74deg, rgba(255,255,255,1) 0%,rgba(255,255,255,0.9) 15%,rgba(255,255,255,0.3) 40%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%)',
              }}
            />
          </>
        )}
        <Flex
          sx={{
            position: 'absolute',
            left: [3, null, 5],
            bottom: [3, null, 5],
            alignItems: 'center',
          }}
        >
          <motion.div
            variants={{
              default: { scale: 1 },
              hover: { scale: 1.1 },
            }}
            animate={isActive ? 'hover' : 'default'}
            sx={{
              variant: 'buttons.icon',
              bg: 'white',
              width: 55,
              height: 55,
              mr: 3,
            }}
          >
            <PlayIcon sx={{ color: 'primary', width: 20, height: 20 }} />
          </motion.div>
          <ContentBlock
            content={{ _rawCaption: card._rawCaption }}
            captionStyles={{
              textAlign: 'left',
              p: {
                color: 'white',
                fontWeight: 'bold',
                lineHeight: 'bodyNarrow',
                fontSize: 'sm',
                letterSpacing: 'snug',
              },
            }}
          />
        </Flex>
      </Button>
      <Modal close={close} open={open} isOpen={showDialog}>
        {/* eslint-disable jsx-a11y/media-has-caption */}
        <video
          autoPlay
          controls
          sx={{
            '&::-webkit-media-controls-panel': {
              // remove gradient form controls
              backgroundImage: 'none !important',
            },
          }}
        >
          <source src={card.video.file.asset.url} type={card.video.type} />
          <p>{`Your browser doesn't support HTML5 video.`}</p>
        </video>
      </Modal>
    </>
  );
};
