import {
  PortableText,
  PortableTextProps,
  PortableTextReactComponents,
} from '@portabletext/react';

import { Box, Paragraph, ThemeUIStyleObject } from 'voom-gatsby';

import { marks } from '~components';

export const disclaimerComponents: Partial<PortableTextReactComponents> = {
  marks: marks,
  block: {
    normal: ({ children }) => (
      <Paragraph variant="copyright" sx={{ mb: 2, lineHeight: 'body' }}>
        {children}
      </Paragraph>
    ),
  },
};

export const Disclaimer = ({
  value,
  sx,
  className,
}: {
  value: PortableTextProps['value'];
  sx?: ThemeUIStyleObject;
  className?: string;
}) => {
  return (
    <Box sx={{ my: 5, ...sx }} className={className}>
      <PortableText value={value} components={disclaimerComponents} />
    </Box>
  );
};
