import { motion } from 'framer-motion';
import { useAtom } from 'jotai';

import { Button, ThemeUIStyleObject } from 'voom-gatsby';

import { mobileResultsViewAtom } from '~hooks/use-doctor-results';

import { ReactComponent as IconGeoMarker } from '~svg/icons/icon-geomarker.svg';
import { ReactComponent as IconList } from '~svg/icons/icon-list.svg';

interface IconToggleProps {
  sx?: ThemeUIStyleObject;
  className?: string;
}

// TODO: Make this reusable
export const IconToggle = ({ sx, className }: IconToggleProps) => {
  const [mobileView, setMobileView] = useAtom(mobileResultsViewAtom);

  return (
    <motion.div layout layoutRoot>
      <Button
        variant="ghost"
        className={className}
        sx={{
          width: 65,
          height: 50,
          borderRadius: '100px',
          p: 0,
          display: 'flex',
          cursor: 'pointer',
          justifyContent: mobileView === 'map' ? 'flex-end' : 'flex-start',
          bg: 'beige',
          boxShadow: 'iconShadow',
          position: 'relative',
          '::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: '100px',
            bg: 'beige',
            filter: 'brightness(0.9)',
          },
          ...sx,
        }}
        onClick={() => {
          if (window.gtag) {
            window.gtag('event', 'Map Icon Toggle Click', {
              selectedTab: mobileView,
            });
          }
          setMobileView(mobileView === 'map' ? 'list' : 'map');
        }}
        aria-label="Toggle map/list view"
      >
        <motion.div
          layout
          sx={{
            zIndex: 'sticky',
            variant: 'buttons.icon',
            borderRadius: 'rounded',
            bg: 'beige',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'red',
            color: 'red',
            boxShadow: 'none',
            svg: {
              color: 'red',
              transition: 'color 0.3s ease-in-out',
              width: 18,
              height: 18,
            },
          }}
        >
          {mobileView === 'list' ? <IconGeoMarker /> : <IconList />}
        </motion.div>
      </Button>
    </motion.div>
  );
};
