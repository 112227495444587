import { graphql, useStaticQuery } from 'gatsby';
import { Fragment, useMemo, useState } from 'react';

import {
  BreadCrumbs,
  Container,
  Grid,
  Paragraph,
  Section,
  Spacer,
  StyledSup,
} from 'voom-gatsby';

import { ContentBlock, FilterRow, RevealVideoCard } from '~components';

import { ContentBlockProps, RevealVideoCardProps } from '~types/sanity';

export const RevealVideoGrid = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    content: ContentBlockProps;
    disclaimer?: string;
  };
  index: number;
}) => {
  const { allSanityRevealVideoCard, allSanityDurationFilter } = useStaticQuery(
    ALL_REVEAL_VIDEOS_QUERY,
  );

  const durationFilters = [
    { label: 'All', value: null },
    ...allSanityDurationFilter.nodes
      .sort((a: { order: number }, b: { order: number }) => a.order - b.order)
      .map(({ value }: { value: string }) => ({ label: value, value: value })),
  ];

  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  const revealVideos: RevealVideoCardProps[] = useMemo(
    () =>
      allSanityRevealVideoCard.nodes.filter(
        ({ duration }: { duration: { value: string } }) =>
          duration.value === activeFilter || activeFilter === null,
      ),
    [activeFilter, allSanityRevealVideoCard.nodes],
  );
  const revealVideoCount: number = revealVideos.length;

  const [videoPlayingIndex, setVideoPlayingIndex] = useState<number | null>(
    null,
  );
  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{
        bg: data.backgroundColor ?? 'white',
      }}
    >
      <Container sx={{ position: 'relative' }}>
        <BreadCrumbs />
        <ContentBlock
          content={data.content}
          titleSize="Lg"
          sx={{
            maxWidth: [600, null, null, null, 900],
          }}
        />
        {data.disclaimer && (
          <Paragraph
            variant="disclaimer"
            sx={{ maxWidth: [600, null, null, null, 900], mb: 4 }}
          >
            <StyledSup>{data.disclaimer}</StyledSup>
          </Paragraph>
        )}
        <FilterRow
          filters={durationFilters}
          label="Healing time"
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
        <Spacer space={10} />
        <Paragraph>{`${revealVideoCount} Results`}</Paragraph>
        <Spacer />
        <Grid columns={[1, 2, 3]} gap={7}>
          {revealVideos.map((card, index) => {
            return (
              <RevealVideoCard
                {...card}
                key={card.video.file.asset.url}
                videoPlaying={videoPlayingIndex === index}
                setVideoPlayingIndex={setVideoPlayingIndex}
                index={index}
              />
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};

const ALL_REVEAL_VIDEOS_QUERY = graphql`
  query AllRevealVideos {
    allSanityRevealVideoCard {
      nodes {
        video {
          file {
            asset {
              url
            }
          }
          type
        }
        image {
          alt
          asset {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        duration {
          value
        }
      }
    }
    allSanityDurationFilter {
      nodes {
        value
        order
      }
    }
  }
`;

export const REVEAL_VIDEO_GRID_QUERY = graphql`
  fragment revealVideoGridFields on SanityRevealVideoGrid {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    content {
      ...sanityContentBlockFields
    }
    disclaimer
  }
`;
