import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactSlickSlider, { Settings } from 'react-slick';

import { Flex, Section } from 'voom-gatsby';

import { ImageWithAltProps } from '~types/sanity';

export const FeaturedCarousel = ({
  featuredImages,
  backgroundColor,
  narrowPaddingTop,
  narrowPaddingBottom,
}: {
  backgroundColor?: string;
  narrowPaddingTop?: boolean;
  narrowPaddingBottom?: boolean;
  featuredImages: ImageWithAltProps[];
  index: number;
}) => {
  const featuredCarouselSettings: Settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    variableWidth: true,
    slidesToScroll: 1,
  };

  return (
    <Section
      narrowPaddingTop={narrowPaddingTop}
      narrowPaddingBottom={narrowPaddingBottom}
      sx={{ bg: backgroundColor, py: [6, 6, 6] }}
    >
      <ReactSlickSlider {...featuredCarouselSettings}>
        {featuredImages.map((image, index) => {
          return (
            <Flex
              key={image.asset.gatsbyImageData.images.fallback?.src ?? index}
              sx={{
                display: 'flex !important', // override react-slick inline styles
                maxWidth: 200,
                height: 55,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <GatsbyImage
                image={image.asset.gatsbyImageData}
                alt={image.alt}
                imgStyle={{ objectFit: 'contain' }}
                sx={{ mx: 4 }}
              />
            </Flex>
          );
        })}
      </ReactSlickSlider>
    </Section>
  );
};

export const FEATURED_CAROUSEL_QUERY = graphql`
  fragment featuredCarouselFields on SanityFeaturedCarousel {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    featuredImages {
      asset {
        gatsbyImageData(layout: FIXED, placeholder: NONE)
      }
      alt
    }
  }
`;
