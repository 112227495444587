import { useSelect } from 'downshift';
import { useEffect } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { Flex, Link } from 'voom-gatsby';

import { ReactComponent as IconChevron } from '~svg/icons/icon-chevron.svg';

type SelectDropdownProps = {
  menuItems: string[];
  register: { name: string };
  setValue: UseFormSetValue<FieldValues>;
  onSelectChange?: () => void;
  fullWidth?: boolean;
};

export const SelectDropdown = ({
  menuItems,
  register,
  setValue,
  onSelectChange,
  fullWidth,
}: SelectDropdownProps) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: menuItems,
    onSelectedItemChange: () => {
      if (onSelectChange) onSelectChange();
    },
  });

  useEffect(() => {
    setValue(register.name, selectedItem, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [selectedItem, register.name, setValue]);

  const returnSizedWhitespace = () => {
    const { name } = register;
    if (name === 'age') return '                      ';
    if (name === 'recoveryGoalsInclude')
      return '                                                    ';
    if (name === 'lookForInProvider')
      return '                                                              ';
  };

  return (
    <div
      sx={{
        fontFamily: 'TT Norms',
        fontSize: ['26px', null, '5xl', null, '6xl'],
        fontWeight: 'medium',
        lineHeight: 'heading',
        position: 'relative',
        minWidth: fullWidth ? ['350px', '0', null, null] : '0',
      }}
    >
      <Flex
        sx={{
          padding: 1,
          textAlign: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
        }}
        {...register}
        {...getToggleButtonProps()}
      >
        <span
          sx={{
            color: 'rgba(218, 27, 133, 1)',
            textDecoration: 'underline',
            textUnderlineOffset: '8px',
            textDecorationThickness: '2px',
            whiteSpace: 'pre',
          }}
        >
          {selectedItem ?? returnSizedWhitespace()}
        </span>
        {isOpen ? (
          <IconChevron
            sx={{
              width: '16px',
              ml: 2,
              mt: 1,
              transform: 'rotate(180deg)',
              color: 'rgba(218, 27, 133, 1)',
            }}
          />
        ) : (
          <IconChevron
            sx={{
              width: '16px',
              ml: 2,
              mt: 1,
              color: 'rgba(218, 27, 133, 1)',
            }}
          />
        )}
      </Flex>
      <ul
        {...getMenuProps()}
        sx={{
          listStyle: 'none',
          width: ['100%', null, null, '110%'],
          boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
          borderRadius: 'radius',
          position: 'absolute',
          backgroundColor: 'white',
          cursor: 'pointer',
          zIndex: 1,
          overflow: 'hidden',
          left: ['0', null, null, '-5%'],
        }}
      >
        {isOpen &&
          menuItems.map((item, index) => (
            <li
              sx={{
                padding: '5px',
                '&:last-of-type': { marginBottom: 2 },
              }}
              key={`${item}${index}`}
              {...getItemProps({
                item,
                index,
              })}
            >
              <Link
                variant="nav"
                sx={{
                  '> span': {
                    fontFamily: 'TT Norms',
                    fontSize: ['26px', null, '4xl', null, '5xl'],
                    fontWeight: 'medium',
                    lineHeight: 'heading',
                    mx: 2,
                    color: selectedItem === item ? 'primary' : 'black',
                    whiteSpace: ['normal', null, null, 'nowrap'],
                  },
                }}
              >
                {item}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};
