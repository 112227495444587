import { graphql, useStaticQuery } from 'gatsby';
import ReactSlickSlider, { Settings } from 'react-slick';

import {
  Container,
  Paragraph,
  Section,
  SliderNextArrow,
  SliderPrevArrow,
  Spacer,
  StyledSup,
} from 'voom-gatsby';

import {
  BeforeAfterCard,
  BunionplastyBackground,
  ContentBlock,
} from '~components';

import { BeforeAfterCardProps, ContentBlockProps } from '~types/sanity';

export const BeforeAfterCallout = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    withSvg?: boolean;
    content: ContentBlockProps;
    beforeAfterList: BeforeAfterCardProps[];
    disclaimer?: string;
  };
  index: number;
}) => {
  const { allSanityBeforeAfterCard } = useStaticQuery(
    BEFORE_AFTER_CARD_COUNT_QUERY,
  );

  const beforeAfterCalloutCarouselSettings: Settings = {
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 3,
    prevArrow: <SliderPrevArrow />,
    nextArrow: <SliderNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Section
      sx={{
        bg: data.backgroundColor ?? 'beige',
        overflow: 'hidden',
      }}
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
    >
      <Container sx={{ position: 'relative', zIndex: 1 }}>
        {data.withSvg && (
          <BunionplastyBackground
            sx={{
              left: -250,
              top: -525,
              width: 1100,
              zIndex: -1,
            }}
          />
        )}
        <ContentBlock
          content={data.content}
          titleSize="Lg"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: [500, null, null, null, 800],
            mx: 'auto',
            textAlign: 'center',
            mb: 5,
            position: 'relative',
            zIndex: 1,
          }}
          linkStyles={{
            flexDirection: ['column', null, 'column'],
            'a:not(:last-of-type)': {
              mr: [0, null, 0],
              mb: [4, null, 4],
            },
          }}
          replaceHashWithCount={allSanityBeforeAfterCard.totalCount}
        />
      </Container>
      <Container variant="noGutterContainer">
        <ReactSlickSlider
          {...beforeAfterCalloutCarouselSettings}
          sx={{ '.slick-slide': { mx: 4, mt: 3 } }}
        >
          {data.beforeAfterList.map((card, index) => {
            return (
              <BeforeAfterCard
                key={
                  card.image.asset.gatsbyImageData.images.fallback?.src ?? index
                }
                {...card}
              />
            );
          })}
        </ReactSlickSlider>
        {data.disclaimer && (
          <>
            <Spacer space={6} />
            <Paragraph
              variant="disclaimer"
              sx={{ mx: 'auto', maxWidth: 400, textAlign: 'center' }}
            >
              <StyledSup>{data.disclaimer}</StyledSup>
            </Paragraph>
          </>
        )}
      </Container>
    </Section>
  );
};

export const BEFORE_AFTER_CARD_COUNT_QUERY = graphql`
  query BeforeAfterCardCount {
    allSanityBeforeAfterCard(filter: { featured: { eq: true } }) {
      totalCount
    }
  }
`;

export const BEFORE_AFTER_CALLOUT_QUERY = graphql`
  fragment beforeAfterCalloutFields on SanityBeforeAfterCallout {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    withSvg
    content {
      ...sanityContentBlockFields
    }
    beforeAfterList {
      image {
        asset {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
        alt
      }
      gender {
        value
        pronoun
      }
      severity {
        value
      }
    }
    disclaimer
  }
`;
