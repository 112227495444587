import { motion, useInView, useScroll } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Honeypot,
  NetlifyFormComponent,
  NetlifyFormProvider,
  useNetlifyForm,
} from 'react-netlify-forms';

import {
  Flex,
  Paragraph,
  Section,
  Spacer,
  Text,
  ThemeUIStyleObject,
} from 'voom-gatsby';

import {
  BunionplastyQuizForm,
  BunionplastyQuizHero,
  DropdownQuestion,
  PanelSelectQuestion,
} from '~components';

import { ReactComponent as SeverityLargeSvg } from '~svg/brand/bunion-scale-large.svg';
import { ReactComponent as SeverityMild } from '~svg/brand/bunion-scale-mild.svg';
import { ReactComponent as SeveritySevereSvg } from '~svg/brand/bunion-scale-severe.svg';
import { ReactComponent as BothFeetSvg } from '~svg/bunionplasty-quiz/foot-both.svg';
import { ReactComponent as FootLeftSvg } from '~svg/bunionplasty-quiz/foot-left.svg';
import { ReactComponent as LoveMyFeetSvg } from '~svg/bunionplasty-quiz/love-my-feet.svg';
import { ReactComponent as ToesCrossesSvg } from '~svg/bunionplasty-quiz/toes-full-crosses.svg';
import { ReactComponent as ToesNoneSvg } from '~svg/bunionplasty-quiz/toes-full-none.svg';
import { ReactComponent as ToesTouchesSvg } from '~svg/bunionplasty-quiz/toes-full-touches.svg';

import { ContentBlockProps as ContentBlockInterface } from '~types/sanity';

const howBigSvgStyles: ThemeUIStyleObject = {
  height: ['65px', null, null, '125px'],
  maxWidth: ['65px', null, null, '125px'],
};

const toeTouchSvgStyles: ThemeUIStyleObject = {
  height: ['45px', null, null, '125px'],
  maxWidth: ['65px', null, null, '125px'],
  mt: 3,
};

const bothFeetSvgStyles: ThemeUIStyleObject = {
  height: ['65px', null, null, '125px'],
  maxWidth: ['65px', null, null, '180px'],
  mt: 3,
};

const cardSpacerStyles = [2, null, null, 4];

export const BunionplastyQuiz = ({
  data,
}: {
  data: {
    content: ContentBlockInterface;
    disclaimer: string;
    finalDisclaimer: string;
  };
}) => {
  const introQuestionRef = useRef<HTMLDivElement>(null);
  const firstQuestionRef = useRef<HTMLDivElement>(null);
  const secondQuestionRef = useRef<HTMLDivElement>(null);
  const thirdQuestionRef = useRef<HTMLDivElement>(null);
  const fourthQuestionRef = useRef<HTMLDivElement>(null);
  const fifthQuestionRef = useRef<HTMLDivElement>(null);
  const sixthQuestionRef = useRef<HTMLDivElement>(null);
  const finalQuizRef = useRef<HTMLDivElement>(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const { scrollYProgress } = useScroll({ offset: [0.2, 0.88] });
  const { illustration } = useStaticQuery(HERO_ILLUSTRATION_QUERY);
  const heroImage = getImage(illustration);
  const { finalDisclaimer } = data;

  const handleQuizStart = () => {
    if (!quizStarted) setQuizStarted(true);

    // timeout to allow questions to render before scrolling
    setTimeout(() => {
      introQuestionRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 50);
    setTimeout(() => {
      firstQuestionRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setCurrentQuestion(1);
    }, 2000);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const netlify = useNetlifyForm({
    name: 'Bunionplasty Quiz',
    honeypotName: 'bot-field',
    onSuccess: () => {
      if (window.gtag) {
        window.gtag('event', 'Form Submission - Bunionplasty Quiz', {});
      }
      navigate('/bunionplasty-quiz-thank-you');
    },
  });

  const onSubmit = (data: unknown) => {
    netlify.handleSubmit(null, data);
  };

  const handleAnswer = (question: number) => {
    if (currentQuestion <= question) {
      setCurrentQuestion(question + 1);
      scrollToNextQuestion(question);
    }
  };

  const scrollToNextQuestion = (question: number) => {
    switch (question) {
      case 1:
        secondQuestionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        break;
      case 2:
        thirdQuestionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        break;
      case 3:
        fourthQuestionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        break;
      case 4:
        fifthQuestionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        break;
      case 5:
        sixthQuestionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        break;
      case 6:
        finalQuizRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        break;
      default:
        break;
    }
  };

  const heroRef = useRef<HTMLDivElement>(null);

  const isHeroInView = useInView(heroRef);

  const isIntroQuestionInView = useInView(introQuestionRef);

  const hideProgressIndicator = heroRef.current
    ? isHeroInView || isIntroQuestionInView
    : true;

  return (
    <>
      <BunionplastyQuizHero
        heroImage={heroImage}
        onQuizStart={handleQuizStart}
        ref={heroRef}
        data={data}
      />
      <Section
        sx={{
          bg: 'white',
          visibility: quizStarted ? 'visible' : 'hidden',
          height: quizStarted ? 'auto' : 0,
          pt: !quizStarted && '0 !important',
          pb: !quizStarted && '0 !important',
        }}
      >
        <motion.svg
          width="100"
          height="100"
          viewBox="0 0 100 100"
          sx={{
            position: 'fixed',
            top: 'calc(50% - 60px)',
            left: '30px',
            transform: 'rotate(-90deg)',
            circle: {
              strokeDashoffset: '0',
              strokeWidth: '15%',
              fill: 'none',
            },
            visibility: ['hidden', null, null, 'visible'],
          }}
          initial={{ opacity: 0, scale: 0 }}
          transition={{
            type: 'spring',
          }}
          animate={{
            opacity: hideProgressIndicator ? 0 : 1,
            scale: hideProgressIndicator ? 0 : 1,
          }}
        >
          <circle
            cx="50"
            cy="50"
            r="30"
            pathLength="1"
            sx={{ stroke: 'rgba(218, 27, 133, 1)', opacity: '0.2' }}
          />
          <motion.circle
            cx="50"
            cy="50"
            r="30"
            pathLength="1"
            sx={{
              stroke: 'rgba(218, 27, 133, 1)',
              transform: 'rotate(-90deg) translateX(-100px)',
              filter: 'drop-shadow(0px 4px 4px #A7A7AF)',
            }}
            style={{ pathLength: scrollYProgress }}
          />
        </motion.svg>
        <NetlifyFormProvider {...netlify}>
          <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
            <Honeypot />
            <Flex
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                px: [4, 10],
              }}
            >
              <motion.div
                sx={{ display: 'flex', flexDirection: 'column' }}
                initial={{ opacity: 0, scale: 0.75 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{
                  type: 'spring',
                  delay: 0.25,
                }}
                viewport={{ once: true }}
              >
                <LoveMyFeetSvg height={150} />
                <Paragraph
                  variant="contentTitleLg"
                  sx={{ textAlign: 'center' }}
                  ref={introQuestionRef}
                >
                  Tell us about <Text variant="contentTitleEm">your</Text> feet!
                </Paragraph>
              </motion.div>
              <Spacer space={512} />
              <PanelSelectQuestion
                headline={
                  <>
                    <Text variant="contentTitleEm">Where</Text> are your
                    bunions?
                  </>
                }
                pillText="1 OF 6"
                cardContent={[
                  {
                    key: 'rightFoot',
                    content: (
                      <>
                        <SeverityMild sx={howBigSvgStyles} />
                        <Spacer space={cardSpacerStyles} />
                        Right foot
                      </>
                    ),
                  },
                  {
                    key: 'leftFoot',
                    content: (
                      <>
                        <FootLeftSvg sx={howBigSvgStyles} />
                        <Spacer space={cardSpacerStyles} />
                        Left foot
                      </>
                    ),
                  },
                  {
                    key: 'bothFeet',
                    content: (
                      <>
                        <BothFeetSvg sx={bothFeetSvgStyles} />
                        <Spacer space={cardSpacerStyles} />
                        Both feet
                      </>
                    ),
                  },
                ]}
                register={register('bunionInWhichFoot')}
                setValue={setValue}
                disabled={currentQuestion < 1}
                ref={firstQuestionRef}
                onSelectChange={() => handleAnswer(1)}
              />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <PanelSelectQuestion
                headline={
                  <>
                    How <Text variant="contentTitleEm">big</Text> is your bunion
                    bump?
                  </>
                }
                subheadline="If you have them in both feet, pick the larger one."
                pillText="2 OF 6"
                cardContent={[
                  {
                    key: 'mild',
                    content: (
                      <>
                        <SeverityMild sx={howBigSvgStyles} />
                        <Spacer space={cardSpacerStyles} />
                        Mild
                      </>
                    ),
                  },
                  {
                    key: 'large',
                    content: (
                      <>
                        <SeverityLargeSvg sx={howBigSvgStyles} />
                        <Spacer space={cardSpacerStyles} />
                        Large
                      </>
                    ),
                  },
                  {
                    key: 'severe',
                    content: (
                      <>
                        <SeveritySevereSvg sx={howBigSvgStyles} />
                        <Spacer space={cardSpacerStyles} />
                        Severe
                      </>
                    ),
                  },
                ]}
                register={register('howBigIsYourBunionBump')}
                setValue={setValue}
                disabled={currentQuestion < 2}
                ref={secondQuestionRef}
                onSelectChange={() => handleAnswer(2)}
              />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <PanelSelectQuestion
                headline={
                  <>
                    Does your big toe{' '}
                    <Text variant="contentTitleEm">touch</Text> or{' '}
                    <Text variant="contentTitleEm">cross</Text> over or
                    <br /> under your second toe?
                  </>
                }
                subheadline="If you have bunions in both feet, pick the foot that’s worse."
                pillText="3 OF 6"
                cardContent={[
                  {
                    key: 'touches',
                    content: (
                      <>
                        <ToesTouchesSvg sx={toeTouchSvgStyles} />
                        <Spacer space={cardSpacerStyles} />
                        Touches
                      </>
                    ),
                  },
                  {
                    key: 'crossesOverOrUnder',
                    content: (
                      <>
                        <ToesCrossesSvg sx={toeTouchSvgStyles} />
                        <Spacer space={cardSpacerStyles} />
                        Crosses Over or Under
                      </>
                    ),
                  },
                  {
                    key: 'noTouchingOrCrossing',
                    content: (
                      <>
                        <ToesNoneSvg sx={toeTouchSvgStyles} />
                        <Spacer space={cardSpacerStyles} />
                        No Touching or Crossing
                      </>
                    ),
                  },
                ]}
                register={register('touchOrCrossOver')}
                setValue={setValue}
                disabled={currentQuestion < 3}
                onSelectChange={() => handleAnswer(3)}
                ref={thirdQuestionRef}
              />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <DropdownQuestion
                text="My age is"
                pillText="4 OF 6"
                menuItems={['under 18', '18 - 24', '25 - 64', 'over 65']}
                addEndingPeriod
                register={register('age')}
                setValue={setValue}
                disabled={currentQuestion < 4}
                onSelectChange={() => handleAnswer(4)}
                ref={fourthQuestionRef}
              />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <DropdownQuestion
                text="My recovery goals include:"
                pillText="5 OF 6"
                menuItems={[
                  'Relief from bunion pain',
                  'Wear my favorite shoes',
                  'Show off my feet again',
                  'Return to active lifestyle',
                  'All of the above!',
                ]}
                register={register('recoveryGoalsInclude')}
                setValue={setValue}
                breakLines
                disabled={currentQuestion < 5}
                onSelectChange={() => handleAnswer(5)}
                ref={fifthQuestionRef}
              />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <DropdownQuestion
                text="What I look for in a provider:"
                pillText="6 OF 6"
                menuItems={[
                  'Wonderful bedside manner',
                  'Fantastic healthcare space',
                  'Telehealth convenience',
                  'First-class practice team',
                  'History of great results',
                ]}
                register={register('lookForInProvider')}
                setValue={setValue}
                breakLines
                disabled={currentQuestion < 6}
                onSelectChange={() => handleAnswer(6)}
                ref={sixthQuestionRef}
              />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <Spacer space={10} />
              <BunionplastyQuizForm
                register={register}
                reset={reset}
                errors={errors}
                control={control}
                setValue={setValue}
                disabled={currentQuestion < 7}
                ref={finalQuizRef}
                finalDisclaimer={finalDisclaimer}
              />
            </Flex>
          </NetlifyFormComponent>
        </NetlifyFormProvider>
      </Section>
    </>
  );
};

export const HERO_ILLUSTRATION_QUERY = graphql`
  query BunionplastyQuizHeroIllustration {
    illustration: file(relativePath: { eq: "bp-quiz-hero-illustration.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`;

export const BUNIONPLASTY_QUIZ_QUERY = graphql`
  fragment bunionplastyQuizFields on SanityBunionplastyQuiz {
    _type
    content {
      ...sanityContentBlockFields
    }
    disclaimer
    finalDisclaimer
  }
`;
