import { ForwardedRef, forwardRef } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormReset,
} from 'react-hook-form';
import { useNetlifyFormContext } from 'react-netlify-forms';

import {
  Box,
  Button,
  Container,
  Flex,
  FormInput,
  FormSelect,
  Paragraph,
  Section,
  Spacer,
  Text,
  useDefaultFormFields,
} from 'voom-gatsby';

import { ReactComponent as BunionplastyIcon } from '~svg/brand/bunionplasty-icon.svg';

interface BunionplastyQuizFormProps {
  disabled?: boolean;
  register: UseFormRegister<FieldValues>;
  reset: UseFormReset<FieldValues>;
  errors: FieldErrors;
  control: Control;
  setValue: (name: string, value: string) => void;
  finalDisclaimer: string;
}

export const BunionplastyQuizForm = forwardRef(
  (props: BunionplastyQuizFormProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      disabled,
      register,
      reset,
      errors,
      control,
      setValue,
      finalDisclaimer,
    } = props;
    const netlify = useNetlifyFormContext();
    const DefaultFormFields = useDefaultFormFields(register, reset);

    return (
      <Section
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          opacity: disabled ? 0.3 : 1,
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        <Box
          sx={{
            pt: [6, null, 6],
            pb: [5, null, 9],
            bg: 'beige',
            borderRadius: 'rounded',
            width: 'full',
          }}
        >
          <Container variant="formContainer">
            <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
              <BunionplastyIcon
                sx={{
                  position: 'absolute',
                  top: ['50px', null, '90px'],
                  height: '75px',
                }}
              />
              <Paragraph variant="eyeBrow" sx={{ textAlign: 'center' }}>
                The Bunion Quiz
              </Paragraph>
              <Paragraph
                variant="contentTitle"
                sx={{ textAlign: 'center', maxWidth: 656 }}
              >
                Just a<Text variant="contentTitleEm"> few </Text> final details:
              </Paragraph>
            </Flex>
            <Spacer space={6} />
            <Flex
              sx={{
                flexDirection: ['column', null, 'row'],
                justifyContent: 'space-between',
                px: [2, null, null, 10],
                '> div': {
                  flex: ['1 1 auto'],
                },
              }}
              ref={ref}
            >
              <Box>
                <Flex
                  sx={{
                    flexDirection: ['column', null, 'row'],
                    justifyContent: 'space-between',
                    '> div': {
                      flex: ['1 1 auto', null, '0 0 calc(50% - 10px)'],
                    },
                  }}
                >
                  <FormInput
                    label="First Name*"
                    name="firstName"
                    register={register('firstName', { required: true })}
                    errors={errors.firstName}
                    control={control}
                  />
                  <FormInput
                    label="Last Name*"
                    name="lastName"
                    register={register('lastName', { required: true })}
                    errors={errors.lastName}
                    control={control}
                  />
                </Flex>
                <Flex
                  sx={{
                    flexDirection: ['column', null, 'row'],
                    justifyContent: 'space-between',
                    '> div': {
                      flex: ['1 1 auto', null, '0 0 calc(50% - 10px)'],
                    },
                  }}
                >
                  <FormInput
                    label="Phone*"
                    name="phoneNumber"
                    type="tel"
                    register={register('phoneNumber', { required: true })}
                    errors={errors.phoneNumber}
                    control={control}
                  />
                  <FormInput
                    label="ZIP*"
                    name="zip"
                    type="text"
                    register={register('zip', { required: true })}
                    errors={errors.zip}
                    control={control}
                  />
                </Flex>
                <FormInput
                  label="Email*"
                  name="email"
                  type="email"
                  register={register('email', { required: true })}
                  errors={errors.email}
                  control={control}
                />
                <FormSelect
                  label="Where are you in your bunion journey?"
                  options={[
                    "Wait, I don't have to live with this pain?",
                    "I'm learning about different procedures",
                    'Making appointments as we speak',
                    "Surgery's scheduled, but now I'm curious!",
                  ]}
                  register={register('journey')}
                  errors={errors.email}
                  setValue={setValue}
                />
                <FormSelect
                  label="What is your health insurance?"
                  options={['Private', 'Medicare', 'Other', 'None']}
                  register={register('insurance')}
                  errors={errors.email}
                  setValue={setValue}
                />
                <DefaultFormFields />
              </Box>
            </Flex>
            {netlify.error && (
              <Paragraph sx={{ py: 4, textAlign: 'center' }}>
                Sorry, we could not reach servers. Please try again later. If
                this problem continues, please contact us. Thanks!
              </Paragraph>
            )}
            <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
              <Spacer />
              <Button
                type="submit"
                sx={{
                  variant: 'buttons.primary',
                  appearance: 'none',
                  mt: 4,
                  mb: 2,
                  width: 250,
                }}
              >
                Submit
              </Button>
              <Paragraph variant="copyright" sx={{ fontStyle: 'italic' }}>
                *Required fields
              </Paragraph>
            </Flex>
          </Container>
        </Box>
        <Spacer space={6} />
        <Paragraph
          variant="disclaimer"
          sx={{
            textAlign: 'center',
            fontStyle: 'italic',
            maxWidth: 715,
            mt: 'auto',
            display: ['none', 'block'],
          }}
        >
          {finalDisclaimer}
        </Paragraph>
      </Section>
    );
  },
);

BunionplastyQuizForm.displayName = 'BunionplastyQuizForm';
