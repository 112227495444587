import { graphql } from 'gatsby';

import { Box, Container, Grid, Section } from 'voom-gatsby';

import { AmbientVideo, ContentBlock } from '~components';

import { ContentBlockProps, VideoAsset } from '~types/sanity';

export const VideoSideBySide = ({
  data,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    content: ContentBlockProps;
    desktopRight: boolean;
    video: VideoAsset;
  };
  index: number;
}) => {
  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{ bg: data.backgroundColor ?? 'grey200' }}
    >
      <Container>
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, '2fr 3fr'],
            rowGap: 6,
            columnGap: 0,
            gridAutoFlow: 'dense',
          }}
        >
          <Box
            sx={{
              gridColumnStart: [1, null, data.desktopRight ? 2 : 1],
              width: 'full',
              height: 'full',
              position: 'relative',
            }}
          >
            <AmbientVideo
              url={data.video.file.asset.url}
              type={data.video.type}
            />
          </Box>
          <ContentBlock
            content={data.content}
            titleSize="Lg"
            sx={{
              maxWidth: [null, null, 500, null, 700],
              mx: 'auto',
              // force text to be closer to center on desktop
              ...(data.desktopRight
                ? { mr: ['auto', null, 0] }
                : { ml: ['auto', null, 0] }),
            }}
          />
        </Grid>
      </Container>
    </Section>
  );
};

export const VIDEO_SIDE_BY_SIDE_QUERY = graphql`
  fragment videoSideBySideFields on SanityVideoSideBySide {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    content {
      ...sanityContentBlockFields
    }
    desktopRight
    video {
      file {
        asset {
          url
        }
      }
      type
    }
  }
`;
