import { useBreakpointIndex } from '@theme-ui/match-media';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useMemo } from 'react';

import {
  Container,
  Grid,
  Heading,
  Link,
  Section,
  Spacer,
  Text,
} from 'voom-gatsby';

import { BeforeAfterCardProps, SanityDoctor } from '~types/sanity';

export const DoctorBeforeAfter = ({
  gallery,
  doctorSlug,
  doctorName,
}: {
  gallery: Omit<BeforeAfterCardProps, 'severity' | 'gender' | 'foot'>[];
  doctorSlug: SanityDoctor['slug']['current'];
  doctorName: SanityDoctor['name'];
}) => {
  const breakpoint = useBreakpointIndex({
    defaultIndex: 3, // default to desktop
  });

  const totalImages = gallery.length;

  const minImageCount = breakpoint > 0 ? 5 : 3;

  const imagesToDisplay = useMemo(
    () => gallery.slice(0, minImageCount),
    [gallery, minImageCount],
  );

  return (
    <>
      <Section narrowPaddingTop narrowPaddingBottom>
        <Container variant="doctorContainer">
          <Heading variant="doctorSectionHeading">{`${doctorName}'s Before & Afters`}</Heading>
          <Spacer />
          <Grid
            sx={{
              gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(4, 1fr)'],
              gridAutoFlow: 'dense',
              gridGap: [3, 4],
            }}
          >
            {imagesToDisplay.map(({ image }, index) => {
              const firstImage = index === 0;
              const lastImage = index + 1 === minImageCount;

              // if this is the last image and there are more images to show, show a button to open the modal
              if (lastImage && totalImages > minImageCount) {
                return (
                  <Link
                    variant="ghost"
                    to={`/find-a-doctor/doctors/${doctorSlug}/patient-gallery`}
                    aria-label="Link to patient gallery"
                    key={image.alt + index}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <GatsbyImage
                      image={image.asset.gatsbyImageData}
                      alt={image.alt}
                      sx={{
                        borderRadius: 'corner',
                        boxShadow: 'cardShadow',
                        zIndex: 0,
                        img: { borderRadius: 'corner' },
                        '::after': {
                          content: '""',
                          position: 'absolute',
                          inset: 0,
                          background: (theme) => theme.colors?.wineGradient,
                        },
                      }}
                    />
                    <Text
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >{`+ ${totalImages - minImageCount} More`}</Text>
                  </Link>
                );
              }
              // return images as normal
              return (
                <GatsbyImage
                  key={image.alt + index}
                  image={image.asset.gatsbyImageData}
                  alt={image.alt}
                  sx={{
                    gridColumn: firstImage ? 'span 2' : undefined,
                    gridRow: firstImage ? 'span 2' : undefined,
                    borderRadius: 'corner',
                    boxShadow: 'cardShadow',
                    zIndex: 0,
                    img: { borderRadius: 'corner' },
                  }}
                />
              );
            })}
          </Grid>
        </Container>
      </Section>
    </>
  );
};
