export const WHITE = '#FFFFFF';
export const GREY_100 = '#E8E8E8';
export const GREY_200 = '#F7F7F9';
export const GREY_300 = '#D7D7DC'; // use for #D8D8D8, #E1DDDD, #979797, #E6E6EA;
export const GREY_400 = '#A7A7AF';
export const GREY_500 = '#45454C';
export const BLACK_100 = '#04000D'; // use for #333333, #0F0F0F, #000000

export const BEIGE = '#FFF4ED';
export const TAN = '#F7EBE4';
export const PINK = '#DA1B85'; // use for #E5177F
export const RED = '#84044B'; // use for #690727
export const WINE = '#42102C';
export const BLUE = '#0F6FFF'; // #003E9A is only used for review stars

export const HOME_HERO_MOBILE_GRADIENT = `linear-gradient(to bottom, 
  rgba(245, 246, 252, 0.0),
  rgba(255, 255, 255, 0.2) 20%, 
  rgba(255, 255, 255, 0.7) 60%,
  rgba(255, 255, 255, 0.8) 80%,  
  rgba(255, 255, 255, 1) 100%)`;

export const FADE_GRADIENT = `linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1));
`;

export const MODAL_OVERLAY = 'rgba(0, 0, 0, 0.8)';

export const DROP_SHADOW = 'rgba(24,30,60,0.25)';

export const PINK_OPACITY = 'rgba(218, 27, 133, 0.3)';

export const WINE_GRADIENT = `linear-gradient(0deg, rgba(82, 9, 49, 0.75), rgba(82, 9, 49, 0.75))`;

export const DOCTOR_SEARCH_HERO_GRADIENT = `linear-gradient(0deg, rgba(82, 9, 49, 1),rgba(82, 9, 49, .99) 10%, rgba(82, 9, 49, 0.95) 40%,rgba(82, 9, 49, 0.5) 60%,rgba(82, 9, 49, 0.1) 100%)`;
