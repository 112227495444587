import { graphql } from 'gatsby';

import { Container, Section } from 'voom-gatsby';

import {
  BunionplastyBackground,
  ContentBlock,
  ScriptsBackground,
} from '~components';

import { ContentBlockProps } from '~types/sanity';

export const LegalHero = ({
  data,
  index,
}: {
  data: {
    backgroundColor?: string;
    narrowPaddingTop?: boolean;
    narrowPaddingBottom?: boolean;
    withSvg?: boolean;
    scriptsBackground?: boolean;
    content: ContentBlockProps;
  };
  index: number;
}) => {
  return (
    <Section
      narrowPaddingTop={data.narrowPaddingTop}
      narrowPaddingBottom={data.narrowPaddingBottom}
      sx={{
        bg: data.backgroundColor ?? 'beige',
      }}
    >
      {data.withSvg && (
        <BunionplastyBackground
          sx={{
            top: [-220, null, -470],
            left: [-220, null, -250],
            width: [505, null, 850],
            svg: {
              color: 'pink',
              opacity: 0.07,
            },
          }}
        />
      )}
      {data.scriptsBackground && <ScriptsBackground />}
      <Container>
        <ContentBlock
          content={data.content}
          sx={{
            maxWidth: [750, null, null, null, 900],
            textAlign: 'center',
            mx: 'auto',
          }}
          titleSize="Lg"
          captionStyles={{
            maxWidth: [410, null, null, null, 480],
            textAlign: 'center',
            mx: 'auto',
          }}
          headingAs={index === 0 ? 'h1' : undefined}
        />
      </Container>
    </Section>
  );
};

export const LEGAL_HERO = graphql`
  fragment legalHeroFields on SanityLegalHero {
    _type
    backgroundColor
    narrowPaddingTop
    narrowPaddingBottom
    withSvg
    scriptsBackground
    content {
      ...sanityContentBlockFields
    }
  }
`;
