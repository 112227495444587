import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Heading,
  Paragraph,
  StyledSup,
  Text,
} from 'voom-gatsby';

import { marks } from '~components';

import { ReactComponent as ChevronIcon } from '~svg/icons/icon-chevron.svg';

import { SanityFaqItem } from '~types/sanity';

export const faqComponents: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => <Paragraph variant="faq">{children}</Paragraph>,
  },
  marks: marks,
  list: ({ children }) => (
    <Text as="ul" variant="contentCaptionUl">
      {children}
    </Text>
  ),
  listItem: ({ children }) => (
    <Text as="li" variant="contentCaptionLi">
      {children}
    </Text>
  ),
};

export const FAQItem: FC<SanityFaqItem> = ({ question, _rawAnswer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Divider
        sx={{
          borderColor: isExpanded ? 'primary' : 'grey300',
          borderBottomWidth: isExpanded ? 2 : 1,
          opacity: isExpanded ? 1 : 0.5,
          my: 3,
        }}
      />
      <Heading as="h3">
        <Button
          variant="ghost"
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: 'full',
            textAlign: 'left',
            alignItems: 'center',
          }}
        >
          <Paragraph variant="faq" sx={{ m: 0 }}>
            <StyledSup>{question}</StyledSup>
          </Paragraph>

          <ChevronIcon
            sx={{
              flexShrink: 0,
              width: 16,
              color: 'black',
              transition: 'transform 0.2s ease-in-out',
              transform: isExpanded ? 'rotateX(180deg)' : 'none',
              ml: 1,
            }}
          />
        </Button>
      </Heading>
      <AnimatePresence>
        <motion.div
          key="content"
          initial="collapsed"
          animate={isExpanded ? 'open' : 'collapsed'}
          variants={{
            open: { opacity: 1, height: 'auto', margin: '15px 0' },
            collapsed: { opacity: 0, height: 0, margin: '0' },
          }}
          transition={{
            type: 'spring',
            stiffness: 50,
            velocity: 4,
            restSpeed: 0.8,
            restDelta: 0.8,
          }}
        >
          <PortableText value={_rawAnswer} components={faqComponents} />
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};
